import { Fetch } from '@/lib';
import type { Cart, CartInvoice } from '@/types';

type RequestResponse = CartInvoice;

type RequestParameter = {};

type RequestBody = Pick<Cart, 'paperInvoice' | 'description'> &
  Record<'recipientAddressId', string>;

const updateCheckoutInfo = async (body: RequestBody) =>
  await Fetch<RequestResponse, RequestParameter, RequestBody>({
    url: 'cart/updateCheckoutInfo',
    method: 'PUT',
    body,
  });

export { updateCheckoutInfo };
