'use client';

import * as React from 'react';

import Image from 'next/image';

import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import {
  Button,
  CaretLeftIcon,
  LocaleLink,
  ProductCardContentPlaceholder,
  ProductCardFooterPlaceholder,
  ProductCardHeaderPlaceholder,
  ProductCardPlaceholder,
  ProductView,
  ProductViewPlaceholder,
  ScrollArea,
  Skeleton,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  TypographyMuted,
} from '@/components';
import type { ProductSummary } from '@/types';
import { cn, generateFileUrl, randomId } from '@/utils';

const productTabsVariants = cva('', {
  variants: {
    variant: {
      default: 'bg-background text-foreground',
      transparent: 'bg-transparent text-muted',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

type ProductTabsContextProps = Record<
  'info',
  Record<'title', string> & Record<'href', string | URL>
> &
  Record<
    'tabTriggers',
    Array<Record<'id' | 'title', string> & Partial<Record<'icon', string>>>
  > &
  Record<
    'tabPanes',
    Array<Record<'id', string> & Record<'products', ProductSummary[]>>
  >;

const ProductTabsContext = React.createContext<ProductTabsContextProps>(
  {} as ProductTabsContextProps,
);

const useProductTabs = () => {
  const context = React.useContext(ProductTabsContext);

  if (!context) {
    throw new Error('useProductTabs must be used within a <ProductTabs />');
  }

  return context;
};

type ProductTabsProps = React.ComponentPropsWithoutRef<typeof Tabs> &
  VariantProps<typeof productTabsVariants> &
  ProductTabsContextProps;

const ProductTabs = React.forwardRef<
  React.ElementRef<typeof Tabs>,
  ProductTabsProps
>(
  (
    {
      info,
      tabPanes,
      tabTriggers,
      defaultValue,
      variant,
      className,
      children,
      ...props
    },
    ref,
  ) => {
    const defaultTab = defaultValue ?? tabTriggers[0].id;

    return (
      <ProductTabsContext.Provider value={{ info, tabPanes, tabTriggers }}>
        <Tabs
          ref={ref}
          defaultValue={defaultTab}
          className={cn(productTabsVariants({ variant, className }))}
          {...props}
        >
          {children}
        </Tabs>
      </ProductTabsContext.Provider>
    );
  },
);
ProductTabs.displayName = 'ProductTabs';

const ProductTabsHeader = ({ children }: Readonly<React.PropsWithChildren>) => {
  const {
    info: { title, href },
  } = useProductTabs();

  return (
    <>
      <div className='container mb-6 flex items-center justify-between lg:mb-8'>
        <h5 className='text-center text-lg font-bold'>{title}</h5>
        <div className='max-lg:hidden'>{children}</div>
        <Button
          asChild
          variant='link'
          className='p-0 font-bold text-inherit max-lg:text-xs'
        >
          <LocaleLink href={href} className='gap-2'>
            موارد بیشتر
            <CaretLeftIcon className='aspect-square size-6 rounded-full bg-muted p-1 text-foreground' />
          </LocaleLink>
        </Button>
      </div>

      <ScrollArea
        type='scroll'
        orientation='horizontal'
        className='mb-6 pb-2 lg:hidden'
      >
        {children}
      </ScrollArea>
    </>
  );
};

const ProductTabsHeaderTriggers = React.forwardRef<
  React.ElementRef<typeof TabsList>,
  React.ComponentPropsWithoutRef<typeof TabsList>
>(({ className, ...props }, ref) => {
  const { tabTriggers } = useProductTabs();

  const { width, height } = {
    width: 40,
    height: 40,
  };

  return (
    <TabsList
      ref={ref}
      className={cn('gap-8 max-lg:container group', className)}
      {...props}
    >
      {tabTriggers.map(({ id, title, icon }) => (
        <TabsTrigger
          key={id}
          value={id}
          className='peer flex-col gap-2 whitespace-normal p-0'
        >
          {icon ? (
            <Image
              src={generateFileUrl('Category', icon, width, height)}
              width={width}
              height={height}
              alt={title}
            />
          ) : null}
          <TypographyMuted className='text-nowrap text-inherit'>
            {title}
          </TypographyMuted>
        </TabsTrigger>
      ))}
    </TabsList>
  );
});
ProductTabsHeaderTriggers.displayName = 'ProductTabsHeaderTriggers';

type ProductTabsContentProps = React.ComponentPropsWithoutRef<'div'> &
  Partial<Record<'type', 'slider' | 'grid'>>;

const ProductTabsContent = React.forwardRef<
  React.ElementRef<'div'>,
  ProductTabsContentProps
>(({ type = 'slider', ...props }, ref) => {
  const { tabPanes } = useProductTabs();

  const content = tabPanes.map(({ id, products }) => (
    <TabsContent key={id} value={id}>
      <ProductView products={products} type={type} />
    </TabsContent>
  ));

  return (
    <div ref={ref} {...props}>
      {content}
    </div>
  );
});
ProductTabsContent.displayName = 'ProductTabsContent';

const ProductTabsPlaceholder = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('space-y-6', className)} {...props} />
));
ProductTabsPlaceholder.displayName = 'ProductTabsPlaceholder';

const ProductTabsHeaderPlaceholder = ({
  children,
}: Readonly<React.PropsWithChildren>) => (
  <>
    <div className='container flex items-center justify-between lg:mb-8'>
      <Skeleton className='h-10 w-24' />
      <div className='flex gap-8 max-lg:hidden'>{children}</div>
      <Skeleton className='h-10 w-24' />
    </div>

    <div className='container flex flex-nowrap items-center gap-4 overflow-hidden lg:hidden'>
      {children}
    </div>
  </>
);

const ProductTabsHeaderTriggersPlaceholder = () =>
  Array.from<number, string>({ length: 4 }, (_v, _k) => randomId()).map(
    value => <Skeleton key={value} className='h-10 w-20 shrink-0' />,
  );

const ProductTabsContentPlaceholder = React.forwardRef<
  React.ElementRef<typeof ProductViewPlaceholder>,
  React.ComponentPropsWithoutRef<typeof ProductViewPlaceholder>
>((props, ref) => <ProductViewPlaceholder ref={ref} {...props} />);
ProductTabsContentPlaceholder.displayName = 'ProductTabsContentPlaceholder';

export {
  ProductTabs,
  ProductTabsContent,
  ProductTabsContentPlaceholder,
  ProductTabsHeader,
  ProductTabsHeaderPlaceholder,
  ProductTabsHeaderTriggers,
  ProductTabsHeaderTriggersPlaceholder,
  ProductTabsPlaceholder,
};
