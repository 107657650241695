import { Fetch } from '@/lib';

type RequestResponse = {};

type RequestParameter = {
  subProductId: string;
};

const removeFromCart = async (params: RequestParameter) =>
  await Fetch<RequestResponse, RequestParameter>({
    url: 'cart/removeFromCart',
    method: 'DELETE',
    params,
  });

export { removeFromCart };
