import { Fetch } from '@/lib';
import { addToCart } from '@/services';

type RequestResponse = Awaited<ReturnType<typeof addToCart>>;

type RequestParameter = {
  subProductId: string;
};

type RequestBody = Pick<Parameters<typeof addToCart>[number], 'size'>;

const updateCartProduct = async (params: RequestParameter, body: RequestBody) =>
  await Fetch<RequestResponse, RequestParameter, RequestBody>({
    url: 'cart/updateCartProduct',
    method: 'PATCH',
    params,
    body,
  });

export { updateCartProduct };
