import { Fetch } from '@/lib';
import type { Purchase } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    USER: {
      PURCHASE: { BASE: BASE_USER_PURCHASE },
    },
  },
} = TAG;

type RequestResponse = Purchase;

type RequestParameter = {
  id: string;
};

const getPurchase = async (params: RequestParameter) =>
  await Fetch<RequestResponse, RequestParameter>({
    url: 'user/getPurchase',
    params,
    next: {
      tags: [BASE_USER_PURCHASE],
    },
  });

export { getPurchase };
