import { Fetch } from '@/lib';
import type { BrandSummary } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    BRAND: {
      BEST: { GLOBAL: GLOBAL_BEST_BRAND },
    },
  },
} = TAG;

type RequestResponse = {
  brands: BrandSummary[];
};

const getBestBrands = async () =>
  await Fetch<RequestResponse>({
    url: 'brand/getBestBrands',
    next: {
      tags: [GLOBAL_BEST_BRAND],
    },
  });

export { getBestBrands };
