import { useContext } from 'react';

import { LoginActionContext, LoginValueContext } from './context';

const useLoginValue = () => {
  const context = useContext(LoginValueContext);

  if (!context) {
    throw new Error('useLoginValue must be used within a <LoginContext />');
  }

  return context;
};

const useLoginAction = () => {
  const context = useContext(LoginActionContext);

  if (!context) {
    throw new Error('useLoginAction must be used within a <LoginContext />');
  }

  return context;
};

export { useLoginAction, useLoginValue };
