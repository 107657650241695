import { Fetch } from '@/lib';
import type {
  ProductsExtraResult,
  ProductSortType,
  ProductSummary,
} from '@/types';

type RequestResponse = {
  total: number;
  data: ProductSummary[];
  searchProductsExtraResult: ProductsExtraResult;
};

type RequestParameter = {
  sortType: ProductSortType;
  searchKey?: string;
  tags?: string[];
  categoriesIds?: string[];
  brandsIds?: string[];
  attributesNames?: string[];
  attributesValues?: string[];
  minPrice?: number;
  maxPrice?: number;
  priceStep?: number;
  isAvailable?: boolean;
  lowestDiscountPercentage?: number;
  pageSize: number;
  pageNumber: number;
};

const searchProducts = async ({
  sortType,
  searchKey,
  tags,
  categoriesIds,
  brandsIds,
  attributesNames,
  attributesValues,
  minPrice,
  maxPrice,
  priceStep,
  isAvailable,
  lowestDiscountPercentage,
  pageSize,
  pageNumber,
}: RequestParameter) => {
  const params: Record<string, unknown> = {
    sortType,
    pageSize,
    pageNumber,
  };

  if (searchKey) {
    params['searchKey'] = searchKey;
  }
  if (tags) {
    params['tags'] = tags;
  }
  if (categoriesIds) {
    params['categoriesIds'] = categoriesIds;
  }
  if (brandsIds) {
    params['brandsIds'] = brandsIds;
  }
  if (attributesNames) {
    params['attributesNames'] = attributesNames;
  }
  if (attributesValues) {
    params['attributesValues'] = attributesValues;
  }
  if (minPrice) {
    params['desiredPriceRange.minPrice'] = minPrice;
  }
  if (maxPrice) {
    params['desiredPriceRange.maxPrice'] = maxPrice;
  }
  if (priceStep) {
    params['desiredPriceRange.priceStep'] = priceStep;
  }
  if (isAvailable) {
    params['isAvailable'] = isAvailable;
  }
  if (lowestDiscountPercentage) {
    params['lowestDiscountPercentage'] = lowestDiscountPercentage;
  }

  return await Fetch<RequestResponse, typeof params>({
    url: 'product/searchProducts',
    params,
  });
};

export { searchProducts };
