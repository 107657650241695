'use client';

export {
  Accordion as AccessibleAccordion,
  AccordionItemButton as AccessibleAccordionButton,
  AccordionItemHeading as AccessibleAccordionHeading,
  AccordionItem as AccessibleAccordionItem,
  AccordionItemPanel as AccessibleAccordionPanel,
  AccordionItemState as AccessibleAccordionState,
} from 'react-accessible-accordion';
