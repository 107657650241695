import * as React from 'react';

import BalancerPrimitive from 'react-wrap-balancer';

import { cn, parseFromContent } from '@/utils';

const TypographyH1 = React.forwardRef<
  React.ElementRef<'h1'>,
  React.ComponentPropsWithoutRef<'h1'>
>(({ className, ...props }, ref) => (
  <h1
    ref={ref}
    className={cn(
      'scroll-m-20 text-4xl font-extrabold tracking-tight',
      className,
    )}
    {...props}
  />
));
TypographyH1.displayName = 'TypographyH1';

const TypographyH2 = React.forwardRef<
  React.ElementRef<'h2'>,
  React.ComponentPropsWithoutRef<'h2'>
>(({ className, ...props }, ref) => (
  <h2
    ref={ref}
    className={cn(
      'scroll-m-20 text-3xl font-semibold tracking-tight',
      className,
    )}
    {...props}
  />
));
TypographyH2.displayName = 'TypographyH2';

const TypographyH3 = React.forwardRef<
  React.ElementRef<'h3'>,
  React.ComponentPropsWithoutRef<'h3'>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      'scroll-m-20 text-2xl font-semibold tracking-tight',
      className,
    )}
    {...props}
  />
));
TypographyH3.displayName = 'TypographyH3';

const TypographyH4 = React.forwardRef<
  React.ElementRef<'h4'>,
  React.ComponentPropsWithoutRef<'h4'>
>(({ className, ...props }, ref) => (
  <h4
    ref={ref}
    className={cn(
      'scroll-m-20 text-xl font-semibold tracking-tight',
      className,
    )}
    {...props}
  />
));
TypographyH4.displayName = 'TypographyH4';

const TypographyH5 = React.forwardRef<
  React.ElementRef<'h5'>,
  React.ComponentPropsWithoutRef<'h5'>
>(({ className, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn('scroll-m-20 text-lg font-medium tracking-tight', className)}
    {...props}
  />
));
TypographyH5.displayName = 'TypographyH5';

const TypographyH6 = React.forwardRef<
  React.ElementRef<'h6'>,
  React.ComponentPropsWithoutRef<'h6'>
>(({ className, ...props }, ref) => (
  <h6
    ref={ref}
    className={cn(
      'scroll-m-20 text-base font-medium tracking-tight',
      className,
    )}
    {...props}
  />
));
TypographyH6.displayName = 'TypographyH6';

const TypographyBalancer = React.forwardRef<
  React.ElementRef<typeof BalancerPrimitive>,
  React.ComponentPropsWithoutRef<typeof BalancerPrimitive>
>(({ as = 'p', ...props }, ref) => (
  <BalancerPrimitive ref={ref} as={as} {...props} />
));
TypographyBalancer.displayName = 'TypographyBalancer';

const TypographyParagraph = React.forwardRef<
  React.ElementRef<'p'>,
  React.ComponentPropsWithoutRef<'p'>
>(({ className, ...props }, ref) => (
  <p ref={ref} className={cn('text-base leading-7', className)} {...props} />
));
TypographyParagraph.displayName = 'TypographyParagraph';

const TypographyMuted = React.forwardRef<
  React.ElementRef<'span'>,
  React.ComponentPropsWithoutRef<'span'>
>(({ className, ...props }, ref) => (
  <span
    ref={ref}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  />
));
TypographyMuted.displayName = 'TypographyMuted';

const TypographyStrong = React.forwardRef<
  React.ElementRef<'strong'>,
  React.ComponentPropsWithoutRef<'strong'>
>(({ className, ...props }, ref) => (
  <strong
    ref={ref}
    className={cn('text-lg font-semibold', className)}
    {...props}
  />
));
TypographyStrong.displayName = 'TypographyStrong';

const TypographyEmphasis = React.forwardRef<
  React.ElementRef<'em'>,
  React.ComponentPropsWithoutRef<'em'>
>(({ className, ...props }, ref) => (
  <em ref={ref} className={cn('italic', className)} {...props} />
));
TypographyEmphasis.displayName = 'TypographyEmphasis';

const TypographySmall = React.forwardRef<
  React.ElementRef<'small'>,
  React.ComponentPropsWithoutRef<'small'>
>(({ className, ...props }, ref) => (
  <small
    ref={ref}
    className={cn('text-sm font-medium leading-none', className)}
    {...props}
  />
));
TypographySmall.displayName = 'TypographySmall';

const TypographyDeleted = React.forwardRef<
  React.ElementRef<'del'>,
  React.ComponentPropsWithoutRef<'del'>
>(({ className, ...props }, ref) => (
  <del
    ref={ref}
    className={cn('text-sm text-muted-foreground line-through', className)}
    {...props}
  />
));
TypographyDeleted.displayName = 'TypographyDeleted';

const TypographyAddress = React.forwardRef<
  React.ElementRef<'address'>,
  React.ComponentPropsWithoutRef<'address'>
>(({ className, ...props }, ref) => (
  <address
    ref={ref}
    className={cn('text-sm font-medium not-italic', className)}
    {...props}
  />
));
TypographyAddress.displayName = 'TypographyAddress';

const TypographyTime = React.forwardRef<
  React.ElementRef<'time'>,
  React.ComponentPropsWithoutRef<'time'>
>(({ className, ...props }, ref) => (
  <time ref={ref} className={cn('text-sm font-medium', className)} {...props} />
));
TypographyTime.displayName = 'TypographyTime';

const TypographyBlockquote = React.forwardRef<
  React.ElementRef<'blockquote'>,
  React.ComponentPropsWithoutRef<'blockquote'>
>(({ className, ...props }, ref) => (
  <blockquote
    ref={ref}
    className={cn('border-s border-border ps-4 text-base italic', className)}
    {...props}
  />
));
TypographyBlockquote.displayName = 'TypographyBlockquote';

const TypographyCode = React.forwardRef<
  React.ElementRef<'code'>,
  React.ComponentPropsWithoutRef<'code'>
>(({ className, ...props }, ref) => (
  <code
    ref={ref}
    className={cn(
      'rounded bg-muted p-1 font-mono text-sm font-semibold',
      className,
    )}
    {...props}
  />
));
TypographyCode.displayName = 'TypographyCode';

type TypographyDescriptionProps = StrictOmit<
  React.ComponentPropsWithoutRef<'div'>,
  'dangerouslySetInnerHTML'
> &
  Record<'description', string>;

const TypographyDescription = React.forwardRef<
  React.ElementRef<'div'>,
  TypographyDescriptionProps
>(({ className, description, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('tiptap', className)}
    dangerouslySetInnerHTML={{ __html: parseFromContent(description) }}
    {...props}
  />
));
TypographyDescription.displayName = 'TypographyDescription';

const TypographySuperscript = React.forwardRef<
  React.ElementRef<'sup'>,
  React.ComponentPropsWithoutRef<'sup'>
>(({ className, ...props }, ref) => (
  <sup ref={ref} className={cn('text-xs', className)} {...props} />
));
TypographySuperscript.displayName = 'TypographySuperscript';

const TypographySubscript = React.forwardRef<
  React.ElementRef<'sub'>,
  React.ComponentPropsWithoutRef<'sub'>
>(({ className, ...props }, ref) => (
  <sub ref={ref} className={cn('text-xs', className)} {...props} />
));
TypographySubscript.displayName = 'TypographySubscript';

export {
  TypographyAddress,
  TypographyBalancer,
  TypographyBlockquote,
  TypographyCode,
  TypographyDeleted,
  TypographyDescription,
  TypographyEmphasis,
  TypographyH1,
  TypographyH2,
  TypographyH3,
  TypographyH4,
  TypographyH5,
  TypographyH6,
  TypographyMuted,
  TypographyParagraph,
  TypographySmall,
  TypographyStrong,
  TypographySubscript,
  TypographySuperscript,
  TypographyTime,
};
