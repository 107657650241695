const PATHNAME = {
  DEFAULT: {
    BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
    API_BASE_URL: process.env.NEXT_PUBLIC_API_BASE_URL,
    GOOGLE_SITE_VERIFICATION: process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION,
    GOOGLE_TAG_MANAGER: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER,
    SLUG_SEPARATOR: '/',
    TIMEZONE: 'Asia/Tehran',
    LOCALE: 'fa-IR',
  },
  SOCIAL_MEDIA: {
    YOUTUBE: {
      BASE_URL: process.env.NEXT_PUBLIC_YOUTUBE_BASE_URL,
      SLUG: '/@kashiland_com/',
    },
    APARAT: {
      BASE_URL: process.env.NEXT_PUBLIC_APARAT_BASE_URL,
      SLUG: '/kashiland_com/',
    },
    INSTAGRAM: {
      BASE_URL: process.env.NEXT_PUBLIC_INSTAGRAM_BASE_URL,
      SLUG: '/kashiland_com/',
    },
    PINTEREST: {
      BASE_URL: process.env.NEXT_PUBLIC_PINTEREST_BASE_URL,
      SLUG: '/kashiland_com/',
    },
    LINKEDIN: {
      BASE_URL: process.env.NEXT_PUBLIC_LINKEDIN_BASE_URL,
      SLUG: '/kashiland-com-70b580211/',
    },
    TWITTER: {
      BASE_URL: process.env.NEXT_PUBLIC_TWITTER_BASE_URL,
      SLUG: '/Kashiland_com/',
    },
    TELEGRAM: {
      BASE_URL: process.env.NEXT_PUBLIC_TELEGRAM_BASE_URL,
      SLUG: '/Kashiland/',
    },
    WHATSAPP: {
      BASE_URL: process.env.NEXT_PUBLIC_WHATSAPP_BASE_URL,
      SLUG: '/989120872957/',
    },
  },
  VERIFICATION: {
    ENAMAD: {
      BASE_URL: process.env.NEXT_PUBLIC_ENAMAD_BASE_URL,
      SLUG: '?id=168151&Code=hNaszTig5oklezyS1raa',
    },
  },
  CONTACT: {
    PUBLIC: {
      EMAIL: process.env.NEXT_PUBLIC_EMAIL,
      FAX: process.env.NEXT_PUBLIC_FAX,
      PHONE: process.env.NEXT_PUBLIC_PHONE,
      FORMATTED_PHONE: process.env.NEXT_PUBLIC_FORMATTED_PHONE,
      ADDRESS: process.env.NEXT_PUBLIC_ADDRESS,
    },
  },
  LOCAL: {
    HOME: {
      BASE_URL: '/',
    },
    ABOUT: {
      BASE_URL: '/about/',
    },
    CONTACT: {
      BASE_URL: '/contact/',
    },
    CART: {
      BASE_URL: '/cart/',
    },
    CHECKOUT: {
      BASE_URL: '/checkout/',
    },
    PAPER_INVOICE: {
      BASE_URL: '/paper-invoice/',
    },
    PRODUCT: {
      BASE_URL: '/product/',
    },
    CATEGORY: {
      BASE_URL: '/category/',
    },
    BRANDS: {
      BASE_URL: '/brands/',
    },
    CATALOGS: {
      BASE_URL: '/catalogs/',
    },
    OFFERS: {
      BASE_URL: '/offers/',
    },
    DASHBOARD: {
      BASE_URL: '/dashboard/',
      ACCOUNT: {
        BASE_URL: 'account',
      },
      PURCHASES: {
        BASE_URL: 'purchases',
      },
      ADDRESSES: {
        BASE_URL: 'addresses',
      },
      FAVORITES: {
        BASE_URL: 'favorites',
      },
    },
    LOGIN: {
      BASE_URL: '/login/',
    },
    MAG: {
      BASE_URL: '/mag/',
      LAST_ARTICLE: {
        BASE_URL: 'last-article',
      },
    },
    AMP_EDN: {
      BASE_URL: '/AMP_EDN/',
    },
    PORTALS: {
      BASE_URL: '/Portals/',
    },
    ADS: {
      BASE_URL: '/ads/',
    },
    AMP: {
      BASE_URL: '/amp/',
    },
    FORUM: {
      BASE_URL: '/forum/',
    },
    NEWS: {
      BASE_URL: '/news/',
    },
    PRO: {
      BASE_URL: '/pro/',
    },
    STORE: {
      BASE_URL: '/store/',
    },
    VIDEO: {
      BASE_URL: '/video/',
    },
    SITEMAP: {
      BASE_URL: 'sitemap',
      SUFFIX: '_index',
      EXTENSION: '.xml',
    },
  },
  QUERY_PARAM: {
    DEFAULT: {
      SORT_TYPE: 'Newest',
      PAGE_SIBLING: 0,
      PAGE_NUMBER: 1,
      PAGE_SIZE: 20,
      POST_SIZE: 8,
      SITEMAP_SIZE: 5_000,
    },
    SYMBOL: {
      QUESTION_MARK: '?',
      ASSIGNMENT: '=',
      AMPERSAND: '&',
      PIPELINE: '|',
      AT_SIGN: '@',
      PERCENT: '%',
      HYPHEN: '-',
      COLON: ':',
      COMMA: ',',
      PLUS: '+',
      HASH: '#',
      DOT: '.',
    },
    HASH: {
      PRODUCT: {
        TABS: 'product-tabs',
        RELATED_PRODUCTS: 'product-related-products',
      },
    },
    SORT_TYPE: {
      BASE_URL: 'sort-type',
      NEWEST: 'Newest',
      OLDEST: 'Oldest',
      CHEAPEST: 'Cheapest',
      BEST_SELLING: 'BestSelling',
      MOST_POPULAR: 'MostPopular',
      MOST_RELEVANT: 'MostRelevant',
      MOST_VISITED: 'MostVisited',
      MOST_EXPENSIVE: 'MostExpensive',
    },
    ID: {
      BASE_URL: 'id',
    },
    MESSAGE: {
      BASE_URL: 'message',
    },
    CULTURE: {
      BASE_URL: 'culture',
    },
    PAGE_SIZE: {
      BASE_URL: 'page-size',
    },
    PAGE_NUMBER: {
      BASE_URL: 'page-number',
    },
    SEARCH_KEY: {
      BASE_URL: 'search-key',
    },
    BRANDS_IDS: {
      BASE_URL: 'brands-ids',
    },
    CATEGORIES_IDS: {
      BASE_URL: 'categories-ids',
    },
    ATTRIBUTES_NAMES: {
      BASE_URL: 'attributes-names',
    },
    ATTRIBUTES_VALUES: {
      BASE_URL: 'attributes-values',
    },
    MIN_PRICE: {
      BASE_URL: 'min-price',
    },
    MAX_PRICE: {
      BASE_URL: 'max-price',
    },
    IS_AVAILABLE: {
      BASE_URL: 'is-available',
    },
    PURCHASE_STATUS: {
      BASE_URL: 'purchase-status',
    },
    LOWEST_DISCOUNT_PERCENTAGE: {
      BASE_URL: 'lowest-discount-percentage',
    },
    TAGS: {
      BASE_URL: 'tags',
    },
  },
} as const;

export { PATHNAME };
