'use client';

import * as React from 'react';

import Image from 'next/image';

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  ImageBrokenIcon,
  LocaleLink,
  Skeleton,
} from '@/components';
import type { BrandSummary } from '@/types';
import { cn, generateFileUrl, PATHNAME } from '@/utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    BRANDS: { BASE_URL: BRANDS_BASE_URL },
  },
} = PATHNAME;

type BrandCardContextProps = BrandSummary;

const BrandCardContext = React.createContext<BrandCardContextProps>(
  {} as BrandCardContextProps,
);

const useBrandCard = () => {
  const context = React.useContext(BrandCardContext);

  if (!context) {
    throw new Error('useBrandCard must be used within a <BrandCard />');
  }

  return context;
};

type BrandCardProps = React.ComponentPropsWithoutRef<typeof Card> &
  BrandCardContextProps;

const BrandCard = React.forwardRef<
  React.ElementRef<typeof Card>,
  BrandCardProps
>(
  (
    { id, slug, title, bannerId, iconId, className, children, ...props },
    ref,
  ) => (
    <BrandCardContext.Provider
      value={{
        id,
        slug,
        title,
        bannerId,
        iconId,
      }}
    >
      <LocaleLink
        href={`${BRANDS_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`}
        className='block h-full'
      >
        <Card ref={ref} className={cn('relative', className)} {...props}>
          {children}
        </Card>
      </LocaleLink>
    </BrandCardContext.Provider>
  ),
);
BrandCard.displayName = 'BrandCard';

const BrandCardHeader = React.forwardRef<
  React.ElementRef<typeof CardHeader>,
  React.ComponentPropsWithoutRef<typeof CardHeader>
>(({ className, ...props }, ref) => {
  const { title, iconId } = useBrandCard();

  const { width, height } = {
    width: 300,
    height: 300,
  };

  return (
    <CardHeader ref={ref} className={cn('h-48', className)} {...props}>
      {iconId ? (
        <Image
          src={generateFileUrl('Brand', iconId, width, height)}
          className='size-full object-contain'
          width={width}
          height={height}
          alt={title}
        />
      ) : (
        <div className='grid size-full place-items-center'>
          <ImageBrokenIcon
            weight='thin'
            className='size-full max-h-20 max-w-20 text-muted-foreground'
          />
        </div>
      )}
    </CardHeader>
  );
});
BrandCardHeader.displayName = 'BrandCardHeader';

const BrandCardContent = React.forwardRef<
  React.ElementRef<typeof CardContent>,
  React.ComponentPropsWithoutRef<typeof CardContent>
>(({ className, ...props }, ref) => {
  const { title } = useBrandCard();

  return (
    <CardContent ref={ref} className={cn('text-center', className)} {...props}>
      <CardTitle>{title}</CardTitle>
    </CardContent>
  );
});
BrandCardContent.displayName = 'BrandCardContent';

const BrandCardPlaceholder = React.forwardRef<
  React.ElementRef<typeof Card>,
  React.ComponentPropsWithoutRef<typeof Card>
>(({ className, ...props }, ref) => (
  <Card ref={ref} className={cn('w-full gap-8', className)} {...props} />
));
BrandCardPlaceholder.displayName = 'BrandCardPlaceholder';

const BrandCardHeaderPlaceholder = React.forwardRef<
  React.ElementRef<typeof CardHeader>,
  React.ComponentPropsWithoutRef<typeof CardHeader>
>(({ className, ...props }, ref) => (
  <CardHeader ref={ref} className={cn('h-48', className)} {...props}>
    <Skeleton className='size-full' />
  </CardHeader>
));
BrandCardHeaderPlaceholder.displayName = 'BrandCardHeaderPlaceholder';

const BrandCardContentPlaceholder = React.forwardRef<
  React.ElementRef<typeof CardContent>,
  React.ComponentPropsWithoutRef<typeof CardContent>
>(({ className, ...props }, ref) => (
  <CardContent
    ref={ref}
    className={cn('flex-col items-center', className)}
    {...props}
  >
    <Skeleton className='h-3 w-2/3' />
  </CardContent>
));
BrandCardContentPlaceholder.displayName = 'BrandCardContentPlaceholder';

export {
  BrandCard,
  BrandCardContent,
  BrandCardContentPlaceholder,
  BrandCardHeader,
  BrandCardHeaderPlaceholder,
  BrandCardPlaceholder,
};
export { useBrandCard };
