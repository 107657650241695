import { clsx } from 'clsx';
import type { ClassValue as ClassNameValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { v4 as uuId } from 'uuid';
import type { V4Options as UUIdOptions } from 'uuid';

import type { BaseTreeOption } from '@/types';
import { PATHNAME } from '@/utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  QUERY_PARAM: {
    DEFAULT: { PAGE_SIZE: DEFAULT_PAGE_SIZE, PAGE_NUMBER: DEFAULT_PAGE_NUMBER },
    SYMBOL: { COMMA: COMMA_SYMBOL, DOT: DOT_SYMBOL, PLUS: PLUS_SYMBOL },
  },
} = PATHNAME;

const cn = (...inputs: ClassNameValue[]) => twMerge(clsx(inputs));

const randomId = (options?: UUIdOptions) => uuId(options);

const validateDecimal = (value: string) =>
  /^(-)?(\d)+(\.)?(\d+)?$/gim.test(value);

const validateDecimalPoint = (value: string) =>
  /^(-)?(\d)+(\.)(0[0-9]{0,2}|[1-9]0[0-9]?|[1-9][0-9]0)?$/gim.test(value);

const normalizeDecimal = (value: string, replaceValue = '') =>
  value.replace(/[^\d\.-]/gim, replaceValue);

const normalizeDigit = (value: string) =>
  value.replace(/[٠-٩۰-۹]/g, d =>
    String(((d.charCodeAt(0) % 1776) % 1632) % 1584),
  );

const normalizeToString = (input: any = ''): string => input.toString();

const combineNumber = (value: string, replaceValue: string = COMMA_SYMBOL) =>
  value.replace(/\B(?=(\d{3})+(?!\d))/gim, replaceValue);

const fixedParseFloat = (value: number, fractionDigits = 0) =>
  parseFloat(value.toFixed(fractionDigits));

const deepFlatFilter = <T>(data: T[], depth = Infinity) =>
  data.flat(depth).filter(Boolean) as Array<NonNullable<T>>;

const getObjectValues = <T extends object>(obj: T) =>
  Object.values(obj).filter(Boolean) as Array<NonNullable<T[keyof T]>>;

const getObjectEntries = <T extends object>(obj: T) =>
  Object.entries(obj) as Array<[keyof T, T[keyof T]]>;

const fromObjectEntries = <T extends object>(
  entries: Iterable<readonly [keyof T, T[keyof T]]>,
) => Object.fromEntries(entries) as Record<keyof T, T[keyof T]>;

const joinEncodedSegments = (
  segments: string[],
  separator: string = DEFAULT_SLUG_SEPARATOR,
) => encodeURIComponent(segments.join(separator));

const joinDecodedSegments = (
  segments: string[],
  separator: string = DEFAULT_SLUG_SEPARATOR,
) => decodeURIComponent(segments.join(separator));

const parseFromContent = (
  content: string,
  type: DOMParserSupportedType = 'text/html',
) =>
  typeof window !== 'undefined'
    ? new DOMParser().parseFromString(content, type).body.innerHTML
    : '';

const preciseRound = (size: number, amount = 1, decimals = 3) => {
  const multiplier = Math.pow(10, Math.abs(decimals));
  return Math.round(size * amount * multiplier) / multiplier;
};

const pageNumberRange = (
  start: number,
  end: number,
  initial: number = DEFAULT_PAGE_NUMBER,
) =>
  Array.from<number, number>(
    { length: end - start + initial },
    (v, k) => k + start,
  );

const pageSizeRange = (
  total: number,
  step: number = DEFAULT_PAGE_SIZE,
  start: number = DEFAULT_PAGE_SIZE,
) =>
  Array.from<number, number>(
    { length: Math.ceil((total + step - start) / step) },
    (v, k) => start + k * step,
  );

const padString = (
  value: string,
  type: 'padStart' | 'padEnd' = 'padStart',
  maxLength = 2,
  fillString = '0',
) => value[type](maxLength, fillString);

const replaceExtraSeparator = (
  value: string,
  type: 'space' | 'dash' = 'space',
  replaceValue = '-',
) =>
  value.trim().replace(type === 'space' ? /\s+/gim : /[-]+/gim, replaceValue);

const filterObjectFromEntries = <T extends object, K extends Array<keyof T>>(
  obj: T,
  fields: K,
) =>
  Object.fromEntries(
    getObjectEntries(obj).filter(([key]) => !fields.includes(key)),
  ) as StrictOmit<T, K[number]>;

const getObjectFromEntries = <T extends object, K extends Array<keyof T>>(
  obj: T,
  fields: K,
) =>
  Object.fromEntries(
    getObjectEntries(obj).filter(([key]) => fields.includes(key)),
  ) as Pick<T, K[number]>;

const addItemToValue = (value: string, separator: string, item: string) =>
  value === ''
    ? item
    : value.includes(item)
      ? value
      : value.split(separator).concat(item).join(separator);

const removeItemFromValue = (value: string, separator: string, item: string) =>
  value !== ''
    ? value
        .split(separator)
        .filter(filteredValue => filteredValue !== item)
        .join(separator)
    : '';

const adjustPageNumber = (
  total: number,
  page: number,
  updatePage: (page: number) => void,
) => {
  if (page > total && total >= DEFAULT_PAGE_NUMBER) {
    updatePage(total);
  }
};

const hasIntersection = <T>(i1: Iterable<T>, i2: Iterable<T>) => {
  const secondIterable = new Set(i2);
  if (secondIterable.size === 0) return false;
  return Array.from(i1).some(value => secondIterable.has(value));
};

const flatMapTreeOption = <T extends BaseTreeOption<T>>(options: T[]): T[] => {
  if (options.length === 0) return [];
  return options.flatMap(option => [
    option,
    ...flatMapTreeOption(option.children),
  ]);
};

const shortenNumber = (
  value: number,
  prefix: string = PLUS_SYMBOL,
  suffix: string = '',
) => {
  if (value <= 0) return value;

  const position = value.toString().length - 1;
  const threshold = Math.pow(10, position || 1) - 1;

  if (value <= threshold) return value;

  return `${prefix}${threshold}${suffix}`;
};

const triggerPathname = (pathname: string, triggers: string[]) =>
  triggers.some(trigger => {
    const decodedPathname = joinDecodedSegments(
      pathname.split(DEFAULT_SLUG_SEPARATOR),
    );
    const decodedTrigger = joinDecodedSegments(
      trigger.split(DEFAULT_SLUG_SEPARATOR),
    );
    const splittedTrigger = trigger
      .split(DEFAULT_SLUG_SEPARATOR)
      .filter(Boolean);

    if (splittedTrigger.length > 0) {
      return (
        decodedPathname === decodedTrigger ||
        decodedPathname.startsWith(decodedTrigger)
      );
    }
    return decodedPathname === decodedTrigger;
  });

const enforceValueLimits = (
  value: unknown,
  min?: string | number,
  max?: string | number,
) => {
  const normalizedValue = normalizeToString(value);
  const normalizedMin = min !== undefined ? normalizeToString(min) : undefined;
  const normalizedMax = max !== undefined ? normalizeToString(max) : undefined;

  let constrainedValue = normalizedValue;

  if (!validateDecimalPoint(constrainedValue)) {
    const numericValue = Number(normalizedValue);
    const numericMinValue =
      normalizedMin !== undefined ? Number(normalizedMin) : undefined;
    const numericMaxValue =
      normalizedMax !== undefined ? Number(normalizedMax) : undefined;

    const isNaNValues =
      isNaN(numericValue) ||
      (numericMinValue !== undefined && isNaN(numericMinValue)) ||
      (numericMaxValue !== undefined && isNaN(numericMaxValue));

    if (!isNaNValues) {
      if (numericMinValue !== undefined && numericValue <= numericMinValue) {
        constrainedValue = String(normalizedMin);
      }
      if (numericMaxValue !== undefined && numericValue >= numericMaxValue) {
        constrainedValue = String(normalizedMax);
      }
    }
  }

  return constrainedValue;
};

const getTimeProgress = (
  targetDate: Date,
  middleDate: Date,
  startDate: Date,
) => {
  const targetDateTime = targetDate.getTime();
  const middleDateTime = middleDate.getTime();
  const startDateTime = startDate.getTime();

  const isBeforeStart = middleDateTime < startDateTime;
  const isAfterTarget = middleDateTime > targetDateTime;
  const isOutOfRange = isBeforeStart || isAfterTarget;

  const timeRange = targetDateTime - startDateTime;
  const timeElapsed = !isOutOfRange ? middleDateTime - startDateTime : 0;
  const timeRemaining = !isOutOfRange ? targetDateTime - middleDateTime : 0;
  const timeProgress = isBeforeStart
    ? 0
    : isAfterTarget
      ? 1
      : fixedParseFloat(timeElapsed / timeRange, 2);

  return {
    targetDateTime,
    middleDateTime,
    startDateTime,
    isBeforeStart,
    isAfterTarget,
    isOutOfRange,
    timeRange,
    timeElapsed,
    timeRemaining,
    timeProgress,
  };
};

const formatCurrency = (
  value: string | number | bigint,
  decimals = 0,
  allowCombine = false,
  allowNegative = false,
  prefix = '',
  suffix = '',
) => {
  let numeric: string | number | bigint = BigInt(0);
  let formatted = '';

  if (typeof value === 'string') {
    const showDecimals = decimals > 0;
    const normalizedValue = normalizeDecimal(value);

    if (validateDecimal(normalizedValue)) {
      if (showDecimals && validateDecimalPoint(normalizedValue)) {
        numeric = normalizedValue;
        formatted = normalizedValue;
      } else {
        try {
          const [integerPart = '0', fractionalPart] =
            normalizedValue.split(DOT_SYMBOL);

          numeric = BigInt(integerPart);

          if (showDecimals && fractionalPart !== undefined) {
            formatted = `${numeric.toString()}${DOT_SYMBOL}${fractionalPart.substring(0, decimals)}`;
            numeric = parseFloat(formatted);
          } else {
            formatted = numeric.toString();
          }
        } catch {
          numeric = fixedParseFloat(parseFloat(normalizedValue), decimals);
          formatted = numeric.toString();
        }
      }
    }
  } else if (typeof value === 'bigint') {
    numeric = value;
    formatted = value.toString();
  } else {
    try {
      if (value > Number.MAX_SAFE_INTEGER) {
        numeric = BigInt(Math.floor(value));
        formatted = numeric.toString();
      } else {
        numeric = fixedParseFloat(value, decimals);
        formatted = numeric.toString();
      }
    } catch {
      numeric = fixedParseFloat(value, decimals);
      formatted = numeric.toString();
    }
  }

  if (
    !allowNegative &&
    (((typeof numeric === 'string' || typeof numeric === 'number') &&
      Number(numeric) < 0) ||
      (typeof numeric === 'bigint' && numeric < BigInt(0)))
  ) {
    numeric =
      typeof numeric === 'bigint' ? -numeric : Math.abs(Number(numeric));
    formatted = numeric.toString();
  }

  if (allowCombine) {
    const hasDecimalPoint = formatted.includes(DOT_SYMBOL);
    const [integerPart, fractionalPart] = formatted.split(DOT_SYMBOL);

    formatted = `${combineNumber(integerPart)}${hasDecimalPoint ? DOT_SYMBOL : ''}${fractionalPart || ''}`;
  }

  return {
    numeric,
    formatted: `${prefix}${formatted}${suffix}`,
  };
};

export {
  addItemToValue,
  adjustPageNumber,
  cn,
  combineNumber,
  deepFlatFilter,
  enforceValueLimits,
  filterObjectFromEntries,
  fixedParseFloat,
  flatMapTreeOption,
  formatCurrency,
  fromObjectEntries,
  getObjectEntries,
  getObjectFromEntries,
  getObjectValues,
  getTimeProgress,
  hasIntersection,
  joinDecodedSegments,
  joinEncodedSegments,
  normalizeDecimal,
  normalizeDigit,
  normalizeToString,
  padString,
  pageNumberRange,
  pageSizeRange,
  parseFromContent,
  preciseRound,
  randomId,
  removeItemFromValue,
  replaceExtraSeparator,
  shortenNumber,
  triggerPathname,
  validateDecimal,
  validateDecimalPoint,
};
