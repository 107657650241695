import { useMemo } from 'react';

import { pageNumberRange, PATHNAME } from '@/utils';

const {
  QUERY_PARAM: {
    DEFAULT: {
      PAGE_NUMBER: DEFAULT_PAGE_NUMBER,
      PAGE_SIZE: DEFAULT_PAGE_SIZE,
      PAGE_SIBLING: DEFAULT_PAGE_SIBLING,
    },
  },
} = PATHNAME;

type PaginationOption = Partial<
  Record<'total' | 'current' | 'size' | 'sibling', number>
>;

const usePagination = ({
  total = DEFAULT_PAGE_SIZE,
  current = DEFAULT_PAGE_NUMBER,
  size = DEFAULT_PAGE_SIZE,
  sibling = DEFAULT_PAGE_SIBLING,
}: PaginationOption) => {
  const totalPage = Math.ceil(total / size);

  const pagination = useMemo<(number | 'DOTS')[]>(() => {
    const firstPageIndex = DEFAULT_PAGE_NUMBER;
    const lastPageIndex = totalPage;

    // NOTE: Pages count is determined as siblingCount + firstPage + lastPage + current + 2 * DOTS
    const pagesCount = sibling + 5;

    if (pagesCount >= totalPage) {
      return pageNumberRange(firstPageIndex, totalPage);
    }

    const leftSiblingIndex = Math.max(current - sibling, 1);
    const rightSiblingIndex = Math.min(current + sibling, totalPage);
    const showLeftDots = leftSiblingIndex > 2;
    const showRightDots = rightSiblingIndex < totalPage - 2;

    if (!showLeftDots && showRightDots) {
      let leftItemCount = 3 + 2 * sibling;
      let leftRange = pageNumberRange(1, leftItemCount);

      return [...leftRange, 'DOTS', totalPage];
    }
    if (showLeftDots && !showRightDots) {
      let rightItemCount = 3 + 2 * sibling;
      let rightRange = pageNumberRange(
        totalPage - rightItemCount + 1,
        totalPage,
      );
      return [firstPageIndex, 'DOTS', ...rightRange];
    }
    if (showLeftDots && showRightDots) {
      let middleRange = pageNumberRange(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, 'DOTS', ...middleRange, 'DOTS', lastPageIndex];
    }

    return [];
  }, [totalPage, current, sibling]);

  return { totalPage, pagination };
};

export { usePagination };
