'use client';

import { useReducer } from 'react';

import { ProductActionContext, ProductValueContext } from './context';
import { useProductAction, useProductValue } from './hook';
import { initialState } from './initial';
import { reducer } from './reducer';

const ProductContext = ({
  children,
  ...props
}: Readonly<React.PropsWithChildren<typeof initialState>>) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...props });

  return (
    <ProductValueContext.Provider value={state}>
      <ProductActionContext.Provider value={dispatch}>
        {children}
      </ProductActionContext.Provider>
    </ProductValueContext.Provider>
  );
};

export { ProductContext, useProductAction, useProductValue };
