import { Fetch } from '@/lib';
import type { BrandSummary } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    BRAND: {
      RELATED_TO_CATEGORY: { GLOBAL: GLOBAL_BRAND_RELATED_TO_CATEGORY },
    },
  },
} = TAG;

type RequestResponse = {
  brands: BrandSummary[];
};

type RequestParameter = {
  categoryId: string;
};

const getRelatedBrandsToCategory = async (params: RequestParameter) =>
  await Fetch<RequestResponse, RequestParameter>({
    url: 'brand/getRelatedBrandsToCategory',
    params,
    next: {
      tags: [GLOBAL_BRAND_RELATED_TO_CATEGORY],
    },
  });

export { getRelatedBrandsToCategory };
