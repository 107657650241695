import { Fetch } from '@/lib';
import type { TorobMetadata } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    TOROB: {
      PRODUCT_METADATA: { BASE: BASE_TOROB_PRODUCT_METADATA },
    },
  },
} = TAG;

type RequestResponse = TorobMetadata;

type RequestParameter = {
  slug: string;
};

const getProductInfo = async ({ slug }: RequestParameter) =>
  await Fetch<RequestResponse, RequestParameter>({
    url: `torob/getProductInfo/${slug}`,
    next: {
      tags: [BASE_TOROB_PRODUCT_METADATA],
    },
  });

export { getProductInfo };
