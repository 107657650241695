import { Fetch } from '@/lib';
import type { PageBanner, PageBuilderType, PageSlider } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    PAGE_BUILDER: {
      PAGE_BANNERS: { BASE: BASE_PAGE_BANNERS },
    },
  },
} = TAG;

type RequestResponse = Partial<
  Record<
    'pageSettings',
    Record<`Landing_Pos${1}_${'Slider'}`, PageSlider> &
      Record<
        | `Landing_Pos${1}_${'Top_Banner' | 'Bottom_Banner'}`
        | `Landing_Pos${2}_${'Mid_Banner'}`
        | `Landing_Pos${4}_${'Right_Banner' | 'Left_Banner'}`
        | `Landing_Pos${2 | 6}_${
            | 'TopRight_Banner'
            | 'TopLeft_Banner'
            | 'BottomRight_Banner'
            | 'BottomLeft_Banner'}`,
        PageBanner
      >
  >
>;

type RequestParameter = {
  type: PageBuilderType[];
};

const getPageBanners = async (params: RequestParameter) =>
  await Fetch<RequestResponse, RequestParameter>({
    url: 'pageBuilder/getPageBanners',
    params,
    next: {
      tags: [BASE_PAGE_BANNERS],
    },
  });

export { getPageBanners };
