'use client';

import * as React from 'react';

import { EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';

import { Button, CaretLeftIcon, CaretRightIcon } from '@/components';
import { cn, randomId } from '@/utils';

type EmblaOptions = Parameters<typeof useEmblaCarousel>[0];
type EmblaPlugins = Parameters<typeof useEmblaCarousel>[1];
type EmblaRef = ReturnType<typeof useEmblaCarousel>[0];
type EmblaApi = ReturnType<typeof useEmblaCarousel>[1];

type EmblaProps = {
  options?: EmblaOptions;
  plugins?: EmblaPlugins;
  setApi?: (api: EmblaApi) => void;
};

type CarouselContextProps = EmblaProps & {
  emblaRef: EmblaRef;
  emblaApi: EmblaApi;
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
};

const CarouselContext = React.createContext<CarouselContextProps>(
  {} as CarouselContextProps,
);

const useCarousel = () => {
  const context = React.useContext(CarouselContext);

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />');
  }

  return context;
};

type CarouselProps = React.ComponentPropsWithoutRef<'div'> & EmblaProps;

const Carousel = React.forwardRef<React.ElementRef<'div'>, CarouselProps>(
  (
    {
      options = { axis: 'x', direction: 'rtl' },
      plugins,
      role = 'region',
      'aria-roledescription': ariaRoledescription = 'carousel',
      className,
      setApi,
      onKeyDownCapture,
      children,
      ...props
    },
    ref,
  ) => {
    const [emblaRef, emblaApi] = useEmblaCarousel(options, plugins);
    const [canScrollPrev, setCanScrollPrev] = React.useState(false);
    const [canScrollNext, setCanScrollNext] = React.useState(false);

    const onSelect = React.useCallback((api: EmblaApi) => {
      if (api) {
        setCanScrollPrev(api.canScrollPrev());
        setCanScrollNext(api.canScrollNext());
      }
    }, []);

    const scrollPrev = React.useCallback(() => {
      emblaApi?.scrollPrev();
    }, [emblaApi]);

    const scrollNext = React.useCallback(() => {
      emblaApi?.scrollNext();
    }, [emblaApi]);

    const handleKeyDown = React.useCallback<
      React.KeyboardEventHandler<HTMLDivElement>
    >(
      event => {
        if (event.key === 'ArrowLeft') {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === 'ArrowRight') {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext],
    );

    React.useEffect(() => {
      if (emblaApi && setApi) {
        setApi(emblaApi);
      }
    }, [emblaApi, setApi]);

    React.useEffect(() => {
      if (emblaApi) {
        onSelect(emblaApi);
        emblaApi.on('reInit', onSelect);
        emblaApi.on('select', onSelect);
      }

      return () => {
        if (emblaApi) {
          emblaApi.off('select', onSelect);
        }
      };
    }, [emblaApi, onSelect]);

    return (
      <CarouselContext.Provider
        value={{
          options,
          plugins,
          setApi,
          emblaRef,
          emblaApi,
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
        }}
      >
        <div
          ref={ref}
          role={role}
          aria-roledescription={ariaRoledescription}
          className={cn('relative', className)}
          onKeyDownCapture={onKeyDownCapture ?? handleKeyDown}
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    );
  },
);
Carousel.displayName = 'Carousel';

const CarouselContent = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => {
  const { options, emblaRef } = useCarousel();

  return (
    <div ref={emblaRef} className='size-full overflow-hidden'>
      <div
        ref={ref}
        className={cn(
          'flex',
          options?.axis === 'x' ? '-ms-4' : '-mt-4 flex-col',
          className,
        )}
        {...props}
      />
    </div>
  );
});
CarouselContent.displayName = 'CarouselContent';

const CarouselItem = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(
  (
    {
      role = 'group',
      'aria-roledescription': ariaRoledescription = 'slide',
      className,
      ...props
    },
    ref,
  ) => {
    const { options } = useCarousel();

    return (
      <div
        ref={ref}
        role={role}
        aria-roledescription={ariaRoledescription}
        className={cn(
          'min-w-0 shrink-0 grow-0 basis-full',
          options?.axis === 'x' ? 'ps-4' : 'pt-4',
          className,
        )}
        {...props}
      />
    );
  },
);
CarouselItem.displayName = 'CarouselItem';

const CarouselPrevious = React.forwardRef<
  React.ElementRef<typeof Button>,
  React.ComponentPropsWithoutRef<typeof Button>
>(
  (
    {
      variant = 'muted',
      size = 'icon',
      className,
      disabled,
      onClick,
      ...props
    },
    ref,
  ) => {
    const { options, scrollPrev, canScrollPrev } = useCarousel();

    return (
      <Button
        ref={ref}
        variant={variant}
        size={size}
        className={cn(
          'absolute size-12 rounded-full',
          options?.axis === 'x'
            ? '-start-6 top-1/2 -translate-y-1/2'
            : '-top-6 start-1/2 -translate-x-1/2 rotate-90',
          !canScrollPrev && 'invisible',
          className,
        )}
        disabled={disabled ?? !canScrollPrev}
        onClick={onClick ?? scrollPrev}
        {...props}
      >
        <CaretRightIcon className='text-muted-foreground' />
        <span className='sr-only'>اسلاید قبلی</span>
      </Button>
    );
  },
);
CarouselPrevious.displayName = 'CarouselPrevious';

const CarouselNext = React.forwardRef<
  React.ElementRef<typeof Button>,
  React.ComponentPropsWithoutRef<typeof Button>
>(
  (
    {
      variant = 'muted',
      size = 'icon',
      className,
      disabled,
      onClick,
      ...props
    },
    ref,
  ) => {
    const { options, scrollNext, canScrollNext } = useCarousel();

    return (
      <Button
        ref={ref}
        variant={variant}
        size={size}
        className={cn(
          'absolute size-12 rounded-full',
          options?.axis === 'x'
            ? '-end-6 top-1/2 -translate-y-1/2'
            : '-bottom-6 end-1/2 -translate-x-1/2 rotate-90',
          !canScrollNext && 'invisible',
          className,
        )}
        disabled={disabled ?? !canScrollNext}
        onClick={onClick ?? scrollNext}
        {...props}
      >
        <CaretLeftIcon className='text-muted-foreground' />
        <span className='sr-only'>اسلاید بعدی</span>
      </Button>
    );
  },
);
CarouselNext.displayName = 'CarouselNext';

type UseDotButtonType = {
  selectedIndex: number;
  scrollSnaps: number[];
  onDotButtonClick: (index: number) => void;
};

const useDotButton = (
  onButtonClick?: (emblaApi: EmblaCarouselType) => void,
): UseDotButtonType => {
  const { emblaApi } = useCarousel();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [scrollSnaps, setScrollSnaps] = React.useState<number[]>([]);

  const onDotButtonClick = React.useCallback(
    (index: number) => {
      if (!emblaApi) return;
      emblaApi.scrollTo(index);
      if (onButtonClick) onButtonClick(emblaApi);
    },
    [emblaApi, onButtonClick],
  );

  const onInit = React.useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = React.useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  React.useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on('reInit', onInit).on('reInit', onSelect).on('select', onSelect);
  }, [emblaApi, onInit, onSelect]);

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick,
  };
};

const CarouselDots = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => {
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton();

  return (
    <div
      ref={ref}
      className={cn('flex justify-center items-center gap-1', className)}
      {...props}
    >
      {scrollSnaps.map((_, index) => {
        const id = randomId();
        const isActive = selectedIndex === index;

        return (
          <Button
            key={id}
            variant={isActive ? 'background' : 'muted'}
            size='icon'
            className={cn('size-2 rounded-full', !isActive && 'bg-muted/50')}
            onClick={() => onDotButtonClick(index)}
          />
        );
      })}
    </div>
  );
});
CarouselDots.displayName = 'CarouselDots';

export {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
};
export { useCarousel, useDotButton };
