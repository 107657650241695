import type {
  BaseTreeOption,
  CartInvoice,
  CartProduct,
  FileOwnerType,
  PaymentToken,
  Product,
  ProductPackaging,
  ResponseErrors,
  SubProduct,
} from '@/types';
import {
  fixedParseFloat,
  getObjectEntries,
  PATHNAME,
  preciseRound,
} from '@/utils';

const {
  DEFAULT: {
    API_BASE_URL: DEFAULT_API_BASE_URL,
    SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR,
    LOCALE: DEFAULT_LOCALE,
  },
  QUERY_PARAM: {
    SYMBOL: {
      QUESTION_MARK: QUESTION_MARK_SYMBOL,
      ASSIGNMENT: ASSIGNMENT_SYMBOL,
      AMPERSAND: AMPERSAND_SYMBOL,
      COMMA: COMMA_SYMBOL,
    },
    CULTURE: { BASE_URL: CULTURE_BASE_URL },
  },
} = PATHNAME;

const generateRequestUrl = <P extends object = object>(
  url: string,
  params?: P,
) => {
  let queryParams = '';

  if (params) {
    queryParams = getObjectEntries(params)
      .flatMap(([key, value]) =>
        Array.isArray(value)
          ? value.map(
              v => `${String(key)}${ASSIGNMENT_SYMBOL}${encodeURIComponent(v)}`,
            )
          : `${String(key)}${ASSIGNMENT_SYMBOL}${encodeURIComponent(String(value))}`,
      )
      .join(AMPERSAND_SYMBOL);
  }

  return `${DEFAULT_API_BASE_URL}${DEFAULT_SLUG_SEPARATOR}${url}${DEFAULT_SLUG_SEPARATOR}${QUESTION_MARK_SYMBOL}${CULTURE_BASE_URL}${ASSIGNMENT_SYMBOL}${DEFAULT_LOCALE}${AMPERSAND_SYMBOL}${queryParams}`;
};

const generateFileUrl = (
  ownerType: FileOwnerType,
  id: string,
  width = 100,
  height = 100,
  quality = 80,
) =>
  generateRequestUrl(
    `file${DEFAULT_SLUG_SEPARATOR}downloadFile${DEFAULT_SLUG_SEPARATOR}${ownerType}`,
    {
      id,
      width,
      height,
      quality,
    },
  );

const generateThumbnailUrl = (
  ownerType: FileOwnerType,
  id: string,
  width = 100,
  height = 100,
) =>
  generateRequestUrl(
    `file${DEFAULT_SLUG_SEPARATOR}downloadThumbnail${DEFAULT_SLUG_SEPARATOR}${ownerType}`,
    {
      id,
      width,
      height,
    },
  );

const generateInvoiceFileUrl = (purchaseId: string) =>
  generateRequestUrl(`file${DEFAULT_SLUG_SEPARATOR}downloadInvoiceFile`, {
    purchaseId,
  });

const generatePaymentUrl = (params: PaymentToken) =>
  generateRequestUrl(`cart${DEFAULT_SLUG_SEPARATOR}submitPayment`, params);

const generateResponseError = ({ errors }: ResponseErrors) => {
  let message = '';

  errors.forEach(error => {
    if ('field' in error) {
      message = error.messages.join(`${COMMA_SYMBOL} `);
    }
    if ('code' in error) {
      message = error.message;
    }
  });

  return message;
};

const generatePurchaseStock = ({
  purchaseMinStock,
  purchaseMaxStock,
  activateStock,
  stock,
}: SubProduct) => {
  const stockSize = activateStock && stock !== undefined ? stock : Infinity;
  const minStockSize = purchaseMinStock || 1;
  const maxStockSize = purchaseMaxStock || stockSize;

  return {
    stockSize,
    minStockSize,
    maxStockSize,
  };
};

const generateCartInvoice = (products: CartProduct[]): Partial<CartInvoice> => {
  const calculateInvoiceValue = (
    { packagingInfo: { amount }, size }: CartProduct,
    value: number,
  ) => {
    const roundedValue = preciseRound(size, amount);
    const result = fixedParseFloat(roundedValue * value);

    return Math.abs(result);
  };

  const originalPrice = products.reduce((previousValue, currentValue) => {
    const { subProduct } = currentValue;
    return (
      previousValue +
      calculateInvoiceValue(currentValue, subProduct.originalPrice)
    );
  }, 0);
  const discount = products.reduce((previousValue, currentValue) => {
    const { subProduct } = currentValue;
    return (
      previousValue +
      calculateInvoiceValue(
        currentValue,
        subProduct.originalPrice -
          (subProduct.price ?? subProduct.originalPrice),
      )
    );
  }, 0);
  const totalPrice = products.reduce((previousValue, currentValue) => {
    const { subProduct } = currentValue;
    return (
      previousValue +
      calculateInvoiceValue(
        currentValue,
        subProduct.price ?? subProduct.originalPrice,
      )
    );
  }, 0);

  return {
    originalPrice,
    discount,
    totalPrice,
  };
};

const generateCartProduct = (
  product: Partial<
    StrictOmit<Product, 'packagingInfo'> &
      Pick<CartProduct, 'subProduct' | 'packagingInfo'>
  >,
  size = 1,
): CartProduct => {
  const {
    id = '',
    slug = '',
    name = '',
    status = 'Submitted',
    isAvailable = true,
    publishTime,
    brand,
    mainCategory,
    pictures = [],
    subProduct,
    packagingInfo,
  } = product;

  const mainSubProduct = (subProduct ?? {}) as SubProduct;
  const mainPackagingInfo = (packagingInfo ?? {}) as ProductPackaging;
  const { id: bannerId = '' } = pictures.at(0) ?? {};
  const { originalPrice = 0, price = originalPrice } = mainSubProduct;

  return {
    product: {
      id,
      slug,
      name,
      status,
      isAvailable,
      publishTime,
      brand,
      mainCategory,
      bannerId,
      mainSubProduct,
    },
    subProduct: mainSubProduct,
    packagingInfo: mainPackagingInfo,
    size,
    price,
  };
};

const findElementByKey = <T, K extends keyof T>(
  elements: T[],
  key: K,
  value: T[K],
) => elements.find(element => element[key] === value);

const findTreeElementByKey = <T extends BaseTreeOption<T>, K extends keyof T>(
  elements: T[],
  key: K,
  value: T[K],
): T | undefined => {
  for (const element of elements) {
    const { children } = element;
    if (element[key] === value) return element;

    if (children) {
      const foundedElement = findTreeElementByKey(children, key, value);
      if (foundedElement) return foundedElement;
    }
  }
  return undefined;
};

const decodeUrlSafeBase64 = (url: string) => {
  try {
    const base64 = url
      .replace(/-/g, '+')
      .replace(/_/g, '/')
      .replace(/[^A-Za-z0-9+/]/g, '');

    const pad = base64.length % 4;
    const padded = pad ? base64 + '='.repeat(4 - pad) : base64;

    const decoded = atob(padded);
    const bytes = new Uint8Array(decoded.length);

    for (let i = 0; i < decoded.length; i++) {
      bytes[i] = decoded.charCodeAt(i);
    }

    return new TextDecoder('utf-8').decode(bytes);
  } catch (error) {
    if (error instanceof Error) {
      return `Error: ${error.message}`;
    }
  }
};

export {
  decodeUrlSafeBase64,
  findElementByKey,
  findTreeElementByKey,
  generateCartInvoice,
  generateCartProduct,
  generateFileUrl,
  generateInvoiceFileUrl,
  generatePaymentUrl,
  generatePurchaseStock,
  generateRequestUrl,
  generateResponseError,
  generateThumbnailUrl,
};
