'use client';

import * as React from 'react';

import Image from 'next/image';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { useMediaQuery } from 'usehooks-ts';
import { z } from 'zod';

import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  EmailInput,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  LocaleLink,
  Separator,
  SpinnerIcon,
  StarRating,
  Textarea,
  TypographyBalancer,
  TypographyParagraph,
} from '@/components';
import { useProductValue } from '@/context';
import { sendCommentForProduct, sendUserCommentForProduct } from '@/services';
import { BREAKPOINT, FormSchema, generateFileUrl } from '@/utils';

const {
  LARGER_THAN: { SM: LARGER_THAN_SM },
} = BREAKPOINT;

type CommentFormContextProps = Pick<
  React.ComponentProps<typeof Dialog>,
  'open' | 'onOpenChange'
> &
  Partial<Record<'isUser', boolean>>;

const CommentFormContext = React.createContext<CommentFormContextProps>({});

const useCommentForm = () => {
  const context = React.useContext(CommentFormContext);
  if (!context) {
    throw new Error('useCommentForm must be used within a <CommentForm />');
  }
  return context;
};

type CommentFormProps = React.ComponentProps<typeof Dialog> &
  CommentFormContextProps;

const CommentForm = ({
  isUser = false,
  open,
  onOpenChange,
  ...props
}: CommentFormProps) => {
  const matches = useMediaQuery(LARGER_THAN_SM);

  return (
    <CommentFormContext.Provider value={{ isUser, open, onOpenChange }}>
      <Dialog open={open} onOpenChange={onOpenChange} {...props}>
        <DialogTrigger asChild>
          <Button
            variant='outline'
            className='border-primary text-primary hover:bg-primary hover:text-white'
          >
            ثبت دیدگاه
          </Button>
        </DialogTrigger>
        <DialogContent
          variant={matches ? 'default' : 'full'}
          className='flex max-h-screen max-w-xl flex-col gap-0 p-0 max-sm:rounded-none'
        >
          <DialogHeader className='py-4'>
            <DialogTitle>ثبت دیدگاه</DialogTitle>
          </DialogHeader>
          <Separator />

          {isUser ? <CommentsUserForm /> : <CommentsNonUserForm />}
        </DialogContent>
      </Dialog>
    </CommentFormContext.Provider>
  );
};

const UserFormSchema = FormSchema.pick({
  comment: true,
  rate: true,
});

const CommentsUserForm = () => {
  const { onOpenChange } = useCommentForm();
  const { id = '', name = '', pictures = [] } = useProductValue();

  const [rating, setRating] = React.useState(0);

  const form = useForm<z.infer<typeof UserFormSchema>>({
    resolver: zodResolver(UserFormSchema),
    defaultValues: {
      comment: '',
      rate: 0,
    },
  });

  const onSubmit = async ({ comment }: z.infer<typeof UserFormSchema>) => {
    try {
      await sendUserCommentForProduct({
        productId: id,
        content: comment,
        rate: rating,
      });
      onOpenChange?.(false);
      toast.success(
        'دیدگاه شما پس از تایید توسط کارشناسان ما قابل مشاهده خواهد بود.',
      );
      form.reset();
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const {
    formState: { isSubmitting },
  } = form;
  const { width, height } = {
    width: 55,
    height: 55,
  };
  const bannerId = pictures.at(0)?.id;

  return (
    <Form {...form}>
      <form
        className='flex h-full flex-col justify-between overflow-auto'
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className='space-y-6 p-6'>
          <div>
            <div className='flex items-center justify-between rounded-md bg-muted p-4'>
              <div className='flex flex-col gap-2'>
                <TypographyParagraph className='text-sm text-secondary'>
                  ثبت دیدگاه در مورد
                </TypographyParagraph>
                <TypographyParagraph className='font-bold max-md:text-sm'>
                  {name}
                </TypographyParagraph>
              </div>

              {bannerId ? (
                <div className='rounded-md bg-white p-2'>
                  <Image
                    src={generateFileUrl('Product', bannerId, width, height)}
                    width={width}
                    height={height}
                    alt={name}
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div className='flex flex-col gap-6'>
            <div className='flex items-center justify-between gap-4 max-sm:flex-col'>
              <div className='flex flex-col gap-2 text-center sm:text-start'>
                <TypographyParagraph className='font-bold'>
                  به محصول موردنظر امتیاز دهید
                </TypographyParagraph>
                <TypographyParagraph className='max-md:text-sm'>
                  تعداد ستاره‌های بیشتر به معنی محبوبیت بیشتر است.
                </TypographyParagraph>
              </div>
              <StarRating
                value={rating}
                onValueChange={value => setRating(value)}
              />
            </div>

            <FormField
              control={form.control}
              name='comment'
              render={({ field }) => (
                <FormItem className='flex flex-col gap-2'>
                  <FormLabel>
                    دیدگاه شما <sup>*</sup>
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      rows={6}
                      placeholder='لطفا دیدگاه خود را اینجا برای ما بنویسید...'
                      className='rounded-md p-4 max-md:text-sm'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <TypographyBalancer className='space-x-0.5 text-sm'>
              <span>ثبت دیدگاه به معنی موافقت با</span>
              <Button asChild variant='link' className='p-1'>
                <LocaleLink href='#'>قوانین در کاشی لند</LocaleLink>
              </Button>
              <span>است.</span>
            </TypographyBalancer>
          </div>
        </div>

        <div className='container flex flex-row-reverse border-t p-4'>
          <Button
            type='submit'
            className='min-w-[240px] max-sm:w-full'
            disabled={isSubmitting}
          >
            {isSubmitting ? <SpinnerIcon className='animate-spin' /> : null}
            <span>ثبت دیدگاه</span>
          </Button>
        </div>
      </form>
    </Form>
  );
};

const NonUserFormSchema = FormSchema.pick({
  displayName: true,
  email: true,
  comment: true,
  rate: true,
});

const CommentsNonUserForm = () => {
  const { onOpenChange } = useCommentForm();
  const { id = '', name = '', pictures = [] } = useProductValue();

  const [rating, setRating] = React.useState(0);

  const form = useForm<z.infer<typeof NonUserFormSchema>>({
    resolver: zodResolver(NonUserFormSchema),
    defaultValues: {
      displayName: '',
      comment: '',
      email: '',
      rate: 0,
    },
  });

  const onSubmit = async ({
    comment,
    email,
    displayName,
  }: z.infer<typeof NonUserFormSchema>) => {
    try {
      await sendCommentForProduct({
        productId: id,
        content: comment,
        rate: rating,
        displayName,
        email,
      });
      onOpenChange?.(false);
      toast.success(
        'دیدگاه شما پس از تایید توسط کارشناسان ما قابل مشاهده خواهد بود.',
      );
      form.reset();
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const {
    formState: { isSubmitting },
  } = form;
  const { width, height } = {
    width: 55,
    height: 55,
  };
  const bannerId = pictures.at(0)?.id;

  return (
    <Form {...form}>
      <form
        className='flex h-full flex-col justify-between overflow-auto'
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className='space-y-6 p-6'>
          <div>
            <div className='flex items-center justify-between rounded-md bg-muted p-4'>
              <div className='flex flex-col gap-2'>
                <TypographyParagraph className='text-sm text-secondary'>
                  ثبت دیدگاه در مورد
                </TypographyParagraph>
                <TypographyParagraph className='font-bold max-md:text-sm'>
                  {name}
                </TypographyParagraph>
              </div>

              {bannerId ? (
                <div className='rounded-md bg-white p-2'>
                  <Image
                    src={generateFileUrl('Product', bannerId, width, height)}
                    width={width}
                    height={height}
                    alt={name}
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div className='flex flex-col gap-6'>
            <div className='flex items-center justify-between gap-4 max-sm:flex-col'>
              <div className='flex flex-col gap-2 text-center sm:text-start'>
                <TypographyParagraph className='font-bold'>
                  به محصول موردنظر امتیاز دهید
                </TypographyParagraph>
                <TypographyParagraph className='max-md:text-sm'>
                  تعداد ستاره‌های بیشتر به معنی محبوبیت بیشتر است.
                </TypographyParagraph>
              </div>
              <StarRating
                value={rating}
                onValueChange={value => setRating(value)}
              />
            </div>

            <FormField
              control={form.control}
              name='displayName'
              render={({ field }) => (
                <FormItem className='flex w-full flex-col gap-2'>
                  <FormLabel>
                    نام و نام خانوادگی <sup>*</sup>
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder='لطفا نام و نام خانوادگی خود را اینجا وارد کنید'
                      className='w-full rounded-md border border-border p-4 text-sm'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem className='flex w-full flex-col gap-2'>
                  <FormLabel>
                    ایمیل <sup>*</sup>
                  </FormLabel>
                  <FormControl>
                    <EmailInput
                      placeholder='لطفا ایمیل خود را اینجا وارد کنید'
                      className='w-full rounded-md border border-border p-4 text-sm'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name='comment'
              render={({ field }) => (
                <FormItem className='flex flex-col gap-2'>
                  <FormLabel>
                    دیدگاه شما <sup>*</sup>
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      rows={6}
                      placeholder='لطفا دیدگاه خود را اینجا برای ما بنویسید...'
                      className='rounded-md p-4 max-md:text-sm'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <TypographyBalancer className='space-x-0.5 text-sm'>
              <span>ثبت دیدگاه به معنی موافقت با</span>
              <Button asChild variant='link' className='p-1'>
                <LocaleLink href='#'>قوانین در کاشی لند</LocaleLink>
              </Button>
              <span>است.</span>
            </TypographyBalancer>
          </div>
        </div>

        <div className='container flex flex-row-reverse border-t p-4'>
          <Button
            type='submit'
            className='min-w-[240px] max-sm:w-full'
            disabled={isSubmitting}
          >
            {isSubmitting ? <SpinnerIcon className='animate-spin' /> : null}
            <span>ثبت دیدگاه</span>
          </Button>
        </div>
      </form>
    </Form>
  );
};

export { CommentForm };
