import { Fetch } from '@/lib';
import type { Category } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    CATEGORY: { BASE: BASE_CATEGORY },
  },
} = TAG;

type RequestResponse = Category;

type RequestParameter = {
  slug: string;
};

const getCategoryBySlug = async ({ slug }: RequestParameter) => {
  try {
    return await Fetch<RequestResponse, RequestParameter>({
      url: `category/getCategoryBySlug/${slug}`,
      next: { tags: [BASE_CATEGORY] },
    });
  } catch {
    return null;
  }
};

export { getCategoryBySlug };
