'use client';

import * as React from 'react';

import { toast } from 'sonner';
import { useBoolean, useMediaQuery } from 'usehooks-ts';

import {
  AddressInfoForm,
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Badge,
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DotsThreeVerticalIcon,
  MapPinAreaIcon,
  PencilSimpleIcon,
  PhoneCallIcon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  Separator,
  Skeleton,
  TrashSimpleIcon,
} from '@/components';
import { deleteRecipientAddressInfo } from '@/services';
import type { RecipientAddressInfo } from '@/types';
import { BREAKPOINT, cn } from '@/utils';

const {
  LARGER_THAN: { LG: LARGER_THAN_LG },
} = BREAKPOINT;

type AddressInfoCardContextProps = RecipientAddressInfo;

const AddressInfoCardContext = React.createContext<AddressInfoCardContextProps>(
  {} as AddressInfoCardContextProps,
);

const useAddressInfoCard = () => {
  const context = React.useContext(AddressInfoCardContext);

  if (!context) {
    throw new Error(
      'useAddressInfoCard must be used within a <AddressInfoCard />',
    );
  }

  return context;
};

type AddressInfoCardProps = React.ComponentPropsWithoutRef<typeof Card> &
  AddressInfoCardContextProps;

const AddressInfoCard = React.forwardRef<
  React.ElementRef<typeof Card>,
  AddressInfoCardProps
>(
  (
    {
      id,
      title,
      firstName,
      lastName,
      city,
      address,
      telNumber,
      phoneNumber,
      className,
      children,
      ...props
    },
    ref,
  ) => (
    <AddressInfoCardContext.Provider
      value={{
        id,
        title,
        firstName,
        lastName,
        city,
        address,
        telNumber,
        phoneNumber,
      }}
    >
      <Card ref={ref} className={cn('p-6', className)} {...props}>
        {children}
      </Card>
    </AddressInfoCardContext.Provider>
  ),
);
AddressInfoCard.displayName = 'AddressInfoCard';

const AddressInfoCardHeader = React.forwardRef<
  React.ElementRef<typeof CardHeader>,
  React.ComponentPropsWithoutRef<typeof CardHeader>
>(({ className, children, ...props }, ref) => {
  const { firstName, lastName, title } = useAddressInfoCard();

  return (
    <CardHeader
      ref={ref}
      className={cn('flex-row items-center justify-between', className)}
      {...props}
    >
      <CardTitle className='text-base'>
        <span>{`${firstName} ${lastName}`}</span>

        <Badge className='ms-1'>{title}</Badge>
      </CardTitle>

      {children}
    </CardHeader>
  );
});
AddressInfoCardHeader.displayName = 'AddressInfoCardHeader';

type AddressInfoCardHeaderActionsProps = React.ComponentPropsWithoutRef<
  typeof Popover
> &
  Partial<Record<'onAction', () => void>>;

const AddressInfoCardHeaderActions = ({
  onAction,
  ...props
}: AddressInfoCardHeaderActionsProps) => {
  const { value: dialogOpen, setValue: setDialogOpen } = useBoolean();
  const addressInfo = useAddressInfoCard();
  const matches = useMediaQuery(LARGER_THAN_LG);

  const { id, title } = addressInfo;

  return (
    <Popover {...props}>
      <PopoverTrigger asChild>
        <Button variant='outline' size='icon'>
          <DotsThreeVerticalIcon />
        </Button>
      </PopoverTrigger>

      <PopoverContent
        side='right'
        align='start'
        className='flex w-max flex-col gap-4'
      >
        <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
          <DialogTrigger className='inline-flex items-center gap-2'>
            <PencilSimpleIcon className='size-4' />
            <span className='text-sm font-bold'>ویرایش آدرس</span>
          </DialogTrigger>

          <DialogContent
            variant={matches ? 'default' : 'full'}
            className='p-0 lg:max-w-2xl'
          >
            <DialogHeader className='pt-4'>
              <DialogTitle>{`ویرایش آدرس ${title}`}</DialogTitle>
            </DialogHeader>

            <Separator />

            <ScrollArea className='w-full'>
              <AddressInfoForm
                type='update'
                actionTitle='ذخیره تغییرات'
                onAction={() => {
                  setDialogOpen(false);
                  onAction?.();
                }}
                {...addressInfo}
              />
            </ScrollArea>
          </DialogContent>
        </Dialog>

        <AlertDialog>
          <AlertDialogTrigger className='inline-flex items-center gap-2 text-danger'>
            <TrashSimpleIcon className='size-4' />
            <span className='text-sm font-bold'>حذف</span>
          </AlertDialogTrigger>

          <AlertDialogContent className='max-sm:max-w-[calc(100vw-2rem)]'>
            <AlertDialogHeader>
              <AlertDialogTitle>حذف آدرس</AlertDialogTitle>
            </AlertDialogHeader>

            <Separator />

            <AlertDialogFooter className='flex-col gap-8'>
              <div className='space-y-1'>
                <AlertDialogTitle>
                  آیا از حذف آدرس خود اطمینان دارید؟
                </AlertDialogTitle>

                <AlertDialogDescription>
                  پس از حذف آدرس، امکان بازگشت وجود نخواهد داشت
                </AlertDialogDescription>
              </div>

              <div className='flex gap-4 max-sm:flex-col'>
                <AlertDialogCancel className='basis-full'>
                  لغو
                </AlertDialogCancel>

                <AlertDialogAction
                  className='basis-full'
                  onClick={() => {
                    deleteRecipientAddressInfo({ id })
                      .then(() => {
                        toast.success('آدرس شما با موفقیت حذف شد.');
                      })
                      .catch(({ message }) => {
                        toast.error(message);
                      })
                      .finally(() => {
                        onAction?.();
                      });
                  }}
                >
                  حذف آدرس
                </AlertDialogAction>
              </div>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </PopoverContent>
    </Popover>
  );
};

const AddressInfoCardContent = React.forwardRef<
  React.ElementRef<typeof CardContent>,
  React.ComponentPropsWithoutRef<typeof CardContent>
>(({ className, ...props }, ref) => {
  const { address } = useAddressInfoCard();

  return (
    <CardContent
      ref={ref}
      className={cn('line-clamp-2 text-start text-sm font-bold', className)}
      {...props}
    >
      {address.content}
    </CardContent>
  );
});
AddressInfoCardContent.displayName = 'AddressInfoCardContent';

const AddressInfoCardFooter = React.forwardRef<
  React.ElementRef<typeof CardFooter>,
  React.ComponentPropsWithoutRef<typeof CardFooter>
>(({ className, ...props }, ref) => {
  const { address, phoneNumber } = useAddressInfoCard();

  return (
    <CardFooter
      ref={ref}
      className={cn('flex-col items-stretch gap-4', className)}
      {...props}
    >
      <div className='flex items-end gap-2'>
        <MapPinAreaIcon />
        <CardDescription className='text-sm'>
          {address.postalCode}
        </CardDescription>
      </div>

      <div className='flex items-end gap-2'>
        <PhoneCallIcon />
        <CardDescription className='text-sm'>{phoneNumber}</CardDescription>
      </div>
    </CardFooter>
  );
});
AddressInfoCardFooter.displayName = 'AddressInfoCardFooter';

type AddressInfoCardBoxProps = Partial<Record<'onAction', () => void>>;

const AddressInfoCardBox = ({ onAction }: AddressInfoCardBoxProps) => {
  const { value: dialogOpen, setValue: setDialogOpen } = useBoolean();
  const matches = useMediaQuery(LARGER_THAN_LG);

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <Button variant='outline' size='lg' className='size-full min-h-52'>
          <PencilSimpleIcon className='size-4' />
          <span className='text-sm font-bold'>افزودن آدرس جدید</span>
        </Button>
      </DialogTrigger>

      <DialogContent
        variant={matches ? 'default' : 'full'}
        className='gap-0 p-0 lg:max-w-2xl'
      >
        <DialogHeader className='pt-4'>
          <DialogTitle>افزودن آدرس</DialogTitle>
        </DialogHeader>

        <Separator className='mt-4' />

        <ScrollArea className='w-full'>
          <AddressInfoForm
            onAction={() => {
              setDialogOpen(false);
              onAction?.();
            }}
          />
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

const AddressInfoCardPlaceholder = React.forwardRef<
  React.ElementRef<typeof Card>,
  React.ComponentPropsWithoutRef<typeof Card>
>(({ className, ...props }, ref) => (
  <Card ref={ref} className={cn('p-6', className)} {...props} />
));
AddressInfoCardPlaceholder.displayName = 'AddressInfoCardPlaceholder';

const AddressInfoCardHeaderPlaceholder = React.forwardRef<
  React.ElementRef<typeof CardHeader>,
  React.ComponentPropsWithoutRef<typeof CardHeader>
>(({ className, ...props }, ref) => (
  <CardHeader
    ref={ref}
    className={cn('flex-row justify-between', className)}
    {...props}
  >
    <Skeleton className='h-3 basis-1/2' />
    <Skeleton className='h-3 basis-1/6' />
  </CardHeader>
));
AddressInfoCardHeaderPlaceholder.displayName =
  'AddressInfoCardHeaderPlaceholder';

const AddressInfoCardContentPlaceholder = React.forwardRef<
  React.ElementRef<typeof CardContent>,
  React.ComponentPropsWithoutRef<typeof CardContent>
>(({ className, ...props }, ref) => (
  <CardContent ref={ref} className={cn('gap-2', className)} {...props}>
    <Skeleton className='h-2 w-full' />
    <Skeleton className='h-2 w-2/3' />
  </CardContent>
));
AddressInfoCardContentPlaceholder.displayName =
  'AddressInfoCardContentPlaceholder';

const AddressInfoCardFooterPlaceholder = React.forwardRef<
  React.ElementRef<typeof CardFooter>,
  React.ComponentPropsWithoutRef<typeof CardFooter>
>(({ className, ...props }, ref) => (
  <CardFooter
    ref={ref}
    className={cn('flex-col items-stretch gap-4', className)}
    {...props}
  >
    <Skeleton className='h-3 w-1/3' />
    <Skeleton className='h-3 w-1/3' />
  </CardFooter>
));
AddressInfoCardFooterPlaceholder.displayName =
  'AddressInfoCardFooterPlaceholder';

export {
  AddressInfoCard,
  AddressInfoCardBox,
  AddressInfoCardContent,
  AddressInfoCardContentPlaceholder,
  AddressInfoCardFooter,
  AddressInfoCardFooterPlaceholder,
  AddressInfoCardHeader,
  AddressInfoCardHeaderActions,
  AddressInfoCardHeaderPlaceholder,
  AddressInfoCardPlaceholder,
};
export { useAddressInfoCard };
