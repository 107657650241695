'use client';

import * as React from 'react';

import {
  Button,
  Label,
  RadioGroup,
  RadioGroupItem,
  Slot,
  SortAscendingIcon,
  TypographySmall,
} from '@/components';
import type { Orientation, ProductSortType } from '@/types';
import { cn, localizeProductSortType, PATHNAME, randomId } from '@/utils';

const {
  QUERY_PARAM: {
    DEFAULT: { SORT_TYPE: DEFAULT_SORT_TYPE },
  },
} = PATHNAME;

type SortListProps = React.ComponentPropsWithoutRef<'div'> &
  Record<'value', ProductSortType> &
  Record<'onValueChange', (value: ProductSortType) => void> &
  Partial<
    Record<'asChild', boolean> &
      Record<'orientation', Orientation> &
      Record<'sortTypes', ProductSortType[]>
  >;

const SortList = React.forwardRef<React.ElementRef<'div'>, SortListProps>(
  (
    {
      value = DEFAULT_SORT_TYPE,
      asChild = false,
      orientation = 'horizontal',
      sortTypes = [
        'Newest',
        'BestSelling',
        'MostPopular',
        'Cheapest',
        'MostExpensive',
      ],
      onValueChange,
      ...props
    }: SortListProps,
    ref,
  ) => {
    const Comp = asChild ? Slot : 'div';
    const isVertical = orientation === 'vertical';

    const items = sortTypes.map(type => {
      const id = randomId();
      const { label } = localizeProductSortType(type);
      const isActive = value === type;

      return isVertical ? (
        <div key={id} className='flex items-center gap-2'>
          <RadioGroupItem id={id} value={type} checked={isActive} />
          <Label htmlFor={id} className='font-medium'>
            {label}
          </Label>
        </div>
      ) : (
        <li key={id}>
          <Button
            variant='link'
            className={cn(
              'underline-offset-[12px] px-2',
              isActive && 'underline',
              !isActive && 'text-slate-400',
            )}
            onClick={() => onValueChange(type)}
          >
            {label}
          </Button>
        </li>
      );
    });

    return (
      <Comp ref={ref} {...props}>
        {isVertical ? (
          <RadioGroup className='space-y-4' onValueChange={onValueChange}>
            {items}
          </RadioGroup>
        ) : (
          <div className='flex items-center gap-2'>
            <SortAscendingIcon />
            <TypographySmall>مرتب سازی بر اساس:</TypographySmall>
            <ul className='flex'>{items}</ul>
          </div>
        )}
      </Comp>
    );
  },
);
SortList.displayName = 'SortList';

export { SortList };
