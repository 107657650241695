import * as React from 'react';

import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  TypographySmall,
} from '@/components';
import { cn } from '@/utils';

type ProductActionProps = React.ComponentPropsWithoutRef<typeof Button> &
  Record<'title', React.ReactNode>;

const ProductAction = React.forwardRef<
  React.ElementRef<typeof Button>,
  ProductActionProps
>(
  (
    {
      title,
      variant = 'background',
      size = 'sm',
      className,
      children,
      ...props
    },
    ref,
  ) => (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            ref={ref}
            variant={variant}
            size={size}
            className={cn('px-2 text-secondary border', className)}
            {...props}
          >
            {children}
          </Button>
        </TooltipTrigger>
        <TooltipContent className='bg-muted text-muted-foreground'>
          <TypographySmall>{title}</TypographySmall>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ),
);
ProductAction.displayName = 'ProductAction';

export { ProductAction };
