'use client';

import * as React from 'react';

import { cva } from 'class-variance-authority';
import type { VariantProps } from 'class-variance-authority';

import { Slot } from '@/components';
import { cn } from '@/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center gap-2 whitespace-nowrap font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/90',
        muted: 'bg-muted text-muted-foreground hover:bg-muted/90',
        danger: 'bg-danger text-danger-foreground hover:bg-danger/90',
        warning: 'bg-warning text-warning-foreground hover:bg-warning/90',
        success: 'bg-success text-success-foreground hover:bg-success/90',
        background: 'bg-background text-foreground hover:bg-muted/90',
        foreground: 'bg-foreground text-background hover:bg-foreground/90',
        outline:
          'border border-border bg-background text-foreground hover:bg-muted/90',
        ghost: 'hover:bg-muted/90 hover:text-muted-foreground',
        link: 'text-primary',
      },
      size: {
        icon: 'size-10 rounded-sm text-sm',
        default: 'h-10 rounded-md px-4 py-2 text-sm',
        sm: 'h-9 rounded-sm px-3 text-xs',
        lg: 'h-11 rounded-lg px-8 text-sm',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

type ButtonProps = React.ComponentPropsWithoutRef<'button'> &
  VariantProps<typeof buttonVariants> &
  Partial<Record<'asChild', boolean>>;

const Button = React.forwardRef<React.ElementRef<'button'>, ButtonProps>(
  (
    { asChild = false, type = 'button', variant, size, className, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        ref={ref}
        type={type}
        className={cn(buttonVariants({ variant, size, className }))}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button };
export { buttonVariants };
