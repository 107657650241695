import { updateCheckoutInfo } from '@/services';

type State = Record<'step', 2 | 3> &
  Partial<
    Parameters<typeof updateCheckoutInfo>[number] &
      Record<'paymentGatewayId', string>
  >;
const initialState: State = {
  step: 2,
};

type Action =
  | {
      type: 'UPDATE_CHECKOUT_STEP';
      payload: Pick<typeof initialState, 'step'>;
    }
  | {
      type: 'UPDATE_CHECKOUT_RECIPIENT_ADDRESS_ID';
      payload: Pick<typeof initialState, 'recipientAddressId'>;
    }
  | {
      type: 'UPDATE_CHECKOUT_PAYMENT_GATEWAY_ID';
      payload: Pick<typeof initialState, 'paymentGatewayId'>;
    }
  | {
      type: 'UPDATE_CHECKOUT_DESCRIPTION';
      payload: Pick<typeof initialState, 'description'>;
    }
  | {
      type: 'UPDATE_CHECKOUT_PAPER_INVOICE';
      payload: Pick<typeof initialState, 'paperInvoice'>;
    }
  | undefined;
const initialAction: Action = undefined;

export { initialAction, initialState };
