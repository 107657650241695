import { Fetch } from '@/lib';
import type { Purchase, PurchaseSortType, PurchaseStatus } from '@/types';

type RequestResponse = {
  total: number;
  data: Purchase[];
};

type RequestParameter = {
  sortType: PurchaseSortType;
  status?: PurchaseStatus;
  searchKey: string;
  pageSize: number;
  pageNumber: number;
};

const searchPurchases = async ({
  pageNumber,
  pageSize,
  searchKey,
  sortType,
  status,
}: RequestParameter) => {
  const params: Record<string, unknown> = {
    pageNumber,
    pageSize,
    searchKey,
    sortType,
  };

  if (status) {
    params['status'] = status;
  }

  return await Fetch<RequestResponse, typeof params>({
    url: 'user/searchPurchases',
    params,
  });
};

export { searchPurchases };
