import { Fetch } from '@/lib';
import type { DiscountCode } from '@/types';

type RequestResponse = DiscountCode;

type RequestParameter = {
  cartId: string;
};

type RequestBody = {
  discountCode: string;
};

const applyDiscountCode = async (params: RequestParameter, body: RequestBody) =>
  await Fetch<RequestResponse, RequestParameter, RequestBody>({
    url: 'cart/applyDiscountCode',
    method: 'PATCH',
    params,
    body,
  });

export { applyDiscountCode };
