import { Fetch } from '@/lib';

type RequestResponse = {};

type RequestParameter = {};

type RequestBody = {
  productId: string;
};

const toggleFavoriteProduct = async (body: RequestBody) =>
  await Fetch<RequestResponse, RequestParameter, RequestBody>({
    url: 'favorite/toggleFavoriteProduct',
    method: 'POST',
    body,
  });

export { toggleFavoriteProduct };
