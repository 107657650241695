import { Fetch } from '@/lib';
import type { SiteMap } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    SEO: {
      SITE_MAP: { PRODUCTS: PRODUCTS_SEO_SITE_MAP },
    },
  },
} = TAG;

type RequestResponse = {
  items: SiteMap[];
};

const getSiteMapProducts = async () =>
  await Fetch<RequestResponse>({
    url: 'seo/getSiteMapProducts',
    next: {
      tags: [PRODUCTS_SEO_SITE_MAP],
    },
  });

export { getSiteMapProducts };
