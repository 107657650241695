import { Fetch } from '@/lib';
import type { CartInvoice } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    CART: {
      BASE: BASE_CART,
      INVOICE_INFO: { BASE: BASE_CART_INVOICE_INFO },
    },
  },
} = TAG;

type RequestResponse = CartInvoice;

const getCartInvoiceInfo = async () =>
  await Fetch<RequestResponse>({
    url: 'cart/getCartInvoiceInfo',
    next: {
      tags: [BASE_CART, BASE_CART_INVOICE_INFO],
    },
  });

export { getCartInvoiceInfo };
