'use client';

import * as React from 'react';

import {
  Button,
  LocaleLink,
  TypographyH1,
  TypographyMuted,
  TypographyParagraph,
} from '@/components';
import { cn, PATHNAME } from '@/utils';

const {
  LOCAL: {
    HOME: { BASE_URL: HOME_BASE_URL },
  },
} = PATHNAME;

type ErrorProps = React.ComponentPropsWithoutRef<'div'> &
  Partial<
    Record<
      'heading' | 'paragraph' | 'actionTitle' | 'resetTitle',
      React.ReactNode
    > &
      Record<'resetAction', () => void> &
      Record<
        'actionHref',
        React.ComponentPropsWithoutRef<typeof LocaleLink>['href']
      >
  >;

const Error = React.forwardRef<React.ElementRef<'div'>, ErrorProps>(
  (
    {
      heading = 'خطای سرور داخلی',
      paragraph = `اوه، مشکلی از سوی ما پیش آمد. لطفاً بعداً دوباره امتحان کنید یا اگر مشکل همچنان ادامه داشت با پشتیبانی تماس بگیرید.`,
      actionTitle = 'صفحه اصلی',
      actionHref = HOME_BASE_URL,
      resetTitle = 'تلاش دوباره',
      resetAction,
      className,
      ...props
    },
    ref,
  ) => (
    <div
      ref={ref}
      className={cn('container grid place-items-center gap-6 py-40', className)}
      {...props}
    >
      <div className='space-y-1 text-center'>
        <TypographyH1 className='text-danger'>{heading}</TypographyH1>
        <TypographyParagraph>
          <TypographyMuted>{paragraph}</TypographyMuted>
        </TypographyParagraph>
      </div>
      <div className='flex items-center justify-center gap-2'>
        <Button>
          <LocaleLink href={actionHref}>{actionTitle}</LocaleLink>
        </Button>
        <Button variant='outline' onClick={resetAction}>
          {resetTitle}
        </Button>
      </div>
    </div>
  ),
);
Error.displayName = 'Error';

export { Error };
