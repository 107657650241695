'use client';

import * as React from 'react';

import { cn } from '@/utils';

const Textarea = React.forwardRef<
  React.ElementRef<'textarea'>,
  React.ComponentPropsWithoutRef<'textarea'>
>(({ rows = 1, className, ...props }, ref) => (
  <textarea
    ref={ref}
    rows={rows}
    className={cn(
      'flex w-full resize-none overflow-hidden rounded-md border border-input bg-background p-3 text-sm ring-offset-background placeholder:text-xs placeholder:font-medium placeholder:text-secondary/60 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
      className,
    )}
    {...props}
  />
));
Textarea.displayName = 'Textarea';

export { Textarea };
