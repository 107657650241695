'use client';

import * as React from 'react';

import Link from 'next/link';

const LocaleLink = React.forwardRef<
  React.ElementRef<typeof Link>,
  React.ComponentPropsWithoutRef<typeof Link>
>(({ prefetch = false, ...props }, ref) => (
  <Link ref={ref} prefetch={prefetch} {...props} />
));
LocaleLink.displayName = 'LocaleLink';

const ExternalLink = React.forwardRef<
  React.ElementRef<'a'>,
  React.ComponentPropsWithoutRef<'a'>
>(
  (
    { target = '_blank', rel = 'noopener noreferrer nofollow', ...props },
    ref,
  ) => <a ref={ref} target={target} rel={rel} {...props} />,
);
ExternalLink.displayName = 'ExternalLink';

const DownloadLink = React.forwardRef<
  React.ElementRef<'a'>,
  React.ComponentPropsWithoutRef<'a'>
>(({ download = true, ...props }, ref) => (
  <a ref={ref} download={download} {...props} />
));
DownloadLink.displayName = 'DownloadLink';

const TelLink = React.forwardRef<
  React.ElementRef<'a'>,
  React.ComponentPropsWithoutRef<'a'>
>(({ href, ...props }, ref) => <a ref={ref} href={`tel:${href}`} {...props} />);
TelLink.displayName = 'TelLink';

const MailLink = React.forwardRef<
  React.ElementRef<'a'>,
  React.ComponentPropsWithoutRef<'a'>
>(({ href, ...props }, ref) => (
  <a ref={ref} href={`mailto:${href}`} {...props} />
));
MailLink.displayName = 'MailLink';

export { DownloadLink, ExternalLink, LocaleLink, MailLink, TelLink };
