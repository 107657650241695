import { createContext } from 'react';
import type { Dispatch } from 'react';

import { initialAction, initialState } from './initial';

const CheckoutValueContext = createContext<typeof initialState>(initialState);

const CheckoutActionContext = createContext<Dispatch<typeof initialAction>>(
  () => {},
);

export { CheckoutActionContext, CheckoutValueContext };
