import { Fetch, setIdentityToken } from '@/lib';
import type { IdentityPayload, IdentityToken } from '@/types';

type RequestResponse = IdentityToken;

type RequestParameter = {};

type RequestBody = Pick<
  IdentityPayload,
  'phoneNumber' | 'password' | 'platform'
>;

const signInWithPassword = async (body: RequestBody) => {
  const response = await Fetch<RequestResponse, RequestParameter, RequestBody>({
    url: 'identity/signInWithPassword',
    method: 'POST',
    body,
  });

  setIdentityToken(response);

  return response;
};

export { signInWithPassword };
