import type { MetadataRoute } from 'next';

import type {
  SiteMap,
  TorobMetadata,
  TorobMetaTag,
  TorobMetaTagOpenGraph,
} from '@/types';
import { generateFileUrl, PATHNAME } from '@/utils';

const {
  DEFAULT: {
    BASE_URL: DEFAULT_BASE_URL,
    SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR,
  },
  LOCAL: {
    CATEGORY: { BASE_URL: CATEGORY_BASE_URL },
    PRODUCT: { BASE_URL: PRODUCT_BASE_URL },
  },
  QUERY_PARAM: {
    DEFAULT: { PAGE_NUMBER: DEFAULT_PAGE_NUMBER },
    SYMBOL: {
      QUESTION_MARK: QUESTION_MARK_SYMBOL,
      ASSIGNMENT: ASSIGNMENT_SYMBOL,
    },
    PAGE_NUMBER: { BASE_URL: PAGE_NUMBER_BASE_URL },
  },
} = PATHNAME;

const generateCanonical = (
  url: string,
  pageNumber: string | number = DEFAULT_PAGE_NUMBER,
) =>
  `${DEFAULT_BASE_URL}${decodeURIComponent(url)}${
    Number(pageNumber) > DEFAULT_PAGE_NUMBER
      ? `${QUESTION_MARK_SYMBOL}${PAGE_NUMBER_BASE_URL}${ASSIGNMENT_SYMBOL}${pageNumber}`
      : ''
  }`;

const generateSitemapIndex = async (sitemaps: string[]) => {
  let xml = '<?xml version="1.0" encoding="UTF-8"?>';
  xml += '<sitemapindex xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">';

  for (const sitemap of sitemaps) {
    xml += '<sitemap>';
    xml += `<loc>${sitemap}</loc>`;
    xml += '</sitemap>';
  }

  xml += '</sitemapindex>';
  return xml;
};

const generateSitemap = (
  items: SiteMap[],
  type: typeof CATEGORY_BASE_URL | typeof PRODUCT_BASE_URL,
): MetadataRoute.Sitemap =>
  items.map(({ slug, lastModified }) => ({
    url: `${DEFAULT_BASE_URL}${type}${slug}${DEFAULT_SLUG_SEPARATOR}`,
    lastModified,
  }));

const generateTorobMetadata = ({
  id,
  name,
  price,
  originalPrice,
  isAvailable,
  imageId,
}: TorobMetadata) => {
  const other: TorobMetaTag = {
    product_id: id,
    product_name: name,
    product_price: price,
    product_old_price: originalPrice,
    availability: isAvailable ? 'instock' : 'outofstock',
  };

  const openGraph: TorobMetaTagOpenGraph = {
    images: [
      {
        url: generateFileUrl('Product', imageId, 500, 500),
        width: 500,
        height: 500,
      },
    ],
  };

  return { other, openGraph };
};

export {
  generateCanonical,
  generateSitemap,
  generateSitemapIndex,
  generateTorobMetadata,
};
