import { getSiteMapCount } from '@/services';
import { pageNumberRange, PATHNAME } from '@/utils';

const {
  LOCAL: {},
  QUERY_PARAM: {
    DEFAULT: {
      PAGE_NUMBER: DEFAULT_PAGE_NUMBER,
      SITEMAP_SIZE: DEFAULT_SITEMAP_SIZE,
    },
  },
} = PATHNAME;

type SitemapProps = {
  id: `${'categories' | 'products'}-${number}`;
};

const generateSitemapSegments = async (): Promise<SitemapProps[]> => {
  const { categories: categoriesCount, products: productsCount } =
    await getSiteMapCount();

  const categoriesRange = pageNumberRange(
    DEFAULT_PAGE_NUMBER,
    Math.ceil(categoriesCount / DEFAULT_SITEMAP_SIZE),
  );
  const productsRange = pageNumberRange(
    DEFAULT_PAGE_NUMBER,
    Math.ceil(productsCount / DEFAULT_SITEMAP_SIZE),
  );

  const categoriesSitemap: SitemapProps[] = categoriesRange.flatMap(() =>
    categoriesRange.map(value => ({
      id: `categories-${value}`,
    })),
  );
  const productsSitemap: SitemapProps[] = productsRange.flatMap(() =>
    productsRange.map(value => ({
      id: `products-${value}`,
    })),
  );

  return [...categoriesSitemap, ...productsSitemap];
};

export { generateSitemapSegments };
