import { Fetch } from '@/lib';
import type { Notification } from '@/types';

type RequestResponse = Notification;

type RequestParameter = {
  id: string;
};

type RequestBody = Pick<Notification, 'seen'>;

const updateNotification = async (
  params: RequestParameter,
  body: RequestBody,
) =>
  await Fetch<RequestResponse, RequestParameter, RequestBody>({
    url: 'user/updateNotification',
    method: 'POST',
    params,
    body,
  });

export { updateNotification };
