'use client';

import { useReducer } from 'react';

import { LoginActionContext, LoginValueContext } from './context';
import { useLoginAction, useLoginValue } from './hook';
import { initialState } from './initial';
import { reducer } from './reducer';

const LoginContext = ({ children }: Readonly<React.PropsWithChildren>) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <LoginValueContext.Provider value={state}>
      <LoginActionContext.Provider value={dispatch}>
        {children}
      </LoginActionContext.Provider>
    </LoginValueContext.Provider>
  );
};

export { LoginContext, useLoginAction, useLoginValue };
