'use client';

import * as React from 'react';

import {
  Alert,
  AlertDescription,
  AlertTitle,
  WarningCircleIcon,
} from '@/components';

type EmptyProps = React.ComponentPropsWithoutRef<typeof Alert> &
  Partial<Record<'heading' | 'paragraph', React.ReactNode>>;

const Empty = React.forwardRef<React.ElementRef<typeof Alert>, EmptyProps>(
  (
    {
      heading = 'اطلاعاتی موجود نیست',
      paragraph = `به نظر می رسد در حال حاضر هیچ داده ای برای نمایش وجود ندارد. لطفاً بعداً
        دوباره بررسی کنید یا جستجوی دیگری را امتحان کنید.`,
      ...props
    },
    ref,
  ) => (
    <Alert ref={ref} {...props}>
      <WarningCircleIcon className='stroke-warning' />
      <AlertTitle className='text-lg'>{heading}</AlertTitle>
      <AlertDescription className='text-xs text-muted-foreground'>
        {paragraph}
      </AlertDescription>
    </Alert>
  ),
);
Empty.displayName = 'Empty';

export { Empty };
