'use client';

import * as React from 'react';

import { CaretDoubleLeftIcon, DotsThreeIcon, LocaleLink } from '@/components';
import { cn } from '@/utils';

const Breadcrumb = React.forwardRef<
  React.ElementRef<'nav'>,
  React.ComponentPropsWithoutRef<'nav'>
>(({ 'aria-label': ariaLabel = 'مسیردهی', ...props }, ref) => (
  <nav ref={ref} aria-label={ariaLabel} {...props} />
));
Breadcrumb.displayName = 'Breadcrumb';

const BreadcrumbList = React.forwardRef<
  React.ElementRef<'ol'>,
  React.ComponentPropsWithoutRef<'ol'>
>(({ className, ...props }, ref) => (
  <ol
    ref={ref}
    className={cn(
      'flex flex-wrap items-center gap-1 break-words text-sm text-muted-foreground',
      className,
    )}
    {...props}
  />
));
BreadcrumbList.displayName = 'BreadcrumbList';

const BreadcrumbItem = React.forwardRef<
  React.ElementRef<'li'>,
  React.ComponentPropsWithoutRef<'li'>
>(({ className, ...props }, ref) => (
  <li
    ref={ref}
    className={cn('inline-flex items-center gap-1.5', className)}
    {...props}
  />
));
BreadcrumbItem.displayName = 'BreadcrumbItem';

const BreadcrumbLink = React.forwardRef<
  React.ElementRef<typeof LocaleLink>,
  React.ComponentPropsWithoutRef<typeof LocaleLink>
>(({ className, ...props }, ref) => (
  <LocaleLink
    ref={ref}
    className={cn('transition-colors hover:text-foreground', className)}
    {...props}
  />
));
BreadcrumbLink.displayName = 'BreadcrumbLink';

const BreadcrumbPage = React.forwardRef<
  React.ElementRef<'span'>,
  React.ComponentPropsWithoutRef<'span'>
>(
  (
    {
      role = 'link',
      'aria-current': ariaCurrent = 'page',
      'aria-disabled': ariaDisabled = true,
      className,
      ...props
    },
    ref,
  ) => (
    <span
      ref={ref}
      role={role}
      aria-current={ariaCurrent}
      aria-disabled={ariaDisabled}
      className={cn('font-normal text-primary', className)}
      {...props}
    />
  ),
);
BreadcrumbPage.displayName = 'BreadcrumbPage';

const BreadcrumbSeparator = React.forwardRef<
  React.ElementRef<'li'>,
  React.ComponentPropsWithoutRef<'li'>
>(
  (
    {
      role = 'presentation',
      'aria-hidden': ariaHidden = true,
      children,
      ...props
    },
    ref,
  ) => (
    <li ref={ref} role={role} aria-hidden={ariaHidden} {...props}>
      {children ?? <CaretDoubleLeftIcon className='size-3' />}
    </li>
  ),
);
BreadcrumbSeparator.displayName = 'BreadcrumbSeparator';

const BreadcrumbEllipsis = React.forwardRef<
  React.ElementRef<'span'>,
  React.ComponentPropsWithoutRef<'span'>
>(
  (
    {
      role = 'presentation',
      'aria-hidden': ariaHidden = true,
      className,
      ...props
    },
    ref,
  ) => (
    <span
      ref={ref}
      role={role}
      aria-hidden={ariaHidden}
      className={cn('flex size-9 items-center justify-center', className)}
      {...props}
    >
      <DotsThreeIcon className='size-4' />
      <span className='sr-only'>بیشتر</span>
    </span>
  ),
);
BreadcrumbEllipsis.displayName = 'BreadcrumbElipssis';

export {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
};
