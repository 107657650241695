import { Fetch } from '@/lib';
import { createRecipientAddressInfo } from '@/services';

type RequestResponse = Awaited<ReturnType<typeof createRecipientAddressInfo>>;

type RequestParameter = {
  id: string;
};

type RequestBody = Parameters<typeof createRecipientAddressInfo>[number];

const updateRecipientAddressInfo = async (
  params: RequestParameter,
  body: RequestBody,
) =>
  await Fetch<RequestResponse, RequestParameter, RequestBody>({
    url: 'user/updateRecipientAddressInfo',
    method: 'PUT',
    params,
    body,
  });

export { updateRecipientAddressInfo };
