'use client';

import * as React from 'react';

import {
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  Separator,
  TypographyMuted,
  TypographyParagraph,
  TypographySmall,
} from '@/components';
import type { CartInvoice as CartInvoiceType } from '@/types';
import { cn, numberFormat, UNIT } from '@/utils';

const { PRICE } = UNIT;

type CartInvoiceContextProps = Partial<CartInvoiceType>;

const CartInvoiceContext = React.createContext<CartInvoiceContextProps>(
  {} as CartInvoiceContextProps,
);

const useCartInvoice = () => {
  const context = React.useContext(CartInvoiceContext);

  if (!context) {
    throw new Error('useCartInvoice must be used within a <CartInvoice />');
  }

  return context;
};

type CartInvoiceProps = React.ComponentPropsWithoutRef<typeof Card> &
  CartInvoiceContextProps;

const CartInvoice = React.forwardRef<
  React.ElementRef<typeof Card>,
  CartInvoiceProps
>(
  (
    {
      id,
      discountCode,
      paperInvoice,
      originalPrice,
      discount,
      deliveryPrice,
      totalPrice,
      children,
      ...props
    },
    ref,
  ) => (
    <CartInvoiceContext.Provider
      value={{
        id,
        discountCode,
        paperInvoice,
        originalPrice,
        discount,
        deliveryPrice,
        totalPrice,
      }}
    >
      <Card ref={ref} {...props}>
        {children}
      </Card>
    </CartInvoiceContext.Provider>
  ),
);
CartInvoice.displayName = 'CartInvoice';

const CartInvoiceHeader = React.forwardRef<
  React.ElementRef<typeof CardHeader>,
  React.ComponentPropsWithoutRef<typeof CardHeader>
>(({ className, ...props }, ref) => {
  const { originalPrice, discount } = useCartInvoice();

  if (originalPrice || discount) {
    return (
      <React.Fragment>
        <CardHeader
          ref={ref}
          className={cn(
            'gap-2.5 *:flex *:w-full *:items-center *:justify-between *:gap-2',
            className,
          )}
          {...props}
        >
          {originalPrice ? (
            <div>
              <TypographyMuted>قیمت محصولات:</TypographyMuted>
              <TypographyParagraph>
                <span className='text-lg font-semibold'>
                  {numberFormat(originalPrice)}
                </span>
                <TypographySmall> {PRICE}</TypographySmall>
              </TypographyParagraph>
            </div>
          ) : null}
          {discount ? (
            <div>
              <TypographyMuted>مقدار تخفیف:</TypographyMuted>
              <TypographyParagraph className='text-danger'>
                <span className='text-lg font-semibold'>
                  {numberFormat(discount)}
                </span>
                <TypographySmall> {PRICE}</TypographySmall>
              </TypographyParagraph>
            </div>
          ) : null}
        </CardHeader>
        <Separator />
      </React.Fragment>
    );
  }
});
CartInvoiceHeader.displayName = 'CartInvoiceHeader';

const CartInvoiceContent = React.forwardRef<
  React.ElementRef<typeof CardContent>,
  React.ComponentPropsWithoutRef<typeof CardContent>
>(({ className, ...props }, ref) => {
  const { deliveryPrice, totalPrice } = useCartInvoice();

  if (deliveryPrice || totalPrice) {
    return (
      <CardContent
        ref={ref}
        className={cn(
          'gap-2.5 *:flex *:w-full *:items-center *:justify-between *:gap-2',
          className,
        )}
        {...props}
      >
        {deliveryPrice ? (
          <div>
            <TypographyMuted>هزینه ارسال:</TypographyMuted>
            <TypographyParagraph>
              <span className='text-lg font-semibold'>
                {numberFormat(deliveryPrice)}
              </span>
              <TypographySmall> {PRICE}</TypographySmall>
            </TypographyParagraph>
          </div>
        ) : null}
        {totalPrice ? (
          <div>
            <TypographyMuted>مبلغ قابل پرداخت:</TypographyMuted>
            <TypographyParagraph className='text-primary'>
              <span className='text-xl font-bold'>
                {numberFormat(totalPrice)}
              </span>
              <TypographySmall> {PRICE}</TypographySmall>
            </TypographyParagraph>
          </div>
        ) : null}
      </CardContent>
    );
  }
});
CartInvoiceContent.displayName = 'CartInvoiceContent';

const CartInvoiceFooter = React.forwardRef<
  React.ElementRef<typeof CardFooter>,
  React.ComponentPropsWithoutRef<typeof CardFooter>
>(({ className, ...props }, ref) => (
  <CardFooter ref={ref} className={cn('mt-2.5', className)} {...props} />
));
CartInvoiceFooter.displayName = 'CartInvoiceFooter';

const CartInvoiceFooterAction = React.forwardRef<
  React.ElementRef<typeof Button>,
  React.ComponentPropsWithoutRef<typeof Button>
>(({ className, ...props }, ref) => (
  <Button ref={ref} className={cn('w-full', className)} {...props} />
));
CartInvoiceFooterAction.displayName = 'CartInvoiceFooterAction';

export {
  CartInvoice,
  CartInvoiceContent,
  CartInvoiceFooter,
  CartInvoiceFooterAction,
  CartInvoiceHeader,
};
export { useCartInvoice };
