import { checkAuth } from '@/services';

type State = Partial<Awaited<ReturnType<typeof checkAuth>>>;
const initialState: State = {};

type Action =
  | {
      type: 'UPDATE_CHECK_AUTH_SUCCESS';
      payload: Pick<typeof initialState, 'success'>;
    }
  | undefined;
const initialAction: Action = undefined;

export { initialAction, initialState };
