'use client';

import * as React from 'react';

import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';
import type { VariantProps } from 'class-variance-authority';

import { toggleVariants } from '@/components';
import { cn } from '@/utils';

type ToggleGroupContextProps = VariantProps<typeof toggleVariants>;

const ToggleGroupContext = React.createContext<ToggleGroupContextProps>({
  variant: 'default',
  size: 'default',
});

const useToggleGroup = () => {
  const context = React.useContext(ToggleGroupContext);

  if (!context) {
    throw new Error('useToggleGroup must be used within a <ToggleGroup />');
  }

  return context;
};

type ToggleGroupProps = React.ComponentPropsWithoutRef<
  typeof ToggleGroupPrimitive.Root
> &
  ToggleGroupContextProps;

const ToggleGroup = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Root>,
  ToggleGroupProps
>(({ variant, size, dir = 'rtl', className, ...props }, ref) => (
  <ToggleGroupContext.Provider value={{ variant, size }}>
    <ToggleGroupPrimitive.Root
      ref={ref}
      dir={dir}
      className={cn('flex items-center justify-center gap-1', className)}
      {...props}
    />
  </ToggleGroupContext.Provider>
));
ToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName;

type ToggleGroupItemProps = React.ComponentPropsWithoutRef<
  typeof ToggleGroupPrimitive.Item
> &
  ToggleGroupContextProps;

const ToggleGroupItem = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Item>,
  ToggleGroupItemProps
>(({ variant, size, className, ...props }, ref) => {
  const { variant: globalVariant, size: globalSize } = useToggleGroup();

  return (
    <ToggleGroupPrimitive.Item
      ref={ref}
      className={cn(
        toggleVariants({
          variant: variant ?? globalVariant,
          size: size ?? globalSize,
          className,
        }),
      )}
      {...props}
    />
  );
});
ToggleGroupItem.displayName = ToggleGroupPrimitive.Item.displayName;

export { ToggleGroup, ToggleGroupItem };
export { useToggleGroup };
