'use client';

import * as React from 'react';

import { StarIcon } from '@/components';
import { cn, randomId } from '@/utils';

type StarRatingProps = React.ComponentPropsWithoutRef<'div'> &
  Record<'value', number> &
  Partial<Record<'onValueChange', (value: number) => void>>;

const StarRating = React.forwardRef<React.ElementRef<'div'>, StarRatingProps>(
  (
    { value: starValue, onValueChange, dir = 'ltr', className, ...props },
    ref,
  ) => (
    <div
      ref={ref}
      dir={dir}
      className={cn('flex cursor-pointer gap-1', className)}
      {...props}
    >
      {Array.from<number, string>({ length: 5 }, (_v, _k) => randomId()).map(
        (value, index) => (
          <button
            key={value}
            type='button'
            onClick={() => {
              if (onValueChange) {
                if (index + 1 === starValue) {
                  onValueChange(0);
                } else {
                  onValueChange(index + 1);
                }
              }
            }}
          >
            {index + 1 <= starValue ? (
              <StarIcon weight='fill' className='text-warning' />
            ) : (
              <StarIcon />
            )}
          </button>
        ),
      )}
    </div>
  ),
);
StarRating.displayName = 'StarRating';

export { StarRating };
