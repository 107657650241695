'use client';

import * as React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  PhoneIcon,
  Separator,
  SpinnerIcon,
  TelInput,
  TypographyMuted,
  TypographyTime,
} from '@/components';
import {
  LoginContext,
  useCartAction,
  useCheckAuthAction,
  useLoginAction,
  useLoginValue,
} from '@/context';
import { useTimeCountdown } from '@/hooks';
import { sendSigninOtp, signInWithOtp } from '@/services';
import { cn, FormSchema, STORAGE } from '@/utils';

const {
  LOCAL: {
    DEFAULT: {
      LOGIN: {
        OTP: { EXPIRATION_TIME: LOGIN_OTP_EXPIRATION_TIME },
      },
    },
  },
} = STORAGE;

const OtpInitialSchema = FormSchema.pick({
  phoneNumber: true,
});
const OtpConfirmSchema = FormSchema.pick({
  otp: true,
});

type LoginDialogContextProps = React.ComponentPropsWithoutRef<typeof Dialog>;

const LoginDialogContext = React.createContext<LoginDialogContextProps>(
  {} as LoginDialogContextProps,
);

const useLoginDialog = () => {
  const context = React.useContext(LoginDialogContext);
  if (!context) {
    throw new Error('useLoginDialog must be used in <LoginDialogContext />');
  }
  return context;
};

const LoginDialog = (props: LoginDialogContextProps) => (
  <LoginContext>
    <LoginDialogContext.Provider value={props}>
      <Dialog {...props} />
    </LoginDialogContext.Provider>
  </LoginContext>
);

const LoginDialogTrigger = React.forwardRef<
  React.ElementRef<typeof DialogTrigger>,
  React.ComponentPropsWithoutRef<typeof DialogTrigger>
>((props, ref) => <DialogTrigger ref={ref} {...props} />);
LoginDialogTrigger.displayName = 'LoginDialogTrigger';

const LoginDialogContent = React.forwardRef<
  React.ElementRef<typeof DialogContent>,
  React.ComponentPropsWithoutRef<typeof DialogContent>
>(({ className, ...props }, ref) => (
  <DialogContent
    ref={ref}
    className={cn('max-w-sm p-6 [&>button]:top-7 [&>button]:end-6', className)}
    {...props}
  />
));
LoginDialogContent.displayName = 'LoginDialogContent';

const LoginDialogOtpInitial = React.forwardRef<
  React.ElementRef<'form'>,
  React.ComponentPropsWithoutRef<'form'>
>(({ className, onSubmit, ...props }, ref) => {
  const { step, phoneNumber = '' } = useLoginValue();
  const loginAction = useLoginAction();
  const form = useForm<z.infer<typeof OtpInitialSchema>>({
    resolver: zodResolver(OtpInitialSchema),
    defaultValues: { phoneNumber },
  });

  const onFormSubmit = async ({
    phoneNumber,
  }: z.infer<typeof OtpInitialSchema>) => {
    try {
      const { ticket } = await sendSigninOtp({ phoneNumber });

      loginAction({
        type: 'UPDATE_LOGIN_PHONE_NUMBER',
        payload: { phoneNumber },
      });
      loginAction({ type: 'UPDATE_LOGIN_TICKET', payload: { ticket } });
      loginAction({ type: 'UPDATE_LOGIN_STEP', payload: { step: 2 } });
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const {
    formState: { isSubmitting },
  } = form;

  if (step !== 1) return;

  return (
    <Form {...form}>
      <form
        ref={ref}
        className={cn('space-y-6', className)}
        onSubmit={onSubmit ?? form.handleSubmit(onFormSubmit)}
        {...props}
      >
        <DialogHeader className='!mb-10 text-center'>
          <DialogTitle className='text-xl'>ورود یا ثبت نام</DialogTitle>
          <DialogDescription>شماره همراه خود را وارد کنید</DialogDescription>
        </DialogHeader>
        <FormField
          control={form.control}
          name='phoneNumber'
          render={({ field }) => (
            <FormItem>
              <div className='relative space-y-0'>
                <FormControl>
                  <TelInput
                    autoFocus
                    placeholder='+98'
                    addonAfter={<PhoneIcon />}
                    {...field}
                  />
                </FormControl>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
        <Separator />
        <Button type='submit' className='w-full' disabled={isSubmitting}>
          {isSubmitting ? <SpinnerIcon className='animate-spin' /> : null}
          <span>ارسال کد تأیید</span>
        </Button>
      </form>
    </Form>
  );
});
LoginDialogOtpInitial.displayName = 'LoginDialogOtpInitial';

const LoginDialogOtpConfirm = React.forwardRef<
  React.ElementRef<'form'>,
  React.ComponentPropsWithoutRef<'form'>
>(({ className, onSubmit, ...props }, ref) => {
  const { onOpenChange } = useLoginDialog();
  const { step, phoneNumber = '', ticket } = useLoginValue();
  const loginAction = useLoginAction();
  const checkAuthAction = useCheckAuthAction();
  const cartAction = useCartAction();
  const form = useForm<z.infer<typeof OtpConfirmSchema>>({
    resolver: zodResolver(OtpConfirmSchema),
    defaultValues: {
      otp: '',
    },
  });

  const onFormSubmit = async ({ otp }: z.infer<typeof OtpConfirmSchema>) => {
    if (!ticket) return;

    try {
      await signInWithOtp({ otp, ticket });

      checkAuthAction({
        type: 'UPDATE_CHECK_AUTH_SUCCESS',
        payload: {
          success: true,
        },
      });
      cartAction({
        type: 'UPDATE_CART_STATUS',
        payload: {
          enabledInitialize: true,
          enabledSync: false,
        },
      });
      onOpenChange?.(false);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const {
    formState: { isSubmitting },
  } = form;

  if (step !== 2) return;

  return (
    <Form {...form}>
      <form
        ref={ref}
        className={cn('space-y-6', className)}
        onSubmit={onSubmit ?? form.handleSubmit(onFormSubmit)}
        {...props}
      >
        <DialogHeader className='!mb-10 text-center'>
          <DialogTitle className='text-xl'>ورود یا ثبت نام</DialogTitle>
          <DialogDescription>
            <span>کد تایید ارسال شده به شماره</span>
            <bdi> {phoneNumber} </bdi>
            <span>را وارد کنید</span>
          </DialogDescription>
        </DialogHeader>
        <FormField
          control={form.control}
          name='otp'
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <InputOTP
                  autoFocus
                  maxLength={6}
                  onComplete={onSubmit ?? form.handleSubmit(onFormSubmit)}
                  render={({ slots }) => (
                    <InputOTPGroup>
                      {slots.map((slot, index) => (
                        <InputOTPSlot key={index} className='h-12' {...slot} />
                      ))}
                    </InputOTPGroup>
                  )}
                  {...field}
                />
              </FormControl>
              <div className='grid place-items-center'>
                <FormMessage />
              </div>
            </FormItem>
          )}
        />
        <LoginDialogOtpExpirationTime />
        <Separator />
        <div className='grid gap-2'>
          <Button type='submit' disabled={isSubmitting}>
            {isSubmitting ? <SpinnerIcon className='animate-spin' /> : null}
            <span>ورود</span>
          </Button>
          <Button
            variant='outline'
            className='text-primary'
            disabled={isSubmitting}
            onClick={() => {
              loginAction({
                type: 'UPDATE_LOGIN_STEP',
                payload: { step: 1 },
              });
            }}
          >
            تصحیح شماره همراه
          </Button>
        </div>
      </form>
    </Form>
  );
});
LoginDialogOtpConfirm.displayName = 'LoginDialogOtpConfirm';

const LoginDialogOtpExpirationTime = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, children, ...props }, ref) => {
  const { phoneNumber } = useLoginValue();
  const loginAction = useLoginAction();

  const { seconds, minutes, isRunning, restart } = useTimeCountdown({
    countStart: LOGIN_OTP_EXPIRATION_TIME,
  });
  const { isPending, mutate } = useMutation({
    mutationFn: sendSigninOtp,
    onSuccess: ({ ticket }) => {
      loginAction({ type: 'UPDATE_LOGIN_TICKET', payload: { ticket } });
      restart();
    },
    onError: ({ message }) => {
      toast.error(message);
    },
  });

  const content = isRunning ? (
    <div className='text-sm'>
      <TypographyTime className='mx-0.5 text-primary'>
        {minutes}:{seconds}
      </TypographyTime>
      <span>تا ارسال مجدد کد</span>
    </div>
  ) : (
    <>
      <TypographyMuted>کد تایید را دریافت نکردید؟</TypographyMuted>
      <Button
        variant='link'
        disabled={isPending}
        onClick={() => {
          if (phoneNumber) {
            mutate({ phoneNumber });
          }
        }}
      >
        {isPending ? (
          <SpinnerIcon className='animate-spin' />
        ) : (
          <span>ارسال مجدد کد</span>
        )}
      </Button>
    </>
  );

  return (
    <div
      ref={ref}
      className={cn('grid place-items-center', className)}
      {...props}
    >
      {children ?? content}
    </div>
  );
});
LoginDialogOtpExpirationTime.displayName = 'LoginDialogOtpExpirationTime';

export {
  LoginDialog,
  LoginDialogContent,
  LoginDialogOtpConfirm,
  LoginDialogOtpExpirationTime,
  LoginDialogOtpInitial,
  LoginDialogTrigger,
};
export { useLoginDialog };
