import { Fetch } from '@/lib';

type RequestResponse = {};

type RequestParameter = {};

type RequestBody = Record<'name' | 'phoneNumber' | 'content', string> &
  Partial<Record<'email', string>>;

const submitContactInfo = async (body: RequestBody) =>
  await Fetch<RequestResponse, RequestParameter, RequestBody>({
    url: 'contactInfo/submitContactInfo',
    method: 'POST',
    body,
  });

export { submitContactInfo };
