import { Fetch } from '@/lib';
import type { Cart } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    CART: { BASE: BASE_CART },
  },
} = TAG;

type RequestResponse = Cart;

const getCart = async () =>
  await Fetch<RequestResponse>({
    url: 'cart/getCart',
    next: {
      tags: [BASE_CART],
    },
  });

export { getCart };
