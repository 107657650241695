'use client';

import * as React from 'react';

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  TypographyParagraph,
  TypographyTime,
  UserIcon,
} from '@/components';
import type { CommentSummary } from '@/types';
import { cn, dateTimeFormat, generateFileUrl } from '@/utils';

type CommentCardContextProps = CommentSummary;

const CommentCardContext = React.createContext<CommentCardContextProps>(
  {} as CommentCardContextProps,
);

const useCommentCard = () => {
  const context = React.useContext(CommentCardContext);
  if (!context) {
    throw new Error('useCommentCard must be used within a <CommentCard />');
  }
  return context;
};

type CommentCardProps = React.ComponentPropsWithoutRef<typeof Card> &
  CommentCardContextProps;

const CommentCard = React.forwardRef<
  React.ElementRef<typeof Card>,
  CommentCardProps
>(({ id, content, publishTime, rate, user, ...props }, ref) => (
  <CommentCardContext.Provider
    value={{
      id,
      content,
      publishTime,
      rate,
      user,
    }}
  >
    <Card ref={ref} {...props} />
  </CommentCardContext.Provider>
));
CommentCard.displayName = 'CommentCard';

const CommentCardHeader = React.forwardRef<
  React.ElementRef<typeof CardHeader>,
  React.ComponentPropsWithoutRef<typeof CardHeader>
>(({ className, children, ...props }, ref) => {
  const {
    publishTime,
    user: { firstName, lastName, displayName, avatarId },
  } = useCommentCard();

  const userName = displayName ?? `${firstName ?? ''} ${lastName ?? ''}`.trim();

  return (
    <CardHeader
      ref={ref}
      className={cn('flex-row items-center gap-3', className)}
      {...props}
    >
      <Avatar>
        {avatarId ? (
          <AvatarImage src={generateFileUrl('User', avatarId)} alt='avatar' />
        ) : null}
        <AvatarFallback>
          <UserIcon />
        </AvatarFallback>
      </Avatar>
      <div className='flex flex-col gap-1'>
        <CardTitle>{userName}</CardTitle>
        <TypographyTime className='text-xs text-secondary'>
          {dateTimeFormat(new Date(publishTime))}
        </TypographyTime>
      </div>
    </CardHeader>
  );
});
CommentCardHeader.displayName = 'CommentCardHeader';

const CommentCardContent = React.forwardRef<
  React.ElementRef<typeof CardContent>,
  React.ComponentPropsWithoutRef<typeof CardContent>
>(({ className, children, ...props }, ref) => {
  const { content } = useCommentCard();

  return (
    <CardContent ref={ref} className={cn('ps-14', className)} {...props}>
      <TypographyParagraph className='text-sm'>{content}</TypographyParagraph>
    </CardContent>
  );
});
CommentCardContent.displayName = 'CommentCardContent';

export { CommentCard, CommentCardContent, CommentCardHeader };
