import { initialAction, initialState } from './initial';

type Reducer = (
  state: typeof initialState,
  action: typeof initialAction,
) => typeof initialState;

const reducer: Reducer = (state, action) => {
  switch (action?.type) {
    case 'INITIALIZE_CART_PRODUCTS': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SYNC_CART_PRODUCTS': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'ADD_CART_PRODUCT': {
      const { products, ...otherState } = state;
      return {
        ...otherState,
        products: [action.payload, ...products],
      };
    }
    case 'UPDATE_CART_PRODUCT': {
      const { products, ...otherState } = state;
      const updatedSubProductId = action.payload.subProduct.id;

      const updatedProducts = products.map(product => {
        const subProductId = product.subProduct.id;
        return subProductId === updatedSubProductId ? action.payload : product;
      });

      return {
        ...otherState,
        products: updatedProducts,
      };
    }
    case 'DELETE_CART_PRODUCT': {
      const { products, ...otherState } = state;
      const deletedSubProductId = action.payload.subProduct.id;

      const filteredProducts = products.filter(product => {
        const subProductId = product.subProduct.id;
        return subProductId !== deletedSubProductId;
      });

      return {
        ...otherState,
        products: filteredProducts,
      };
    }
    case 'UPDATE_CART_INVOICE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_CART_STATUS': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer };
