import { useContext } from 'react';

import { CheckAuthActionContext, CheckAuthValueContext } from './context';

const useCheckAuthValue = () => {
  const context = useContext(CheckAuthValueContext);

  if (!context) {
    throw new Error(
      'useCheckAuthValue must be used within a <CheckAuthContext />',
    );
  }

  return context;
};

const useCheckAuthAction = () => {
  const context = useContext(CheckAuthActionContext);

  if (!context) {
    throw new Error(
      'useCheckAuthAction must be used within a <CheckAuthContext />',
    );
  }

  return context;
};

export { useCheckAuthAction, useCheckAuthValue };
