'use client';

import { useCallback, useEffect, useMemo, useReducer } from 'react';

import { usePathname } from 'next/navigation';

import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useLocalStorage } from 'usehooks-ts';

import { useCheckAuthValue } from '@/context';
import { initializeCart, syncCart } from '@/services';
import {
  enforceValueLimits,
  fixedParseFloat,
  generateCartInvoice,
  generatePurchaseStock,
  preciseRound,
  STORAGE,
} from '@/utils';

import { CartActionContext, CartValueContext } from './context';
import { useCartAction, useCartValue } from './hook';
import { initialState } from './initial';
import { reducer } from './reducer';

const {
  LOCAL: {
    CART: { PRODUCTS: LOCAL_CART_PRODUCTS },
  },
} = STORAGE;

const REFRESH_INTERVAL = 30 * 60 * 1000;

const CartContext = ({ children }: Readonly<React.PropsWithChildren>) => {
  const [localProducts, setLocalProducts] = useLocalStorage<
    (typeof initialState)['products']
  >(LOCAL_CART_PRODUCTS, initialState.products);
  const { success } = useCheckAuthValue();
  const [state, dispatch] = useReducer(reducer, initialState, () => ({
    products: localProducts,
    invoice: generateCartInvoice(localProducts),
    enabledSync: success,
  }));
  const pathname = usePathname();

  const { products, enabledInitialize, enabledSync } = state;

  const { mutate: mutateInitializeCart } = useMutation({
    mutationFn: initializeCart,
    onSuccess: data => {
      dispatch({
        type: 'INITIALIZE_CART_PRODUCTS',
        payload: generatePayload(data),
      });
      if (products.length > 0) {
        toast.info(
          'لیست محصولات ممکن است بر اساس موجودی انبار یا داده‌های پیشین شما به‌روزرسانی شده باشد.',
        );
      }
    },
    onError: ({ message }) => {
      toast.error(message);
    },
  });
  const { mutate: mutateSyncCart } = useMutation({
    mutationFn: syncCart,
    onSuccess: data => {
      dispatch({
        type: 'SYNC_CART_PRODUCTS',
        payload: generatePayload(data),
      });
    },
    onError: ({ message }) => {
      toast.error(message);
    },
  });

  const generatePayload = useCallback(
    ({
      id,
      cartProducts,
    }: Awaited<ReturnType<typeof initializeCart | typeof syncCart>>) => ({
      id,
      products: cartProducts.map(cartProduct => {
        const {
          packagingInfo: { amount: packagingInfoAmount },
          size,
        } = cartProduct;

        return {
          ...cartProduct,
          size: fixedParseFloat(size / packagingInfoAmount),
        };
      }),
    }),
    [],
  );
  const variables = useMemo<
    Parameters<typeof mutateSyncCart | typeof mutateInitializeCart>[0]
  >(
    () => ({
      cartProducts: products.map(({ subProduct, packagingInfo, size }) => {
        const { id: subProductId } = subProduct;
        const {
          identifier: packagingInfoIdentifier,
          amount: packagingInfoAmount,
        } = packagingInfo;

        const { minStockSize, maxStockSize } =
          generatePurchaseStock(subProduct);
        const roundedValue = preciseRound(size, packagingInfoAmount);
        const enforcedSize = enforceValueLimits(
          roundedValue,
          preciseRound(minStockSize, packagingInfoAmount),
          preciseRound(maxStockSize, packagingInfoAmount),
        );

        return {
          subProductId,
          packagingInfoIdentifier,
          size: parseFloat(enforcedSize),
        };
      }),
    }),
    [products],
  );

  useEffect(() => {
    dispatch({
      type: 'UPDATE_CART_INVOICE',
      payload: {
        invoice: generateCartInvoice(products),
      },
    });
    setLocalProducts(products);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  useEffect(() => {
    if (!enabledInitialize) return;

    mutateInitializeCart(variables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledInitialize]);

  useEffect(() => {
    if (!enabledSync || !success) return;

    mutateSyncCart(variables);
    const intervalId = setInterval(
      () => mutateSyncCart(variables),
      REFRESH_INTERVAL,
    );

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledSync, success, pathname]);

  return (
    <CartValueContext.Provider value={state}>
      <CartActionContext.Provider value={dispatch}>
        {children}
      </CartActionContext.Provider>
    </CartValueContext.Provider>
  );
};

export { CartContext, useCartAction, useCartValue };
