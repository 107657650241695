'use client';

import * as React from 'react';

const PelleIcon = React.forwardRef<
  React.ElementRef<'svg'>,
  React.ComponentPropsWithoutRef<'svg'>
>(
  (
    {
      fill = 'currentColor',
      width = 24,
      height = 24,
      viewBox = '0 0 1900 1900',
      xmlns = 'http://www.w3.org/2000/svg',
      ...props
    },
    ref,
  ) => (
    <svg
      ref={ref}
      fill={fill}
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      {...props}
    >
      <path
        transform='translate(1200,400)'
        d='M0 0 C165.33 0 330.66 0 501 0 C501 56.76 501 113.52 501 172 C390.78 172 280.56 172 167 172 C167 284.2 167 396.4 167 512 C56.78 512 -53.44 512 -167 512 C-167 623.21 -167 734.42 -167 849 C-277.88 849 -388.76 849 -503 849 C-503 961.2 -503 1073.4 -503 1189 C-667.67 1189 -832.34 1189 -1002 1189 C-1002 1132.24 -1002 1075.48 -1002 1017 C-837.165 1016.505 -837.165 1016.505 -669 1016 C-668.67 903.8 -668.34 791.6 -668 676 C-558.77 676 -449.54 676 -337 676 C-337 563.47 -337 450.94 -337 335 C-225.79 335 -114.58 335 0 335 C0 224.45 0 113.9 0 0 Z'
      />
    </svg>
  ),
);
PelleIcon.displayName = 'PelleIcon';

const RubikaIcon = React.forwardRef<
  React.ElementRef<'svg'>,
  React.ComponentPropsWithoutRef<'svg'>
>(
  (
    {
      fill = 'currentColor',
      width = 24,
      height = 24,
      viewBox = '0 0 420 420',
      xmlns = 'http://www.w3.org/2000/svg',
      ...props
    },
    ref,
  ) => (
    <svg
      ref={ref}
      fill={fill}
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      {...props}
    >
      <g>
        <path
          d='M380.546,110.125L211.552,1.586c-3.293-2.115-7.515-2.115-10.807,0L31.744,110.081c-2.866,1.84-4.6,5.014-4.598,8.42
          l0.041,180.039c0.002,3.476,1.806,6.699,4.767,8.519l168.961,103.75c1.605,0.986,3.419,1.479,5.233,1.479
          c1.814,0,3.629-0.491,5.232-1.479L380.352,307.1c2.96-1.818,4.766-5.043,4.767-8.519l0.023-180.039
          C385.143,115.137,383.409,111.965,380.546,110.125z M206.147,25.627l61.936,39.629l-61.936,39.627l-61.934-39.627L206.147,25.627z
          M50.197,291.254v-63.938l60.692,36.848l0.01,64.377L50.197,291.254z M110.899,237.623l-60.703-37.285v-64.74l60.703,38.842
          V237.623z M63.22,116.834l59.186-37.869l59.186,37.869l-59.186,37.869L63.22,116.834z M133.908,174.44l60.705-38.844v64.742
          l-60.705,37.285V174.44z M133.938,277.927l40.348,25.815l-40.348,24.783V277.927L133.938,277.927z M194.642,379.952l-50.232-30.854
          l50.232-30.847V379.952z M206.138,297.04l-62.076-38.765l62.076-38.592l62.076,38.733L206.138,297.04z M217.653,379.952v-61.645
          l50.194,30.812L217.653,379.952z M278.358,328.571l-40.388-24.805l40.388-25.842V328.571z M278.388,237.67l-60.703-37.287v-64.736
          l60.703,38.842V237.67z M230.708,116.879l59.186-37.867l59.186,37.867l-59.186,37.869L230.708,116.879z M362.104,291.3
          l-60.705,37.286v-63.17l60.705-37.983V291.3z M362.104,200.383l-60.705,37.287v-63.182l60.705-38.844V200.383z'
        />
      </g>
    </svg>
  ),
);
RubikaIcon.displayName = 'RubikaIcon';

const BaleIcon = React.forwardRef<
  React.ElementRef<'svg'>,
  React.ComponentPropsWithoutRef<'svg'>
>(
  (
    {
      fill = 'currentColor',
      role = 'img',
      width = 24,
      height = 24,
      viewBox = '0 0 24 24',
      xmlns = 'http://www.w3.org/2000/svg',
      ...props
    },
    ref,
  ) => (
    <svg
      ref={ref}
      fill={fill}
      role={role}
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      {...props}
    >
      <path d='M11.425 23.987a12.218 12.218 0 0 1-2.95-.514 6.578 6.578 0 0 0-.336-.116C4.936 22.303 2.22 19.763.913 16.599a11.92 11.92 0 0 1-.9-4.063C.005 12.377.001 10.246 0 6.74 0 .71-.005 1.137.07.903.23.394.673.05 1.224.005c.421-.034.7.088 1.603.699.562.38 1.119.78 1.796 1.289.315.237.353.261.376.247l.35-.23c.58-.381 1.11-.677 1.7-.945A11.913 11.913 0 0 1 9.766.21a11.19 11.19 0 0 1 2.041-.2c1.14-.016 2.077.091 3.152.36 3.55.888 6.538 3.411 8.028 6.78.492 1.113.845 2.43.945 3.522.033.366.039.43.053.611.008.105.015.406.015.669 0 .783-.065 1.57-.169 2.064a5.474 5.474 0 0 0-.046.26c-.056.378-.214.987-.399 1.535-.205.613-.367.999-.684 1.633a11.95 11.95 0 0 1-2.623 3.436c-.44.396-.829.705-1.26 1.003-.647.445-1.307.812-2.039 1.134-.6.265-1.44.539-2.101.686a11.165 11.165 0 0 1-1.178.202 12.28 12.28 0 0 1-2.076.082zm-.61-5.92c.294-.06.678-.209.864-.337.144-.099.428-.376 2.064-2.013a161.8 161.8 0 0 1 1.764-1.753c.017 0 1.687-1.67 1.687-1.689 0-.02 1.64-1.648 1.661-1.648.01 0 .063-.047.118-.106.467-.495.682-.957.716-1.547.026-.433-.06-.909-.217-1.196a2.552 2.552 0 0 0-.983-1.024c-.281-.163-.512-.233-.888-.27-.306-.031-.688 0-.948.075-.243.07-.603.274-.853.481-.042.035-1.279 1.265-2.748 2.733l-2.671 2.67-1.093-1.09c-.6-.6-1.12-1.114-1.155-1.142a2.419 2.419 0 0 0-1.338-.51c-.404-.013-.91.09-1.224.25a2.89 2.89 0 0 0-.659.526c-.108.12-.287.357-.29.385-.003.03-.009.044-.065.16a2.312 2.312 0 0 0-.224.91c-.011.229-.01.265.019.491.045.353.24.781.51 1.115.05.063.97.992 2.044 2.064 1.507 1.505 1.98 1.97 2.074 2.039.327.24.683.388 1.101.456.182.03.5.016.734-.03z' />
    </svg>
  ),
);
BaleIcon.displayName = 'BaleIcon';

const EitaaIcon = React.forwardRef<
  React.ElementRef<'svg'>,
  React.ComponentPropsWithoutRef<'svg'>
>(
  (
    {
      fill = 'currentColor',
      role = 'img',
      width = 24,
      height = 24,
      viewBox = '0 0 24 24',
      xmlns = 'http://www.w3.org/2000/svg',
      ...props
    },
    ref,
  ) => (
    <svg
      ref={ref}
      fill={fill}
      role={role}
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      {...props}
    >
      <path d='M5.968 23.942a6.624 6.624 0 0 1-2.332-.83c-1.62-.929-2.829-2.593-3.217-4.426-.151-.717-.17-1.623-.15-7.207C.288 5.47.274 5.78.56 4.79c.142-.493.537-1.34.823-1.767C2.438 1.453 3.99.445 5.913.08c.384-.073.94-.08 6.056-.08 6.251 0 6.045-.009 7.066.314a6.807 6.807 0 0 1 4.314 4.184c.33.937.346 1.087.369 3.555l.02 2.23-.391.268c-.558.381-1.29 1.06-2.316 2.15-1.182 1.256-2.376 2.42-2.982 2.907-1.309 1.051-2.508 1.651-3.726 1.864-.634.11-1.682.067-2.302-.095-.553-.144-.517-.168-.726.464a6.355 6.355 0 0 0-.318 1.546l-.031.407-.146-.03c-1.215-.241-2.419-1.285-2.884-2.5a3.583 3.583 0 0 1-.26-1.219l-.016-.34-.309-.284c-.644-.59-1.063-1.312-1.195-2.061-.212-1.193.34-2.542 1.538-3.756 1.264-1.283 3.127-2.29 4.953-2.68.658-.14 1.818-.177 2.403-.075 1.138.198 2.067.773 2.645 1.639.182.271.195.31.177.555a.812.812 0 0 1-.183.493c-.465.651-1.848 1.348-3.336 1.68-2.625.585-4.294-.142-4.033-1.759.026-.163.04-.304.031-.313-.032-.032-.293.104-.575.3-.479.334-.903.984-1.05 1.607-.036.156-.05.406-.034.65.02.331.053.454.192.736.092.186.275.45.408.589l.24.251-.096.122a4.845 4.845 0 0 0-.677 1.217 3.635 3.635 0 0 0-.105 1.815c.103.461.421 1.095.739 1.468.242.285.797.764.886.764.024 0 .044-.048.044-.106.001-.23.184-.973.326-1.327.423-1.058 1.351-1.96 2.82-2.74.245-.13.952-.47 1.572-.757 1.36-.63 2.103-1.015 2.511-1.305 1.176-.833 1.903-2.065 2.14-3.625.086-.57.086-1.634 0-2.207-.368-2.438-2.195-4.096-4.818-4.37-2.925-.307-6.648 1.953-8.942 5.427-1.116 1.69-1.87 3.565-2.187 5.443-.123.728-.169 2.08-.093 2.75.193 1.704.822 3.078 1.903 4.156a6.531 6.531 0 0 0 1.87 1.313c2.368 1.13 4.99 1.155 7.295.071.996-.469 1.974-1.196 3.023-2.25 1.02-1.025 1.71-1.88 3.592-4.458 1.04-1.423 1.864-2.368 2.272-2.605l.15-.086-.019 3.091c-.018 2.993-.022 3.107-.123 3.561-.6 2.678-2.54 4.636-5.195 5.242l-.468.107-5.775.01c-4.734.008-5.85-.002-6.19-.056z' />
    </svg>
  ),
);
EitaaIcon.displayName = 'EitaaIcon';

const AparatIcon = React.forwardRef<
  React.ElementRef<'svg'>,
  React.ComponentPropsWithoutRef<'svg'>
>(
  (
    {
      fill = 'currentColor',
      role = 'img',
      width = 24,
      height = 24,
      viewBox = '0 0 68.33 68.33',
      xmlns = 'http://www.w3.org/2000/svg',
      ...props
    },
    ref,
  ) => (
    <svg
      ref={ref}
      fill={fill}
      role={role}
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns={xmlns}
      {...props}
    >
      <path d='M29.49,2,23.2.36A10.58,10.58,0,0,0,10.25,7.87L8.68,13.8A32.4,32.4,0,0,1,29.49,2Z' />
      <path d='M1.9,39.33.36,45.14A10.58,10.58,0,0,0,7.87,58.08l6,1.6A32.41,32.41,0,0,1,1.9,39.33Z' />
      <path d='M60.46,10.25,53.73,8.46a32.4,32.4,0,0,1,12.4,21.7l1.85-7A10.58,10.58,0,0,0,60.46,10.25Z' />
      <path d='M38.69,66.26,45.14,68a10.58,10.58,0,0,0,12.94-7.51l1.82-6.84A32.42,32.42,0,0,1,38.69,66.26Z' />
      <path d='M34.17,4.54A29.63,29.63,0,1,0,63.79,34.17,29.63,29.63,0,0,0,34.17,4.54ZM17.39,19.32a8.46,8.46,0,1,1,6.71,9.91A8.46,8.46,0,0,1,17.39,19.32ZM29.7,44.92A8.46,8.46,0,1,1,23,35,8.46,8.46,0,0,1,29.7,44.92Zm3.59-6.85a3.76,3.76,0,1,1,4.41-3A3.76,3.76,0,0,1,33.29,38.07ZM50.94,49a8.46,8.46,0,1,1-6.71-9.91A8.46,8.46,0,0,1,50.94,49Zm-5.6-15.68a8.46,8.46,0,1,1,9.91-6.71A8.46,8.46,0,0,1,45.34,33.33Z' />
    </svg>
  ),
);
AparatIcon.displayName = 'AparatIcon';

const BrandsIcon = React.forwardRef<
  React.ElementRef<'svg'>,
  React.ComponentPropsWithoutRef<'svg'>
>(
  (
    {
      width = '32',
      height = '32',
      viewBox = '0 0 32 32',
      fill = 'none',
      xmlns = 'http://www.w3.org/2000/svg',
      ...props
    },
    ref,
  ) => (
    <svg
      ref={ref}
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns={xmlns}
      {...props}
    >
      <circle
        cx='16.0001'
        cy='16.0001'
        r='14.0274'
        fill='#0C5EE2'
        fill-opacity='0.08'
      />
      <path
        d='M6.57715 10.4499C6.57715 8.97711 7.77106 7.7832 9.24381 7.7832H22.7559C24.2286 7.7832 25.4225 8.97711 25.4225 10.4499V26.7592C25.4225 28.232 24.2286 29.4259 22.7559 29.4259H9.24382C7.77106 29.4259 6.57715 28.232 6.57715 26.7592V10.4499Z'
        fill='white'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.91064 10.4505C5.91064 8.60957 7.40303 7.11719 9.24398 7.11719H22.756C24.597 7.11719 26.0894 8.60957 26.0894 10.4505V26.7599C26.0894 28.6008 24.597 30.0932 22.756 30.0932H9.24398C7.40303 30.0932 5.91064 28.6008 5.91064 26.7599V10.4505ZM9.24398 8.45052C8.13941 8.45052 7.24398 9.34595 7.24398 10.4505V26.7599C7.24398 27.8645 8.13941 28.7599 9.24398 28.7599H22.756C23.8606 28.7599 24.756 27.8645 24.756 26.7599V10.4505C24.756 9.34595 23.8606 8.45052 22.756 8.45052H9.24398Z'
        fill='white'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.91064 10.4505C5.91064 8.60957 7.40303 7.11719 9.24398 7.11719H22.756C24.597 7.11719 26.0894 8.60957 26.0894 10.4505V26.7599C26.0894 28.6008 24.597 30.0932 22.756 30.0932H9.24398C7.40303 30.0932 5.91064 28.6008 5.91064 26.7599V10.4505ZM9.24398 8.45052C8.13941 8.45052 7.24398 9.34595 7.24398 10.4505V26.7599C7.24398 27.8645 8.13941 28.7599 9.24398 28.7599H22.756C23.8606 28.7599 24.756 27.8645 24.756 26.7599V10.4505C24.756 9.34595 23.8606 8.45052 22.756 8.45052H9.24398Z'
        fill='black'
      />
      <path
        d='M11 18.6316H18.3333C19.3058 18.6316 20.2384 19.0197 20.9261 19.7107C21.6137 20.4016 22 21.3387 22 22.3158C22 23.2929 21.6137 24.23 20.9261 24.9209C20.2384 25.6118 19.3058 26 18.3333 26H11V12H17.2333C18.1085 12 18.9479 12.3493 19.5668 12.9712C20.1857 13.593 20.5333 14.4364 20.5333 15.3158C20.5333 16.1952 20.1857 17.0386 19.5668 17.6604C18.9479 18.2822 18.1085 18.6316 17.2333 18.6316'
        stroke='#0C5EE2'
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ),
);
BrandsIcon.displayName = 'BrandsIcon';

export { AparatIcon, BaleIcon, BrandsIcon, EitaaIcon, PelleIcon, RubikaIcon };
