import { Fetch } from '@/lib';
import type { CommentSummary } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    COMMENT: {
      PRODUCT_GLOBAL: { BASE: BASE_PRODUCT_GLOBAL },
    },
  },
} = TAG;

type RequestResponse = {
  total: number;
  data: CommentSummary[];
};

type RequestParameter = {
  id: string;
  pageSize: number;
  pageNumber: number;
};

const getProductComments = async (params: RequestParameter) =>
  await Fetch<RequestResponse, RequestParameter>({
    url: 'comment/getProductComments',
    params,
    next: {
      tags: [BASE_PRODUCT_GLOBAL],
    },
  });

export { getProductComments };
