import type { ProductSortType, PurchaseStatus } from '@/types';

const localizeProductSortType = (sortType: ProductSortType) => {
  switch (sortType) {
    case 'BestSelling':
      return { label: 'پرفروش ترین ها' };
    case 'Cheapest':
      return { label: 'ارزان ترین ها' };
    case 'MostExpensive':
      return { label: 'گران ترین ها' };
    case 'MostPopular':
      return { label: 'محبوب ترین ها' };
    case 'MostRelevant':
      return { label: 'مرتبط ترین ها' };
    case 'MostVisited':
      return { label: 'پربازدید ترین ها' };
    case 'Newest':
      return { label: 'جدید ترین ها' };
    case 'Oldest':
      return { label: 'قدیمی ترین ها' };
    default:
      return { label: undefined };
  }
};

const localizePurchaseStatus = (status: PurchaseStatus) => {
  switch (status) {
    case 'Purchased':
      return { label: 'در حال انجام', color: 'hsl(var(--warning))' };
    case 'Posted':
      return { label: 'ارسال‌ شده', color: 'hsl(var(--primary))' };
    case 'Delivered':
      return { label: 'تحویل داده ‌شده', color: 'hsl(var(--success))' };
    default:
      return { label: undefined, color: undefined };
  }
};

export { localizeProductSortType, localizePurchaseStatus };
