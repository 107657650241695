import { Fetch } from '@/lib';
import type { SiteMap } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    SEO: {
      SITE_MAP: { CATEGORIES: CATEGORIES_SEO_SITE_MAP },
    },
  },
} = TAG;

type RequestResponse = {
  items: SiteMap[];
};

const getSiteMapCategories = async () =>
  await Fetch<RequestResponse>({
    url: 'seo/getSiteMapCategories',
    next: {
      tags: [CATEGORIES_SEO_SITE_MAP],
    },
  });

export { getSiteMapCategories };
