import { Fetch } from '@/lib';
import type { RecipientAddressInfo } from '@/types';

type RequestResponse = RecipientAddressInfo;

type RequestParameter = {};

type RequestBody = Pick<
  RecipientAddressInfo,
  'title' | 'firstName' | 'lastName' | 'address' | 'telNumber' | 'phoneNumber'
> &
  Record<'cityId', string>;

const createRecipientAddressInfo = async (body: RequestBody) =>
  await Fetch<RequestResponse, RequestParameter, RequestBody>({
    url: 'user/createRecipientAddressInfo',
    method: 'POST',
    body,
  });

export { createRecipientAddressInfo };
