import { NextResponse } from 'next/server';
import type { NextMiddleware } from 'next/server';

const stackMiddlewares = (
  middlewares: Array<(middleware: NextMiddleware) => NextMiddleware>,
  index = 0,
): NextMiddleware => {
  const current = middlewares[index];

  if (current) {
    const next = stackMiddlewares(middlewares, index + 1);
    return current(next);
  }

  return () => NextResponse.next();
};

export { stackMiddlewares };
