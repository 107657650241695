'use client';

import * as React from 'react';

import Image from 'next/image';

import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  DownloadSimpleIcon,
  LocaleLink,
  Skeleton,
} from '@/components';
import type { CatalogSummary } from '@/types';
import {
  cn,
  dateTimeFormat,
  generateFileUrl,
  numberFormat,
  PATHNAME,
  UNIT,
} from '@/utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    CATALOGS: { BASE_URL: CATALOGS_BASE_URL },
  },
} = PATHNAME;
const { PRICE } = UNIT;

type CatalogCardContextProps = CatalogSummary;

const CatalogCardContext = React.createContext<CatalogCardContextProps>(
  {} as CatalogCardContextProps,
);

const useCatalogCard = () => {
  const context = React.useContext(CatalogCardContext);
  if (!context) {
    throw new Error('useCatalogCard must be used within a <CatalogCard />');
  }
  return context;
};

type CatalogCardProps = React.ComponentPropsWithoutRef<typeof Card> &
  CatalogCardContextProps;

const CatalogCard = React.forwardRef<
  React.ElementRef<typeof Card>,
  CatalogCardProps
>(
  (
    {
      id,
      slug,
      name,
      bannerId,
      publishTime,
      pageCount,
      className,
      children,
      ...props
    },
    ref,
  ) => {
    return (
      <CatalogCardContext.Provider
        value={{
          id,
          slug,
          name,
          bannerId,
          publishTime,
          pageCount,
        }}
      >
        <LocaleLink
          href={`${CATALOGS_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`}
          className='block h-full'
        >
          <Card ref={ref} className={cn('relative', className)} {...props}>
            {children}
          </Card>
        </LocaleLink>
      </CatalogCardContext.Provider>
    );
  },
);
CatalogCard.displayName = 'CatalogCard';

const CatalogCardHeader = React.forwardRef<
  React.ElementRef<typeof CardHeader>,
  React.ComponentPropsWithoutRef<typeof CardHeader>
>(({ className, children, ...props }, ref) => {
  const { name, bannerId } = useCatalogCard();

  const { width, height } = { width: 300, height: 320 };

  return (
    <CardHeader ref={ref} className={cn('relative h-52', className)} {...props}>
      {bannerId ? (
        <Image
          className='size-full object-cover'
          src={generateFileUrl('Product', bannerId, width, height)}
          width={width}
          height={height}
          alt={name}
        />
      ) : null}
      {children}
    </CardHeader>
  );
});
CatalogCardHeader.displayName = 'CatalogCardHeader';

const CatalogCardContent = React.forwardRef<
  React.ElementRef<typeof CardContent>,
  React.ComponentPropsWithoutRef<typeof CardContent>
>(({ className, ...props }, ref) => {
  const { name, publishTime, pageCount = 0 } = useCatalogCard();

  return (
    <CardContent ref={ref} className={cn('gap-4', className)} {...props}>
      <CardTitle className='text-base font-normal'>{name}</CardTitle>
      <div className='space-y-2'>
        <CardDescription className='flex items-center justify-between gap-px'>
          <span>سال انتشار:</span>
          <span>
            {dateTimeFormat(new Date(publishTime), { year: 'numeric' })}
          </span>
        </CardDescription>
        <CardDescription className='flex items-center justify-between gap-px'>
          <span>تعداد صفحات:</span>
          <span>{numberFormat(pageCount)}</span>
        </CardDescription>
      </div>
    </CardContent>
  );
});
CatalogCardContent.displayName = 'CatalogCardContent';

const CatalogCardFooter = React.forwardRef<
  React.ElementRef<typeof CardFooter>,
  React.ComponentPropsWithoutRef<typeof CardFooter>
>(({ className, ...props }, ref) => {
  const { id } = useCatalogCard();

  return (
    <CardFooter
      ref={ref}
      className={cn('flex-col gap-2 items-stretch', className)}
      {...props}
    >
      <Button
        asChild
        variant='outline'
        size='lg'
        className='rounded-sm font-normal text-primary'
      >
        <LocaleLink href={generateFileUrl('Config', id)}>
          <span>دانلود کاتالوگ</span>
          <DownloadSimpleIcon />
        </LocaleLink>
      </Button>
    </CardFooter>
  );
});
CatalogCardFooter.displayName = 'CatalogCardFooter';

const CatalogCardPlaceholder = React.forwardRef<
  React.ElementRef<typeof Card>,
  React.ComponentPropsWithoutRef<typeof Card>
>(({ className, ...props }, ref) => (
  <Card ref={ref} className={cn('gap-2', className)} {...props} />
));
CatalogCardPlaceholder.displayName = 'CatalogCardPlaceholder';

const CatalogCardHeaderPlaceholder = React.forwardRef<
  React.ElementRef<typeof CardHeader>,
  React.ComponentPropsWithoutRef<typeof CardHeader>
>(({ className, ...props }, ref) => (
  <CardHeader ref={ref} className={cn('h-52', className)} {...props}>
    <Skeleton className='size-full' />
  </CardHeader>
));
CatalogCardHeaderPlaceholder.displayName = 'CatalogCardHeaderPlaceholder';

const CatalogCardContentPlaceholder = React.forwardRef<
  React.ElementRef<typeof CardContent>,
  React.ComponentPropsWithoutRef<typeof CardContent>
>(({ className, ...props }, ref) => (
  <CardContent ref={ref} className={cn('flex-col gap-2', className)} {...props}>
    <Skeleton className='h-2 w-full' />
    <Skeleton className='h-2 w-2/3' />
  </CardContent>
));
CatalogCardContentPlaceholder.displayName = 'CatalogCardContentPlaceholder';

const CatalogCardFooterPlaceholder = React.forwardRef<
  React.ElementRef<typeof CardFooter>,
  React.ComponentPropsWithoutRef<typeof CardFooter>
>(({ className, ...props }, ref) => (
  <CardFooter ref={ref} className={cn('justify-end', className)} {...props}>
    <Skeleton className='h-2 w-1/3' />
  </CardFooter>
));
CatalogCardFooterPlaceholder.displayName = 'CatalogCardFooterPlaceholder';

export {
  CatalogCard,
  CatalogCardContent,
  CatalogCardContentPlaceholder,
  CatalogCardFooter,
  CatalogCardFooterPlaceholder,
  CatalogCardHeader,
  CatalogCardHeaderPlaceholder,
  CatalogCardPlaceholder,
};
