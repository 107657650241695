import { Fetch } from '@/lib';
import type { Brand } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    BRAND: { BASE: BASE_BRAND },
  },
} = TAG;

type RequestResponse = Brand;

type RequestParameter = {
  slug: string;
};

const getBrandBySlug = async ({ slug }: RequestParameter) => {
  try {
    return await Fetch<RequestResponse, RequestParameter>({
      url: `brand/getBrandBySlug/${slug}`,
      next: { tags: [BASE_BRAND] },
    });
  } catch (error) {
    return null;
  }
};

export { getBrandBySlug };
