import { Fetch } from '@/lib';
import type { UserInfo } from '@/types';

type RequestResponse = UserInfo;

type RequestParameter = {
  userId: string;
};

type RequestBody = Partial<
  Pick<
    UserInfo,
    | 'firstName'
    | 'lastName'
    | 'displayName'
    | 'userName'
    | 'email'
    | 'avatarId'
    | 'telNumber'
  >
>;

const updateUserAccountInformation = async (
  { userId }: RequestParameter,
  body: RequestBody,
) =>
  await Fetch<
    RequestResponse,
    StrictOmit<RequestParameter, 'userId'>,
    RequestBody
  >({
    url: `user/updateUserAccountInformation/${userId}`,
    method: 'PUT',
    body,
  });

export { updateUserAccountInformation };
