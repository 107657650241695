import { Fetch } from '@/lib';
import type { BrandSummary } from '@/types';

type RequestResponse = {
  total: number;
  data: BrandSummary[];
};

type RequestParameter = {
  searchKey: string;
  pageSize: number;
  pageNumber: number;
};

const searchBrands = async (params: RequestParameter) =>
  await Fetch<RequestResponse, RequestParameter>({
    url: 'brand/searchBrands',
    params,
  });

export { searchBrands };
