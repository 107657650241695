import { Fetch } from '@/lib';
import type { Cart } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    CART: {
      BASE: BASE_CART,
      PRODUCTS: { BASE: BASE_CART_PRODUCTS },
    },
  },
} = TAG;

type RequestResponse = Pick<Cart, 'id' | 'cartProducts'>;

const getCartProducts = async () =>
  await Fetch<RequestResponse>({
    url: 'cart/getCartProducts',
    next: {
      tags: [BASE_CART, BASE_CART_PRODUCTS],
    },
  });

export { getCartProducts };
