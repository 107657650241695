import { useCallback, useRef, useState } from 'react';

type MutationOption<
  T extends (...args: any) => Promise<any>,
  U extends Error = Error,
  V = unknown,
> = {
  retry?: number;
  retryDelay?: number;
  mutationFn: T;
  onMutate?: (...variables: Parameters<T>) => Promise<V> | V;
  onSuccess?: (
    data: Awaited<ReturnType<T>>,
    context: V,
    ...variables: Parameters<T>
  ) => Promise<unknown> | unknown;
  onError?: (
    error: U,
    context: V,
    ...variables: Parameters<T>
  ) => Promise<unknown> | unknown;
  onSettled?: (
    data: Awaited<ReturnType<T>> | undefined,
    error: U | undefined,
    context: V,
    ...variables: Parameters<T>
  ) => Promise<unknown> | unknown;
};

type MutationResult<
  T extends (...args: any) => Promise<any>,
  U extends Error = Error,
  V = unknown,
> = {
  isPending: boolean;
  isSuccess: boolean;
  isError: boolean;
  data?: Awaited<ReturnType<T>>;
  error?: U;
  variables?: Parameters<T>;
  failureCount: number;
  submittedAt?: number;
  mutate: (...variables: Parameters<T>) => void;
  mutateAsync: (...variables: Parameters<T>) => Promise<T>;
  reset: () => void;
};

const useMutation = <
  T extends (...args: any) => Promise<any>,
  U extends Error = Error,
  V = unknown,
>({
  retry = 0,
  retryDelay = 1000,
  mutationFn,
  onMutate,
  onSuccess,
  onError,
  onSettled,
}: MutationOption<T, U, V>): MutationResult<T, U, V> => {
  const [isPending, setPending] = useState(false);
  const [data, setData] = useState<Awaited<ReturnType<T>>>();
  const [error, setError] = useState<U>();
  const [variables, setVariables] = useState<Parameters<T>>();
  const [failureCount, setFailureCount] = useState(0);
  const [submittedAt, setSubmittedAt] = useState<number>();

  const contextRef = useRef<V>();
  const retryCountRef = useRef(0);

  const executeMutation = useCallback<MutationResult<T, U, V>['mutateAsync']>(
    async (...variables): Promise<T> => {
      setPending(true);
      setError(undefined);
      setVariables(variables);
      retryCountRef.current = 0;

      try {
        if (onMutate) {
          contextRef.current = await onMutate(...variables);
        }

        const result = await mutationFn(...variables);
        setData(result);
        setSubmittedAt(Date.now());

        await onSuccess?.(result, contextRef.current as V, ...variables);
        await onSettled?.(result, error, contextRef.current as V, ...variables);

        return result;
      } catch (error) {
        if (retryCountRef.current < retry) {
          retryCountRef.current++;
          setFailureCount(prev => prev + 1);

          await new Promise(resolve =>
            setTimeout(
              resolve,
              retryDelay * Math.pow(2, retryCountRef.current),
            ),
          );
          return executeMutation(...variables);
        }

        setError(error as U);
        setFailureCount(prev => prev + 1);

        await onError?.(error as U, contextRef.current as V, ...variables);
        await onSettled?.(
          undefined,
          error as U,
          contextRef.current as V,
          ...variables,
        );

        return Promise.reject(error);
      } finally {
        setPending(false);
      }
    },
    [
      error,
      mutationFn,
      onError,
      onMutate,
      onSettled,
      onSuccess,
      retry,
      retryDelay,
    ],
  );

  const mutate = useCallback<MutationResult<T, U, V>['mutate']>(
    (...variables) => {
      executeMutation(...variables);
    },
    [executeMutation],
  );

  const mutateAsync = useCallback<MutationResult<T, U, V>['mutateAsync']>(
    (...variables) => executeMutation(...variables),
    [executeMutation],
  );

  const reset = useCallback(() => {
    setPending(false);
    setData(undefined);
    setError(undefined);
    setVariables(undefined);
    setFailureCount(0);
    setSubmittedAt(undefined);
    contextRef.current = undefined;
    retryCountRef.current = 0;
  }, []);

  return {
    isPending,
    isSuccess: !!data && !error,
    isError: !!error,
    data,
    error,
    variables,
    failureCount,
    submittedAt,
    mutate,
    mutateAsync,
    reset,
  };
};

export { useMutation };
