import { Fetch } from '@/lib';
import type { Cart } from '@/types';

type RequestResponse = Pick<Cart, 'cartProducts'>;

type RequestParameter = {};

type RequestBody = Record<'subProductId' | 'packagingInfoIdentifier', string> &
  Record<'size', number>;

const addToCart = async (body: RequestBody) =>
  await Fetch<RequestResponse, RequestParameter, RequestBody>({
    url: 'cart/addToCart',
    method: 'POST',
    body,
  });

export { addToCart };
