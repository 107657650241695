import { Fetch } from '@/lib';
import { addToCart, getCartProducts } from '@/services';

type RequestResponse = Awaited<ReturnType<typeof getCartProducts>>;

type RequestParameters = {};

type RequestBody = {
  cartProducts: Array<Parameters<typeof addToCart>[number]>;
};

const initializeCart = async (body: RequestBody) =>
  await Fetch<RequestResponse, RequestParameters, RequestBody>({
    url: 'cart/initializeCart',
    method: 'POST',
    body,
  });

export { initializeCart };
