import { Fetch } from '@/lib';
import type { PaymentGateway } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    CART: {
      PAYMENT_GATEWAYS: { BASE: BASE_CART_PAYMENT_GATEWAYS },
    },
  },
} = TAG;

type RequestResponse = {
  paymentGateways: PaymentGateway[];
};

type RequestParameter = {
  cartId: string;
};

const getAvailablePaymentGateways = async (params: RequestParameter) =>
  await Fetch<RequestResponse, RequestParameter>({
    url: 'cart/getAvailablePaymentGateways',
    params,
    next: {
      tags: [BASE_CART_PAYMENT_GATEWAYS],
    },
  });

export { getAvailablePaymentGateways };
