'use client';

import * as React from 'react';

import * as ProgressPrimitive from '@radix-ui/react-progress';

import { cn } from '@/utils';

type ProgressContextProps = Pick<
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>,
  'value'
>;

const ProgressContext = React.createContext<ProgressContextProps>({});

const useProgress = () => {
  const context = React.useContext(ProgressContext);

  if (!context) {
    throw new Error('useProgress must be used within a <Progress />');
  }

  return context;
};

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
>(({ value, className, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={cn(
      'relative h-4 w-full overflow-hidden rounded-full bg-secondary',
      className,
    )}
    {...props}
  />
));
Progress.displayName = ProgressPrimitive.Root.displayName;

const ProgressIndicator = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Indicator>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Indicator>
>(({ className, style, ...props }, ref) => {
  const { value } = useProgress();

  return (
    <ProgressPrimitive.Indicator
      ref={ref}
      className={cn(
        'size-full flex-1 bg-primary transition-all duration-2000',
        className,
      )}
      style={{ transform: `translateX(${-100 - (value || 0)}%)`, ...style }}
      {...props}
    />
  );
});
ProgressIndicator.displayName = ProgressPrimitive.Indicator.displayName;

export { Progress, ProgressIndicator };
export { useProgress };
