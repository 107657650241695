import { Fetch } from '@/lib';

type RequestResponse = {};

type RequestParameter = {
  id: string;
};

const deleteRecipientAddressInfo = async (params: RequestParameter) =>
  await Fetch<RequestResponse, RequestParameter>({
    url: 'user/deleteRecipientAddressInfo',
    method: 'DELETE',
    params,
  });

export { deleteRecipientAddressInfo };
