import { Fetch } from '@/lib';
import type { CategorySummary, MagazinePost } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    PAGE_BUILDER: {
      PAGE_FOOTER_INFO: { BASE: BASE_PAGE_FOOTER_INFO },
    },
  },
} = TAG;

type RequestResponse = Record<
  'categories' | 'bestSellerCategories',
  CategorySummary[]
> &
  Record<'posts', MagazinePost[]>;

const getFooterInfo = async () =>
  await Fetch<RequestResponse>({
    url: 'pageBuilder/getFooterInfo',
    next: {
      tags: [BASE_PAGE_FOOTER_INFO],
    },
  });

export { getFooterInfo };
