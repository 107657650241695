const STORAGE = {
  COOKIE: {
    DEFAULT: {
      DOMAIN: {
        BASE:
          process.env.NODE_ENV === 'production' ? '.kashiland.com' : undefined,
      },
    },
    IDENTITY: {
      BASE: 'identity',
      ACCESS_TOKEN: 'identity:access-token',
      REFRESH_TOKEN: 'identity:refresh-token',
      EXPIRE_AT: 'identity:expire-at',
      TOKEN_TYPE: 'identity:token-type',
    },
  },
  LOCAL: {
    DEFAULT: {
      LOGIN: {
        BASE: 'login',
        OTP: {
          EXPIRATION_TIME: 2.5 * 60,
        },
        TERMS: {
          AGREEMENT:
            'با ورود و یا ثبت نام در فروشگاه کاشی لند، شما شرایط و قوانین استفاده از تمام سرویس های سایت کاشی لند و قوانین حریم خصوصی آن را می‌پذیرید.',
        },
      },
    },
    SEARCH: {
      BASE: 'search',
      HISTORY: 'search:history',
    },
    PRODUCTS: {
      BASE: 'products',
      LAST_VISITED: 'products:last-visited',
    },
    CART: {
      BASE: 'cart',
      PRODUCTS: 'cart:products',
      INVOICE: 'cart:invoice',
    },
  },
  SESSION: {
    DEFAULT: {},
  },
  HEADERS: {
    DEFAULT: {},
    URL: {
      BASE: 'url',
      PATHNAME: 'pathname',
    },
  },
} as const;

export { STORAGE };
