'use client';

import * as React from 'react';

import Image from 'next/image';

import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

import {
  Badge,
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  HeartIcon,
  LocaleLink,
  Skeleton,
  SpinnerIcon,
} from '@/components';
import { toggleFavoriteProduct } from '@/services';
import type { CartProduct, Product, ProductSummary } from '@/types';
import {
  cn,
  discountPercentage,
  generateFileUrl,
  numberFormat,
  PATHNAME,
  UNIT,
} from '@/utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    PRODUCT: { BASE_URL: PRODUCT_BASE_URL },
  },
} = PATHNAME;
const { PRICE } = UNIT;

type ProductCardContextProps = ProductSummary &
  Partial<
    Pick<CartProduct, 'size'> &
      Pick<Product, 'isFavorite'> &
      Record<'isDiscounted', boolean>
  >;

const ProductCardContext = React.createContext<ProductCardContextProps>(
  {} as ProductCardContextProps,
);

const useProductCard = () => {
  const context = React.useContext(ProductCardContext);

  if (!context) {
    throw new Error('useProductCard must be used within a <ProductCard />');
  }

  return context;
};

type ProductCardProps = React.ComponentPropsWithoutRef<typeof Card> &
  ProductCardContextProps;

const ProductCard = React.forwardRef<
  React.ElementRef<typeof Card>,
  ProductCardProps
>(
  (
    {
      id,
      slug,
      name,
      status,
      bannerId,
      isAvailable,
      publishTime,
      brand,
      mainCategory,
      mainSubProduct,
      size,
      isFavorite,
      isDiscounted,
      className,
      children,
      ...props
    },
    ref,
  ) => {
    const { originalPrice, price = originalPrice } = mainSubProduct;

    return (
      <ProductCardContext.Provider
        value={{
          id,
          slug,
          name,
          status,
          bannerId,
          isAvailable,
          publishTime,
          brand,
          mainCategory,
          mainSubProduct,
          size,
          isFavorite,
          isDiscounted: price < originalPrice,
        }}
      >
        <LocaleLink
          href={`${PRODUCT_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`}
          className='block h-full'
        >
          <Card
            ref={ref}
            className={cn('relative', !isAvailable && 'grayscale', className)}
            {...props}
          >
            {children}
          </Card>
        </LocaleLink>
      </ProductCardContext.Provider>
    );
  },
);
ProductCard.displayName = 'ProductCard';

const ProductCardHeader = React.forwardRef<
  React.ElementRef<typeof CardHeader>,
  React.ComponentPropsWithoutRef<typeof CardHeader>
>(({ className, children, ...props }, ref) => {
  const { name, bannerId } = useProductCard();

  const { width, height } = {
    width: 300,
    height: 320,
  };

  return (
    <CardHeader ref={ref} className={cn('relative h-52', className)} {...props}>
      <Image
        className='size-full object-contain'
        src={generateFileUrl('Product', bannerId, width, height)}
        width={width}
        height={height}
        alt={name}
      />

      {children}
    </CardHeader>
  );
});
ProductCardHeader.displayName = 'ProductCardHeader';

const ProductCardHeaderDiscount = React.forwardRef<
  React.ElementRef<typeof Badge>,
  React.ComponentPropsWithoutRef<typeof Badge>
>(({ variant = 'danger', className, ...props }, ref) => {
  const {
    isDiscounted,
    mainSubProduct: { originalPrice, price = originalPrice },
  } = useProductCard();

  if (isDiscounted) {
    return (
      <Badge
        ref={ref}
        variant={variant}
        className={cn('absolute m-px rounded', className)}
        {...props}
      >
        {discountPercentage(originalPrice, price)}
        <span className='sr-only'>درصد تخفیف</span>
      </Badge>
    );
  }
});
ProductCardHeaderDiscount.displayName = 'ProductCardHeaderDiscount';

type ProductCardHeaderFavoriteProps = React.ComponentPropsWithoutRef<
  typeof Button
> &
  Partial<Record<'onAction', () => void>>;

const ProductCardHeaderFavorite = React.forwardRef<
  React.ElementRef<typeof Button>,
  ProductCardHeaderFavoriteProps
>(
  (
    {
      variant = 'ghost',
      size = 'icon',
      className,
      disabled,
      onAction,
      onClick,
      ...props
    },
    ref,
  ) => {
    const { id, isFavorite } = useProductCard();
    const { isPending, mutate } = useMutation({
      mutationFn: toggleFavoriteProduct,
      onSuccess: () => {
        onAction?.();
      },
      onError: ({ message }) => {
        toast.error(message);
      },
    });

    if (isFavorite) {
      return (
        <Button
          ref={ref}
          variant={variant}
          size={size}
          className={cn('absolute -top-2.5 -left-2.5', className)}
          disabled={disabled || isPending}
          onClick={event => {
            event.preventDefault();
            mutate({ productId: id });
            onClick?.(event);
          }}
          {...props}
        >
          {isPending ? (
            <SpinnerIcon className='animate-spin' />
          ) : (
            <HeartIcon weight='fill' className='text-danger' />
          )}
          <span className='sr-only'>آیکون محبوبیت</span>
        </Button>
      );
    }
  },
);
ProductCardHeaderFavorite.displayName = 'ProductCardHeaderFavorite';

const ProductCardHeaderSize = React.forwardRef<
  React.ElementRef<typeof Badge>,
  React.ComponentPropsWithoutRef<typeof Badge>
>(({ size = 'full', className, ...props }, ref) => {
  const { size: productSize = 0 } = useProductCard();

  return productSize > 0 ? (
    <Badge
      ref={ref}
      size={size}
      className={cn('absolute bottom-0 left-0 m-px p-3.5', className)}
      {...props}
    >
      {numberFormat(productSize)}
      <span className='sr-only'>تعداد سفارش</span>
    </Badge>
  ) : null;
});
ProductCardHeaderSize.displayName = 'ProductCardHeaderSize';

const ProductCardContent = React.forwardRef<
  React.ElementRef<typeof CardContent>,
  React.ComponentPropsWithoutRef<typeof CardContent>
>((props, ref) => {
  const { name } = useProductCard();

  return (
    <CardContent ref={ref} {...props}>
      <CardTitle>{name}</CardTitle>
    </CardContent>
  );
});
ProductCardContent.displayName = 'ProductCardContent';

const ProductCardFooter = React.forwardRef<
  React.ElementRef<typeof CardFooter>,
  React.ComponentPropsWithoutRef<typeof CardFooter>
>(({ className, ...props }, ref) => {
  const {
    isAvailable,
    isDiscounted,
    mainSubProduct: { originalPrice = 0, price = 0 },
  } = useProductCard();

  return (
    <CardFooter ref={ref} className={cn('justify-end', className)} {...props}>
      <CardDescription className='flex items-center gap-px'>
        {isAvailable ? (
          <>
            {isDiscounted ? (
              <span className='font-medium line-through'>
                {numberFormat(originalPrice)}
              </span>
            ) : null}

            <span
              className={cn(
                'text-lg font-bold',
                isDiscounted ? 'text-danger' : 'text-primary',
              )}
            >
              {numberFormat(price)}
            </span>

            <span
              className={cn(
                'font-normal',
                isDiscounted ? 'text-danger' : 'text-primary',
              )}
            >
              {PRICE}
            </span>
            <span className='sr-only'>قیمت محصول</span>
          </>
        ) : (
          <span className='font-normal'>ناموجود</span>
        )}
      </CardDescription>
    </CardFooter>
  );
});
ProductCardFooter.displayName = 'ProductCardFooter';

const ProductCardPlaceholder = React.forwardRef<
  React.ElementRef<typeof Card>,
  React.ComponentPropsWithoutRef<typeof Card>
>(({ className, ...props }, ref) => (
  <Card ref={ref} className={cn('gap-2', className)} {...props} />
));
ProductCardPlaceholder.displayName = 'ProductCardPlaceholder';

const ProductCardHeaderPlaceholder = React.forwardRef<
  React.ElementRef<typeof CardHeader>,
  React.ComponentPropsWithoutRef<typeof CardHeader>
>(({ className, ...props }, ref) => (
  <CardHeader ref={ref} className={cn('h-52', className)} {...props}>
    <Skeleton className='size-full' />
  </CardHeader>
));
ProductCardHeaderPlaceholder.displayName = 'ProductCardHeaderPlaceholder';

const ProductCardContentPlaceholder = React.forwardRef<
  React.ElementRef<typeof CardContent>,
  React.ComponentPropsWithoutRef<typeof CardContent>
>(({ className, ...props }, ref) => (
  <CardContent ref={ref} className={cn('flex-col gap-2', className)} {...props}>
    <Skeleton className='h-2 w-full' />
    <Skeleton className='h-2 w-2/3' />
  </CardContent>
));
ProductCardContentPlaceholder.displayName = 'ProductCardContentPlaceholder';

const ProductCardFooterPlaceholder = React.forwardRef<
  React.ElementRef<typeof CardFooter>,
  React.ComponentPropsWithoutRef<typeof CardFooter>
>(({ className, ...props }, ref) => (
  <CardFooter ref={ref} className={cn('justify-end', className)} {...props}>
    <Skeleton className='h-2 w-1/3' />
  </CardFooter>
));
ProductCardFooterPlaceholder.displayName = 'ProductCardFooterPlaceholder';

export {
  ProductCard,
  ProductCardContent,
  ProductCardContentPlaceholder,
  ProductCardFooter,
  ProductCardFooterPlaceholder,
  ProductCardHeader,
  ProductCardHeaderDiscount,
  ProductCardHeaderFavorite,
  ProductCardHeaderPlaceholder,
  ProductCardHeaderSize,
  ProductCardPlaceholder,
};
export { useProductCard };
