'use client';

import * as React from 'react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Alert,
  AlertDescription,
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  DotIcon,
  ExternalLink,
  ProductCard,
  ProductCardContent,
  ProductCardFooter,
  ProductCardHeader,
  ProductCardHeaderDiscount,
  ProductCardHeaderSize,
  ReceiptIcon,
  Separator,
  TypographyMuted,
  TypographySmall,
  WarningCircleIcon,
} from '@/components';
import type { Purchase } from '@/types';
import {
  cn,
  dateTimeFormat,
  generateInvoiceFileUrl,
  localizePurchaseStatus,
  numberFormat,
  UNIT,
} from '@/utils';

const { PRICE } = UNIT;

type PurchaseCardContextProps = Purchase;

const PurchaseCardContext = React.createContext<PurchaseCardContextProps>(
  {} as PurchaseCardContextProps,
);

const usePurchaseCard = () => {
  const context = React.useContext(PurchaseCardContext);

  if (!context) {
    throw new Error('usePurchaseCard must be used within a <PurchaseCard />');
  }

  return context;
};

type PurchaseCardProps = React.ComponentPropsWithoutRef<typeof Card> &
  PurchaseCardContextProps;

const PurchaseCard = React.forwardRef<
  React.ElementRef<typeof Card>,
  PurchaseCardProps
>(
  (
    {
      id,
      trackingCode,
      user,
      purchaseProducts,
      discountCode,
      paperInvoice,
      description,
      recipientAddressInfo,
      status,
      totalPrice,
      createdDate,
      lastModifiedDate,
      className,
      children,
      ...props
    },
    ref,
  ) => (
    <PurchaseCardContext.Provider
      value={{
        id,
        trackingCode,
        user,
        purchaseProducts,
        discountCode,
        paperInvoice,
        description,
        recipientAddressInfo,
        status,
        totalPrice,
        createdDate,
        lastModifiedDate,
      }}
    >
      <Card ref={ref} className={cn('px-0', className)} {...props}>
        {children}
      </Card>
    </PurchaseCardContext.Provider>
  ),
);
PurchaseCard.displayName = 'PurchaseCard';

const PurchaseCardHeader = React.forwardRef<
  React.ElementRef<typeof CardHeader>,
  React.ComponentPropsWithoutRef<typeof CardHeader>
>(({ className, ...props }, ref) => {
  const { id, status } = usePurchaseCard();
  const { label, color } = localizePurchaseStatus(status);

  return (
    <CardHeader
      ref={ref}
      className={cn(
        'items-start justify-between gap-y-4 px-8 py-3 sm:flex-row sm:items-center',
        className,
      )}
      {...props}
    >
      <div className='flex items-center gap-2'>
        <TypographyMuted>وضعیت سفارش:</TypographyMuted>

        <CardTitle className='text-base' style={{ color }}>
          {label}
        </CardTitle>
      </div>

      <Button asChild variant='link' className='h-auto gap-1 p-0'>
        <ExternalLink href={generateInvoiceFileUrl(id)}>
          <ReceiptIcon />
          مشاهده و دانلود فاکتور
        </ExternalLink>
      </Button>
    </CardHeader>
  );
});
PurchaseCardHeader.displayName = 'PurchaseCardHeader';

const PurchaseCardContent = React.forwardRef<
  React.ElementRef<typeof CardContent>,
  React.ComponentPropsWithoutRef<typeof CardContent>
>(({ className, ...props }, ref) => {
  const {
    createdDate,
    trackingCode,
    totalPrice,
    recipientAddressInfo: { firstName, lastName, phoneNumber, address },
  } = usePurchaseCard();

  return (
    <CardContent
      ref={ref}
      className={cn('mx-8 my-2 gap-5', className)}
      {...props}
    >
      <div className='flex flex-row flex-wrap gap-x-1 gap-y-4 border-b border-dashed pb-2'>
        <div className='flex flex-wrap items-center gap-1'>
          <TypographyMuted>تاریخ سفارش:</TypographyMuted>
          <TypographySmall className='font-bold'>
            {dateTimeFormat(new Date(createdDate))}
          </TypographySmall>
        </div>
        <DotIcon className='text-slate-200' />

        <div className='flex flex-wrap items-center gap-1'>
          <TypographyMuted>کد سفارش:</TypographyMuted>
          <TypographySmall className='font-bold'>
            {trackingCode}
          </TypographySmall>
        </div>
        <DotIcon className='text-slate-200' />

        <div className='flex flex-wrap items-center gap-1'>
          <TypographyMuted>مبلغ سفارش:</TypographyMuted>
          <TypographySmall className='font-bold'>
            {numberFormat(totalPrice, undefined, ` ${PRICE}`)}
          </TypographySmall>
        </div>
      </div>

      <div className='flex flex-row flex-wrap gap-x-1 gap-y-4 border-b border-dashed pb-2'>
        <div className='flex flex-wrap items-center gap-1'>
          <TypographyMuted>نام و نام‌خانوادگی گیرنده:</TypographyMuted>
          <TypographySmall className='font-bold'>
            {`${firstName} ${lastName}`}
          </TypographySmall>
        </div>
        <DotIcon className='text-slate-200' />

        <div className='flex flex-wrap items-center gap-1'>
          <TypographyMuted>تلفن همراه گیرنده:</TypographyMuted>
          <TypographySmall className='font-bold'>{phoneNumber}</TypographySmall>
        </div>
        <DotIcon className='text-slate-200' />

        <div className='flex flex-wrap items-center gap-1'>
          <TypographyMuted>آدرس سفارش:</TypographyMuted>
          <TypographySmall className='font-bold'>
            {address.content}
          </TypographySmall>
        </div>
      </div>
    </CardContent>
  );
});
PurchaseCardContent.displayName = 'PurchaseCardContent';

const PurchaseCardFooter = React.forwardRef<
  React.ElementRef<typeof CardFooter>,
  React.ComponentPropsWithoutRef<typeof CardFooter>
>(({ className, ...props }, ref) => {
  const { purchaseProducts } = usePurchaseCard();

  return (
    <CardFooter
      ref={ref}
      className={cn('mx-8 mb-4 mt-2 flex-col items-stretch gap-y-6', className)}
      {...props}
    >
      <Accordion type='single' className='w-full' collapsible>
        <AccordionItem value='purchaseProducts'>
          <AccordionTrigger className='text-base'>
            محصولات خریداری شده
          </AccordionTrigger>

          <AccordionContent className='space-y-4'>
            <Alert variant='muted'>
              <WarningCircleIcon />
              <AlertDescription>
                فاکتور کاغذی سفارش، همراه مرسوله برای شما ارسال خواهد شد.
              </AlertDescription>
            </Alert>

            <div className='grid gap-4 sm:grid-cols-2 lg:grid-cols-4'>
              {purchaseProducts.map(
                ({
                  product: { id, mainSubProduct, ...otherProduct },
                  subProduct,
                  size,
                }) => (
                  <ProductCard
                    key={id}
                    className='basis-60'
                    id={id}
                    size={size}
                    mainSubProduct={subProduct}
                    {...otherProduct}
                  >
                    <ProductCardHeader>
                      <ProductCardHeaderDiscount />
                      <ProductCardHeaderSize />
                    </ProductCardHeader>
                    <ProductCardContent />
                    <ProductCardFooter />
                  </ProductCard>
                ),
              )}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </CardFooter>
  );
});
PurchaseCardFooter.displayName = 'PurchaseCardFooter';

const PurchaseCardSeparator = React.forwardRef<
  React.ElementRef<typeof CardContent>,
  React.ComponentPropsWithoutRef<typeof CardContent>
>((props, ref) => (
  <CardContent ref={ref} {...props}>
    <Separator />
  </CardContent>
));
PurchaseCardSeparator.displayName = 'PurchaseCardSeparator';

export {
  PurchaseCard,
  PurchaseCardContent,
  PurchaseCardFooter,
  PurchaseCardHeader,
  PurchaseCardSeparator,
};
export { usePurchaseCard };
