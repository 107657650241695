const TAG = {
  SERVICE: {
    BRAND: {
      BASE: 'brand',
      GLOBAL: 'brands',
      BEST: {
        BASE: 'best-brand',
        GLOBAL: 'best-brands',
      },
      RELATED_TO_CATEGORY: {
        BASE: 'related-brand-to-category',
        GLOBAL: 'related-brands-to-category',
      },
      PRODUCT: {
        BASE: 'product-brand',
        GLOBAL: 'product-brands',
      },
    },
    CART: {
      BASE: 'cart',
      PRODUCTS: { BASE: 'cart-products' },
      INVOICE_INFO: { BASE: 'cart-invoice-info' },
      PAYMENT_GATEWAYS: { BASE: 'cart-payment-gateways' },
    },
    CATEGORY: {
      BASE: 'category',
      GLOBAL: 'categories',
      RELATED: {
        GLOBAL: 'related-categories',
      },
    },
    CITY: {
      BASE: 'city',
      GLOBAL: 'cities',
    },
    COMMENT: {
      BASE: 'comment',
      GLOBAL: 'comments',
      PRODUCT_GLOBAL: { BASE: 'product-comments' },
    },
    IDENTITY: {
      BASE: 'identity',
      AUTH: { BASE: 'identity-auth' },
      SESSION: {
        BASE: 'identity-session',
        GLOBAL: 'identity-sessions',
      },
    },
    PAGE_BUILDER: {
      BASE: 'page-builder',
      PAGE_BANNERS: {
        BASE: 'page-banners',
        FIRST: 'first-page-banners',
        SECOND: 'second-page-banners',
        THIRD: 'third-page-banners',
        FOURTH: 'fourth-page-banners',
      },
      PAGE_CATEGORY_PRODUCTS: {
        BASE: 'page-category-products',
        FIRST: 'first-category-products',
        SECOND: 'second-category-products',
        THIRD: 'third-category-products',
      },
      PAGE_FOOTER_INFO: { BASE: 'page-footer-info' },
    },
    PRODUCT: {
      BASE: 'product',
      GLOBAL: 'products',
      SEO_INFO: { BASE: 'product-seo-info' },
      RELATED_GLOBAL: {
        BASE: 'related-products',
        TO_CART: 'related-products-to-cart',
      },
      BRIEF_SEARCH_GLOBAL: {
        BASE: 'brief-search-products',
      },
      NEW_GLOBAL: {
        BASE: 'new-products',
      },
    },
    SPECIAL_OFFER: {
      BASE: 'special-offer',
    },
    USER: {
      BASE: 'user',
      RECIPIENT_ADDRESS_INFOS: {
        BASE: 'recipient-address-infos',
      },
      ACCOUNT_INFORMATION: {
        BASE: 'user-account-information',
      },
      FAVORITE_PRODUCTS: {
        BASE: 'user-favorite-products',
      },
      NOTIFICATIONS: {
        BASE: 'user-notifications',
      },
      DASHBOARD: {
        BASE: 'user-dashboard',
      },
      PURCHASE: {
        BASE: 'user-purchase',
      },
    },
    TOROB: {
      BASE: 'torob',
      PRODUCT_METADATA: {
        BASE: 'torob-product-metadata',
      },
    },
    SEO: {
      BASE: 'seo',
      SITE_MAP: {
        BASE: 'seo-site-map',
        CATEGORIES: 'categories-seo-site-map',
        PRODUCTS: 'products-seo-site-map',
        COUNT: 'seo-site-map-count',
      },
    },
    MAGAZINE: {
      BASE: 'magazine',
      POST: {
        BASE: 'magazine-post',
        GLOBAL: 'magazine-posts',
      },
      LAST_ARTICLE: {
        BASE: 'magazine-last-article',
        GLOBAL: 'magazine-last-articles',
      },
    },
  },
  METADATA: {
    GOOGLE_SITE_VERIFICATION: {
      BASE: 'google-site-verification',
    },
  },
} as const;

export { TAG };
