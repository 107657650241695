import { Fetch } from '@/lib';
import type { PurchaseReport, UserInfo } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    USER: {
      DASHBOARD: { BASE: BASE_USER_DASHBOARD },
    },
  },
} = TAG;

type RequestResponse = {
  purchaseReport: PurchaseReport;
  userInfo: UserInfo;
};

const getUserDashboard = async () =>
  await Fetch<RequestResponse>({
    url: 'user/getUserDashboard',
    next: {
      tags: [BASE_USER_DASHBOARD],
    },
  });

export { getUserDashboard };
