import { Fetch } from '@/lib';
import type { ProductsBriefResult } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    PRODUCT: {
      BRIEF_SEARCH_GLOBAL: { BASE: BASE_BRIEF_SEARCH_GLOBAL_PRODUCT },
    },
  },
} = TAG;

type RequestResponse = ProductsBriefResult;

type RequestParameter = {
  searchKey: string;
};

const briefSearchProducts = async (params: RequestParameter) =>
  await Fetch<RequestResponse, RequestParameter>({
    url: 'product/briefSearchProducts',
    params,
    next: {
      tags: [BASE_BRIEF_SEARCH_GLOBAL_PRODUCT],
    },
  });

export { briefSearchProducts };
