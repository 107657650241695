'use client';

import * as React from 'react';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  Empty,
  ProductCard,
  ProductCardContent,
  ProductCardContentPlaceholder,
  ProductCardFooter,
  ProductCardFooterPlaceholder,
  ProductCardHeader,
  ProductCardHeaderDiscount,
  ProductCardHeaderPlaceholder,
  ProductCardPlaceholder,
  ScrollArea,
} from '@/components';
import type { ProductSummary } from '@/types';
import { cn, randomId } from '@/utils';

type ProductViewContextProps = Record<'products', ProductSummary[]> &
  Partial<Record<'type', 'grid' | 'slider'>>;

const ProductViewContext = React.createContext<ProductViewContextProps>(
  {} as ProductViewContextProps,
);

const useProductView = () => {
  const context = React.useContext(ProductViewContext);

  if (!context) {
    throw new Error('useProductView must be used within a <ProductView />');
  }

  return context;
};

type ProductViewProps = React.ComponentPropsWithoutRef<'div'> &
  ProductViewContextProps;

const ProductView = React.forwardRef<React.ElementRef<'div'>, ProductViewProps>(
  ({ products, type = 'slider', children, className, ...props }, ref) => {
    const isEmpty = products.length === 0;

    const grid = (
      <div className='container grid grid-cols-5 place-content-center gap-4 max-lg:hidden'>
        {products.map(product => {
          const { id } = product;
          return (
            <ProductCard key={id} className='h-96 flex-1 shrink-0' {...product}>
              <ProductCardHeader>
                <ProductCardHeaderDiscount />
              </ProductCardHeader>
              <ProductCardContent />
              <ProductCardFooter />
            </ProductCard>
          );
        })}
      </div>
    );

    const slider = (
      <div className='container'>
        <Carousel className='place-items-center gap-4 max-lg:hidden'>
          <CarouselContent>
            {products.map(product => (
              <CarouselItem
                key={product.id}
                className='lg:basis-1/4 xl:basis-1/5'
              >
                <ProductCard className='h-96 flex-1' {...product}>
                  <ProductCardHeader>
                    <ProductCardHeaderDiscount />
                  </ProductCardHeader>
                  <ProductCardContent />
                  <ProductCardFooter />
                </ProductCard>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      </div>
    );

    const scroll = (
      <ScrollArea type='scroll' orientation='horizontal' className='lg:hidden'>
        <div className='container flex gap-4'>
          {products.map(product => (
            <ProductCard
              key={product.id}
              className='h-96 w-60 shrink-0'
              {...product}
            >
              <ProductCardHeader>
                <ProductCardHeaderDiscount />
              </ProductCardHeader>
              <ProductCardContent />
              <ProductCardFooter />
            </ProductCard>
          ))}
        </div>
      </ScrollArea>
    );

    return (
      <ProductViewContext.Provider value={{ products, type }}>
        <div ref={ref} className={className} {...props}>
          {!isEmpty ? (
            <>
              {type === 'grid' ? grid : slider}
              {scroll}
            </>
          ) : (
            <div className='container'>
              <Empty />
            </div>
          )}
        </div>
      </ProductViewContext.Provider>
    );
  },
);
ProductView.displayName = 'ProductView';

type ProductViewPlaceholderProps = React.ComponentPropsWithoutRef<'div'> &
  Partial<Record<'type', 'slider' | 'grid'>>;

const ProductViewPlaceholder = React.forwardRef<
  React.ElementRef<'div'>,
  ProductViewPlaceholderProps
>(({ type = 'slider', className, ...props }, ref) => {
  const cards = Array.from(
    { length: type === 'grid' ? 10 : 5 },
    (_v, i) => i,
  ).map(() => {
    const id = randomId();

    return (
      <ProductCardPlaceholder key={id} className='h-96 min-w-60 flex-1'>
        <ProductCardHeaderPlaceholder />
        <ProductCardContentPlaceholder />
        <ProductCardFooterPlaceholder />
      </ProductCardPlaceholder>
    );
  });

  return (
    <div
      ref={ref}
      className={cn(
        'container flex justify-between gap-4 overflow-hidden',
        type === 'grid' ? 'grid-cols-5 lg:grid' : '',
        className,
      )}
      {...props}
    >
      {cards}
    </div>
  );
});
ProductViewPlaceholder.displayName = 'ProductViewPlaceholder';

export { ProductView, ProductViewPlaceholder };
export { useProductView };
