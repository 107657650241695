'use client';

import * as React from 'react';

import { OTPInput } from 'input-otp';
import type { SlotProps } from 'input-otp';

import { DotsThreeIcon } from '@/components';
import { cn } from '@/utils';

const InputOTP = React.forwardRef<
  React.ElementRef<typeof OTPInput>,
  React.ComponentPropsWithoutRef<typeof OTPInput>
>(({ className, ...props }, ref) => (
  <OTPInput
    ref={ref}
    containerClassName={cn('flex items-center gap-2', className)}
    {...props}
  />
));
InputOTP.displayName = 'InputOTP';

const InputOTPGroup = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ dir = 'ltr', className, ...props }, ref) => (
  <div
    ref={ref}
    dir={dir}
    className={cn('flex w-full items-center gap-2', className)}
    {...props}
  />
));
InputOTPGroup.displayName = 'InputOTPGroup';

type InputOTPSlotProps = React.ComponentPropsWithoutRef<'div'> & SlotProps;

const InputOTPSlot = React.forwardRef<
  React.ElementRef<'div'>,
  InputOTPSlotProps
>(({ char, hasFakeCaret, isActive, className, ...props }, ref) => {
  const fakeCaret = hasFakeCaret ? (
    <div className='pointer-events-none absolute inset-0 flex items-center justify-center'>
      <div className='h-4 w-px animate-caret-blink bg-primary duration-1000' />
    </div>
  ) : null;

  return (
    <div
      ref={ref}
      className={cn(
        'relative flex h-10 basis-full items-center justify-center rounded-sm border border-border text-sm font-semibold transition-all',
        (hasFakeCaret || !!char) && 'border-primary',
        char && 'z-10 text-primary',
        className,
      )}
      {...props}
    >
      {char}
      {fakeCaret}
    </div>
  );
});
InputOTPSlot.displayName = 'InputOTPSlot';

const InputOTPSeparator = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ role = 'separator', ...props }, ref) => (
  <div ref={ref} role={role} {...props}>
    <DotsThreeIcon />
  </div>
));
InputOTPSeparator.displayName = 'InputOTPSeparator';

export { InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot };
