import { Fetch } from '@/lib';
import type { IdentityPayload } from '@/types';

type RequestResponse = {};

type RequestParameter = {};

type RequestBody = Pick<IdentityPayload, 'otp'>;

const confirmPhoneNumber = async (body: RequestBody) =>
  await Fetch<RequestResponse, RequestParameter, RequestBody>({
    url: 'identity/confirmPhoneNumber',
    method: 'POST',
    body,
  });

export { confirmPhoneNumber };
