import { Fetch } from '@/lib';
import type { MagazinePost } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    MAGAZINE: {
      POST: { GLOBAL: GLOBAL_MAGAZINE_POST },
    },
  },
} = TAG;

type RequestResponse = {
  posts: MagazinePost[];
};

type RequestParameter = {
  categorySlug?: string;
  size?: number;
};

const getPostsByCategory = async ({ categorySlug, size }: RequestParameter) => {
  const params: Record<string, unknown> = {};

  if (categorySlug) {
    params['categorySlug'] = categorySlug;
  }
  if (size) {
    params['size'] = size;
  }

  return await Fetch<RequestResponse, typeof params>({
    url: 'magazine/getPostsByCategory',
    params,
    next: {
      tags: [GLOBAL_MAGAZINE_POST],
    },
  });
};

export { getPostsByCategory };
