import Script from 'next/script';

import { Product as ProductSchemaDts, WithContext } from 'schema-dts';

import type { Product } from '@/types';
import { generateCanonical, generateFileUrl, PATHNAME } from '@/utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    HOME: { BASE_URL: HOME_BASE_URL },
    CATEGORY: { BASE_URL: CATEGORY_BASE_URL },
    PRODUCT: { BASE_URL: PRODUCT_BASE_URL },
    BRANDS: { BASE_URL: BRANDS_BASE_URL },
  },
} = PATHNAME;

type ProductSchemaProps = Product;

const ProductSchema = ({
  documents,
  id,
  identifier,
  name,
  otherCategories,
  pictures,
  productType,
  slug,
  baseAttributes,
  variableAttributes,
  brand,
  description,
  mainCategory,
}: ProductSchemaProps) => {
  const bannerId = pictures.at(0)?.id;

  const schema: WithContext<ProductSchemaDts> = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    '@id': generateCanonical(`/schema/Product/${id}`),

    sku: slug,
    name: name,
    mpn: identifier,
    description: description,
    url: generateCanonical(
      `${PRODUCT_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`,
    ),

    brand: brand
      ? {
          '@type': 'Brand',
          name: brand.title,
          url: generateCanonical(
            `${BRANDS_BASE_URL}${brand.slug}${DEFAULT_SLUG_SEPARATOR}`,
          ),
        }
      : undefined,

    category: [
      mainCategory ? mainCategory.title : '',
      ...otherCategories.map(cat => cat.title),
    ],

    additionalProperty: [
      ...variableAttributes.map(
        ({ name, values }) =>
          ({
            '@type': 'PropertyValue',
            name: name,
            value: values.join(', '),
          }) as const,
      ),
      ...baseAttributes.map(
        ({ name, selectedValues }) =>
          ({
            '@type': 'PropertyValue',
            name: name,
            value: selectedValues.join(', '),
          }) as const,
      ),
      ...documents.map(
        ({ id, name, type }) =>
          ({
            '@type': 'PropertyValue',
            name: name,
            url: generateFileUrl('Product', id),
            fileFormat: type,
          }) as const,
      ),
      {
        '@type': 'PropertyValue',
        name: 'Product Type',
        value: productType,
      },
    ],

    image: pictures
      .filter(({ type }) => type === 'Image' || type === 'CompressibleImage')
      .map(({ id }) => generateFileUrl('Product', id)),

    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': generateCanonical(
        `/schema/WebPage${PRODUCT_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`,
      ),
      url: generateCanonical(
        `${PRODUCT_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`,
      ),
      breadcrumb: {
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: 'کاشی‌لند',
            item: generateCanonical(HOME_BASE_URL),
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: mainCategory?.title,
            item: generateCanonical(
              `${CATEGORY_BASE_URL}${mainCategory?.slug}`,
            ),
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: name,
            item: generateCanonical(
              `${PRODUCT_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`,
            ),
          },
        ],
      },
    },
  };

  return (
    <Script
      id={id}
      type='application/ld+json'
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
};

export { ProductSchema };
