import { Fetch } from '@/lib';
import type { Notification } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    USER: {
      NOTIFICATIONS: { BASE: BASE_USER_NOTIFICATIONS },
    },
  },
} = TAG;

type RequestResponse = {
  notifications: Notification[];
};

type RequestParameter = Pick<Notification, 'seen'>;

const getNotifications = async (params: RequestParameter) =>
  await Fetch<RequestResponse, RequestParameter>({
    url: 'user/getNotifications',
    params,
    next: {
      tags: [BASE_USER_NOTIFICATIONS],
    },
  });

export { getNotifications };
