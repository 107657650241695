import { Fetch } from '@/lib';
import type { CategoryQueryResult } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    CATEGORY: { GLOBAL: GLOBAL_CATEGORY },
  },
} = TAG;

type RequestResponse = {
  categories: CategoryQueryResult[];
};

const getCategories = async () =>
  await Fetch<RequestResponse>({
    url: 'category/getCategories',
    next: { tags: [GLOBAL_CATEGORY] },
    cache: 'force-cache',
  });

export { getCategories };
