import { Fetch } from '@/lib';
import type { ProductSortType, ProductSummary } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    PRODUCT: { BASE: BASE_PRODUCT },
    USER: {
      FAVORITE_PRODUCTS: { BASE: BASE_USER_FAVORITE_PRODUCTS },
    },
  },
} = TAG;

type RequestResponse = {
  total: number;
  data: ProductSummary[];
};

type RequestParameter = {
  sortType: ProductSortType;
  searchKey: string;
  pageSize: number;
  pageNumber: number;
};

const searchFavoriteProducts = async (params: RequestParameter) =>
  await Fetch<RequestResponse, RequestParameter>({
    url: 'user/searchFavoriteProducts',
    params,
    next: {
      tags: [BASE_USER_FAVORITE_PRODUCTS, BASE_PRODUCT],
    },
  });

export { searchFavoriteProducts };
