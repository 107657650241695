'use client';

import * as React from 'react';

import { cva } from 'class-variance-authority';
import type { VariantProps } from 'class-variance-authority';

import { TypographyH5, TypographyMuted } from '@/components';
import { cn } from '@/utils';

const alertVariants = cva(
  'relative w-full rounded-md p-5 [&>svg+h5]:translate-y-[-5px] [&>svg]:absolute [&>svg]:right-5 [&>svg]:top-5 [&>svg]:text-inherit [&>svg~*]:ps-8',
  {
    variants: {
      variant: {
        default: 'border border-border bg-background text-foreground',
        secondary: 'bg-secondary/75 text-secondary-foreground',
        muted: 'bg-muted/75 text-muted-foreground',
        danger: 'bg-danger/75 text-danger-foreground',
        warning: 'bg-warning/75 text-warning-foreground',
        success: 'bg-success/75 text-success-foreground',
        foreground: 'bg-foreground/75 text-background',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

type AlertProps = React.ComponentPropsWithoutRef<'div'> &
  VariantProps<typeof alertVariants>;

const Alert = React.forwardRef<React.ElementRef<'div'>, AlertProps>(
  ({ role = 'alert', variant, className, ...props }, ref) => (
    <div
      ref={ref}
      role={role}
      className={cn(alertVariants({ variant, className }))}
      {...props}
    />
  ),
);
Alert.displayName = 'Alert';

const AlertTitle = React.forwardRef<
  React.ElementRef<typeof TypographyH5>,
  React.ComponentPropsWithoutRef<typeof TypographyH5>
>(({ className, ...props }, ref) => (
  <TypographyH5
    ref={ref}
    className={cn('mb-1 font-medium leading-none tracking-tight', className)}
    {...props}
  />
));
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef<
  React.ElementRef<typeof TypographyMuted>,
  React.ComponentPropsWithoutRef<typeof TypographyMuted>
>(({ className, ...props }, ref) => (
  <TypographyMuted
    ref={ref}
    className={cn('leading-relaxed', className)}
    {...props}
  />
));
AlertDescription.displayName = 'AlertDescription';

export { Alert, AlertDescription, AlertTitle };
export { alertVariants };
