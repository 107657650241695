import { initialAction, initialState } from './initial';

type Reducer = (
  state: typeof initialState,
  action: typeof initialAction,
) => typeof initialState;

const reducer: Reducer = (state, action) => {
  switch (action?.type) {
    case 'UPDATE_CHECKOUT_STEP':
    case 'UPDATE_CHECKOUT_RECIPIENT_ADDRESS_ID':
    case 'UPDATE_CHECKOUT_PAYMENT_GATEWAY_ID':
    case 'UPDATE_CHECKOUT_DESCRIPTION':
    case 'UPDATE_CHECKOUT_PAPER_INVOICE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer };
