import { Fetch } from '@/lib';
import type { CategoryProduct, ProductSortType } from '@/types';

type RequestResponse = {
  productsByCategories: CategoryProduct[];
};

type RequestParameter = {
  categoriesIds: string[];
  sortType: ProductSortType;
};

const searchProductsByCategories = async (params: RequestParameter) =>
  await Fetch<RequestResponse, RequestParameter>({
    url: 'product/searchProductsByCategories',
    params,
  });

export { searchProductsByCategories };
