import { Fetch } from '@/lib';
import type { PaymentToken } from '@/types';

type RequestResponse = PaymentToken;

type RequestParameter = {};

type RequestBody = {
  cartId: string;
  paymentGatewayId: string;
};

const createPayment = async (body: RequestBody) =>
  await Fetch<RequestResponse, RequestParameter, RequestBody>({
    url: 'cart/createPayment',
    method: 'POST',
    body,
  });

export { createPayment };
