import type { IdentityPayload } from '@/types';

type State = Partial<IdentityPayload> & Record<'step', 1 | 2>;
const initialState: State = {
  step: 1,
};

type Action =
  | {
      type: 'UPDATE_LOGIN_PHONE_NUMBER';
      payload: Pick<typeof initialState, 'phoneNumber'>;
    }
  | {
      type: 'UPDATE_LOGIN_TICKET';
      payload: Pick<typeof initialState, 'ticket'>;
    }
  | {
      type: 'UPDATE_LOGIN_STEP';
      payload: Pick<typeof initialState, 'step'>;
    }
  | undefined;
const initialAction: Action = undefined;

export { initialAction, initialState };
