import { Fetch, setIdentityToken } from '@/lib';
import type { IdentityPayload, IdentityToken } from '@/types';

type RequestResponse = IdentityToken;

type RequestParameter = {};

type RequestBody = Pick<IdentityPayload, 'otp' | 'ticket' | 'platform'>;

const signInWithOtp = async (body: RequestBody) => {
  const response = await Fetch<RequestResponse, RequestParameter, RequestBody>({
    url: 'identity/signInWithOtp',
    method: 'POST',
    body,
  });

  setIdentityToken(response);

  return response;
};

export { signInWithOtp };
