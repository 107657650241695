import { Fetch } from '@/lib';
import type { IdentitySession } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    IDENTITY: {
      SESSION: { GLOBAL: GLOBAL_IDENTITY_SESSION },
    },
  },
} = TAG;

type RequestResponse = {
  current: IdentitySession;
  others: IdentitySession[];
};

const getSessions = async () =>
  await Fetch<RequestResponse>({
    url: 'identity/getSessions',
    next: {
      tags: [GLOBAL_IDENTITY_SESSION],
    },
  });

export { getSessions };
