import { deleteIdentityToken, Fetch } from '@/lib';

type RequestResponse = {};

type RequestParameter = {};

const signOut = async () => {
  const response = await Fetch<RequestResponse, RequestParameter>({
    url: 'identity/signOut',
    method: 'PATCH',
  });

  deleteIdentityToken();

  return response;
};

export { signOut };
