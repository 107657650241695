import { createContext, Dispatch } from 'react';

import { initialAction, initialState } from './initial';

const CartValueContext = createContext<typeof initialState>(initialState);

const CartActionContext = createContext<Dispatch<typeof initialAction>>(
  () => {},
);

export { CartActionContext, CartValueContext };
