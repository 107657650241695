import { Fetch } from '@/lib';

type RequestResponse = {};

type RequestParameter = {
  id: string;
};

const notifyWhenProductIsAvailable = async (params: RequestParameter) =>
  await Fetch<RequestResponse, RequestParameter>({
    url: 'product/notifyWhenProductIsAvailable',
    method: 'POST',
    params,
  });

export { notifyWhenProductIsAvailable };
