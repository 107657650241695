import { Fetch } from '@/lib';
import type { Province } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    CITY: { GLOBAL: GLOBAL_CITY },
  },
} = TAG;

type RequestResponse = {
  provinces: Province[];
};

const getCities = async () =>
  await Fetch<RequestResponse>({
    url: 'city/getCities',
    next: { tags: [GLOBAL_CITY] },
    cache: 'force-cache',
  });

export { getCities };
