import { Fetch } from '@/lib';
import type { IdentityPayload } from '@/types';

type RequestResponse = {};

type RequestParameter = {};

type RequestBody = Pick<
  IdentityPayload,
  'phoneNumber' | 'email' | 'token' | 'tokenType' | 'newPassword'
>;

const resetPassword = async (body: RequestBody) =>
  await Fetch<RequestResponse, RequestParameter, RequestBody>({
    url: 'identity/resetPassword',
    method: 'PATCH',
    body,
  });

export { resetPassword };
