'use client';

import { useReducer } from 'react';

import { CheckAuthActionContext, CheckAuthValueContext } from './context';
import { useCheckAuthAction, useCheckAuthValue } from './hook';
import { initialState } from './initial';
import { reducer } from './reducer';

const CheckAuthContext = ({
  children,
  ...props
}: Readonly<React.PropsWithChildren<typeof initialState>>) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...props });

  return (
    <CheckAuthValueContext.Provider value={state}>
      <CheckAuthActionContext.Provider value={dispatch}>
        {children}
      </CheckAuthActionContext.Provider>
    </CheckAuthValueContext.Provider>
  );
};

export { CheckAuthContext, useCheckAuthAction, useCheckAuthValue };
