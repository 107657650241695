import { Fetch } from '@/lib';
import type { IdentityPayload } from '@/types';

type RequestResponse = Pick<IdentityPayload, 'ticket'>;

type RequestParameter = {};

type RequestBody = Pick<IdentityPayload, 'phoneNumber'>;

const sendSigninOtp = async (body: RequestBody) =>
  await Fetch<RequestResponse, RequestParameter, RequestBody>({
    url: 'identity/sendSignInOtp',
    method: 'POST',
    body,
  });

export { sendSigninOtp };
