import { getProductBySlug, getProductComments } from '@/services';
import type { SubProduct } from '@/types';

type State = Partial<
  Awaited<ReturnType<typeof getProductBySlug>> &
    Record<'comments', Awaited<ReturnType<typeof getProductComments>>> &
    Record<'selectedSubProduct', SubProduct> &
    Record<'calculatedComments', Record<'total' | 'rate', number>>
>;
const initialState: State = {};

type Action =
  | {
      type: 'UPDATE_SELECTED_SUB_PRODUCT';
      payload: Pick<typeof initialState, 'selectedSubProduct'>;
    }
  | undefined;
const initialAction: Action = undefined;

export { initialAction, initialState };
