const BREAKPOINT = {
  SMALLER_THAN: {
    SM: 'only screen and (max-width: 640px)',
    MD: 'only screen and (max-width: 768px)',
    LG: 'only screen and (max-width: 1024px)',
    XL: 'only screen and (max-width: 1280px)',
    XXL: 'only screen and (max-width: 1536px)',
  },
  LARGER_THAN: {
    SM: 'only screen and (min-width: 640px)',
    MD: 'only screen and (min-width: 768px)',
    LG: 'only screen and (min-width: 1024px)',
    XL: 'only screen and (min-width: 1280px)',
    XXL: 'only screen and (min-width: 1536px)',
  },
} as const;

export { BREAKPOINT };
