'use client';

import * as React from 'react';

import Image from 'next/image';

import {
  Badge,
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  LocaleLink,
  Skeleton,
  TrashIcon,
  TypographyDeleted,
  TypographyMuted,
  TypographySmall,
} from '@/components';
import type { CartProduct as CartProductType } from '@/types';
import {
  cn,
  discountPercentage,
  generateCartInvoice,
  generateFileUrl,
  numberFormat,
  PATHNAME,
  randomId,
  UNIT,
} from '@/utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    PRODUCT: { BASE_URL: PRODUCT_BASE_URL },
  },
} = PATHNAME;
const { PRICE } = UNIT;

type CartProductContextProps = CartProductType;

const CartProductContext = React.createContext<CartProductContextProps>(
  {} as CartProductContextProps,
);

const useCartProduct = () => {
  const context = React.useContext(CartProductContext);

  if (!context) {
    throw new Error('useCartProduct must be used within a <CartProduct />');
  }

  return context;
};

type CartProductProps = React.ComponentPropsWithoutRef<typeof Card> &
  CartProductContextProps;

const CartProduct = React.forwardRef<
  React.ElementRef<typeof Card>,
  CartProductProps
>(
  (
    {
      product,
      subProduct,
      packagingInfo,
      size,
      price,
      className,
      children,
      ...props
    },
    ref,
  ) => (
    <CartProductContext.Provider
      value={{
        product,
        subProduct,
        packagingInfo,
        size,
        price,
      }}
    >
      <Card ref={ref} className={cn('relative flex', className)} {...props}>
        {children}
      </Card>
    </CartProductContext.Provider>
  ),
);
CartProduct.displayName = 'CartProduct';

const CartProductHeader = React.forwardRef<
  React.ElementRef<typeof CardHeader>,
  React.ComponentPropsWithoutRef<typeof CardHeader>
>(({ className, ...props }, ref) => (
  <CardHeader
    ref={ref}
    className={cn('shrink-0 gap-0', className)}
    {...props}
  />
));
CartProductHeader.displayName = 'CartProductHeader';

const CartProductBanner = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => {
  const {
    product: { name, bannerId },
  } = useCartProduct();

  const { width, height } = {
    width: 200,
    height: 180,
  };

  return (
    <div
      ref={ref}
      className={cn('block size-full aspect-square', className)}
      {...props}
    >
      <Image
        className='size-full rounded-sm border border-border p-1'
        src={generateFileUrl('Product', bannerId, width, height)}
        width={width}
        height={height}
        alt={name}
      />
    </div>
  );
});
CartProductBanner.displayName = 'CartProductBanner';

const CartProductRemoveAction = React.forwardRef<
  React.ElementRef<typeof Button>,
  React.ComponentPropsWithoutRef<typeof Button>
>(({ variant = 'link', className, ...props }, ref) => (
  <Button
    ref={ref}
    variant={variant}
    className={cn('h-auto text-danger p-0.5', className)}
    {...props}
  >
    <TrashIcon />
    <TypographySmall className='sr-only'>حذف محصول</TypographySmall>
  </Button>
));
CartProductRemoveAction.displayName = 'CartProductRemoveAction';

const CartProductContent = React.forwardRef<
  React.ElementRef<typeof CardContent>,
  React.ComponentPropsWithoutRef<typeof CardContent>
>(({ className, ...props }, ref) => (
  <CardContent ref={ref} className={cn('grid gap-4', className)} {...props} />
));
CartProductContent.displayName = 'CartProductContent';

const CartProductTitle = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => {
  const {
    product: { name, slug },
    subProduct: { attributes, originalPrice, price = originalPrice },
  } = useCartProduct();

  return (
    <div ref={ref} className={cn('flex flex-col gap-2', className)} {...props}>
      <CardTitle className='line-clamp-2'>
        <LocaleLink
          href={`${PRODUCT_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`}
          className='text-base font-medium leading-7'
          title={name}
        >
          {name}
        </LocaleLink>
        {price < originalPrice ? (
          <Badge variant='danger' className='mx-1 rounded'>
            {discountPercentage(originalPrice, price)}
            <span className='sr-only'>درصد تخفیف</span>
          </Badge>
        ) : null}
      </CardTitle>
      <div className='flex items-center gap-2'>
        {attributes.flatMap(({ selectedValues }) =>
          selectedValues.slice(0, 5).map(value => {
            const id = randomId();
            return (
              <Badge key={id} variant='outline' className='text-nowrap'>
                {value}
              </Badge>
            );
          }),
        )}
      </div>
    </div>
  );
});
CartProductTitle.displayName = 'CartProductTitle';

const CartProductPackaging = React.forwardRef<
  React.ElementRef<typeof CardDescription>,
  React.ComponentPropsWithoutRef<typeof CardDescription>
>(({ className, ...props }, ref) => {
  const {
    packagingInfo: { packagingModel },
    size,
  } = useCartProduct();

  return (
    <CardDescription
      ref={ref}
      className={cn('flex flex-wrap items-center gap-1', className)}
      {...props}
    >
      <TypographyMuted className='text-foreground'>
        مقدار نهایی:
      </TypographyMuted>
      <TypographySmall className='font-normal'>
        {`${numberFormat(size)} ${packagingModel.label}`}
      </TypographySmall>
    </CardDescription>
  );
});
CartProductPackaging.displayName = 'CartProductPackaging';

const CartProductPrice = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => {
  const cartProduct = useCartProduct();
  const { originalPrice = 0, totalPrice = 0 } = generateCartInvoice([
    cartProduct,
  ]);

  return (
    <div
      ref={ref}
      className={cn('grid justify-items-end gap-1', className)}
      {...props}
    >
      {originalPrice > totalPrice ? (
        <CardDescription className='font-normal'>
          <TypographyDeleted>{numberFormat(originalPrice)}</TypographyDeleted>
          <span className='ms-1'>{PRICE}</span>
          <span className='sr-only'>قیمت اولیه محصول</span>
        </CardDescription>
      ) : null}
      <CardDescription className='text-primary'>
        <span className='text-base'>{numberFormat(totalPrice)}</span>
        <span className='ms-1'>{PRICE}</span>
        <span className='sr-only'>قیمت نهایی محصول</span>
      </CardDescription>
    </div>
  );
});
CartProductPrice.displayName = 'CartProductPrice';

const CartProductPlaceholder = React.forwardRef<
  React.ElementRef<typeof Card>,
  React.ComponentPropsWithoutRef<typeof Card>
>(({ className, ...props }, ref) => (
  <Card
    ref={ref}
    className={cn('grid-cols-12 grid-rows-2', className)}
    {...props}
  />
));
CartProductPlaceholder.displayName = 'CartProductPlaceholder';

const CartProductHeaderPlaceholder = React.forwardRef<
  React.ElementRef<typeof CardHeader>,
  React.ComponentPropsWithoutRef<typeof CardHeader>
>(({ className, ...props }, ref) => (
  <CardHeader
    ref={ref}
    className={cn('col-span-2 row-span-2', className)}
    {...props}
  >
    <div className='h-24'>
      <Skeleton className='size-full' />
    </div>
  </CardHeader>
));
CartProductHeaderPlaceholder.displayName = 'CartProductHeaderPlaceholder';

const CartProductContentPlaceholder = React.forwardRef<
  React.ElementRef<typeof CardContent>,
  React.ComponentPropsWithoutRef<typeof CardContent>
>(({ className, ...props }, ref) => (
  <CardContent
    ref={ref}
    className={cn('col-span-10 col-start-3 row-start-1', className)}
    {...props}
  >
    <div className='flex w-full items-start justify-between gap-8'>
      <div className='w-full space-y-2'>
        <Skeleton className='h-3 w-3/5' />
        <Skeleton className='h-2.5 w-2/5' />
      </div>
      <Skeleton className='h-10 w-60' />
    </div>
  </CardContent>
));
CartProductContentPlaceholder.displayName = 'CartProductContentPlaceholder';

const CartProductFooterPlaceholder = React.forwardRef<
  React.ElementRef<typeof CardFooter>,
  React.ComponentPropsWithoutRef<typeof CardFooter>
>(({ className, ...props }, ref) => (
  <CardFooter
    ref={ref}
    className={cn(
      'col-span-10 col-start-3 row-start-2 justify-between',
      className,
    )}
    {...props}
  >
    <Skeleton className='h-2 w-1/5' />
    <Skeleton className='h-2 w-1/5' />
  </CardFooter>
));
CartProductFooterPlaceholder.displayName = 'CartProductFooterPlaceholder';

export {
  CartProduct,
  CartProductBanner,
  CartProductContent,
  CartProductContentPlaceholder,
  CartProductFooterPlaceholder,
  CartProductHeader,
  CartProductHeaderPlaceholder,
  CartProductPackaging,
  CartProductPlaceholder,
  CartProductPrice,
  CartProductRemoveAction,
  CartProductTitle,
};
export { useCartProduct };
