'use client';

import * as React from 'react';

import { FixedSizeList } from 'react-window';

const InnerElement = React.forwardRef<
  React.ElementRef<'ul'>,
  React.ComponentPropsWithoutRef<'ul'>
>(({ style, ...props }, ref) => (
  <ul
    ref={ref}
    style={{
      ...style,
      height: '100%',
    }}
    {...props}
  />
));
InnerElement.displayName = 'InnerElement';

type VirtualListProps<T> = React.ComponentPropsWithoutRef<
  typeof FixedSizeList<T>
>;

function VirtualList<T>({
  direction = 'rtl',
  innerElementType = InnerElement,
  ...props
}: VirtualListProps<T>) {
  return (
    <FixedSizeList<T>
      direction={direction}
      innerElementType={innerElementType}
      {...props}
    />
  );
}

export { InnerElement, VirtualList };
