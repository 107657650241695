import { startTransition, useEffect, useState } from 'react';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { useDebounceCallback, useIsMounted } from 'usehooks-ts';

import { PATHNAME } from '@/utils';

const {
  QUERY_PARAM: {
    SYMBOL: { QUESTION_MARK: QUESTION_MARK_SYMBOL },
  },
} = PATHNAME;

type QueryParamOption<T> = {
  initialValue: T;
  mode?: keyof Pick<ReturnType<typeof useRouter>, 'push' | 'replace'>;
  delay?: Parameters<typeof useDebounceCallback>[1];
  routerOptions?: Parameters<
    ReturnType<typeof useRouter>['push' | 'replace']
  >[1];
  debounceOptions?: Parameters<typeof useDebounceCallback>[2];
};

const useQueryParam = <
  T extends Record<string, string | number | boolean | undefined>,
>({
  initialValue,
  mode = 'replace',
  delay = 500,
  routerOptions,
  debounceOptions,
}: QueryParamOption<T>) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const isMounted = useIsMounted();

  const [queryParam, setQueryParam] = useState<T>(initialValue);

  const handleQueryParam = useDebounceCallback(
    (queryParam: T) => {
      const params = new URLSearchParams(searchParams.toString());

      Object.keys(queryParam).forEach(key => {
        if (queryParam[key] !== initialValue[key]) {
          params.set(key, String(queryParam[key]));
        } else {
          params.delete(key);
        }
      });

      startTransition(() => {
        router[mode](
          `${pathname}${QUESTION_MARK_SYMBOL}${params.toString()}`,
          routerOptions,
        );
      });
    },
    delay,
    debounceOptions,
  );

  useEffect(() => {
    if (isMounted()) {
      const params = new URLSearchParams(searchParams.toString());
      const currentValue = { ...initialValue } as Record<
        string,
        string | number | boolean | undefined
      >;

      Object.keys(initialValue).forEach(key => {
        if (params.has(key)) {
          const value = params.get(key);
          if (typeof initialValue[key] === 'number') {
            currentValue[key] =
              value !== null ? Number(value) : initialValue[key];
          } else {
            currentValue[key] = value || initialValue[key];
          }
        }
      });

      setQueryParam(currentValue as T);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return [queryParam, handleQueryParam] as const;
};

export { useQueryParam };
