'use client';

import * as React from 'react';

import {
  Button,
  DecimalInput,
  MinusIcon,
  PlusIcon,
  TrashIcon,
} from '@/components';
import type { CartProduct } from '@/types';
import {
  cn,
  enforceValueLimits,
  generatePurchaseStock,
  preciseRound,
} from '@/utils';

type CartCounterContextProps = Partial<
  CartProduct & ReturnType<typeof generatePurchaseStock>
>;

const CartCounterContext = React.createContext<CartCounterContextProps>(
  {} as CartCounterContextProps,
);

const useCartCounter = () => {
  const context = React.useContext(CartCounterContext);
  if (!context) {
    throw new Error('useCartCounter must be used within a <CartCounter />');
  }
  return context;
};

type CartCounterProps = React.ComponentPropsWithoutRef<'div'> &
  Partial<CartProduct>;

const CartCounter = React.forwardRef<React.ElementRef<'div'>, CartCounterProps>(
  (
    {
      product,
      subProduct,
      packagingInfo,
      size,
      price,
      className,
      children,
      ...props
    },
    ref,
  ) => (
    <CartCounterContext.Provider
      value={{
        product,
        subProduct,
        packagingInfo,
        size,
        price,
        ...(subProduct ? generatePurchaseStock(subProduct) : {}),
      }}
    >
      <div
        ref={ref}
        className={cn(
          'relative flex h-12 w-full items-center justify-between gap-1 rounded-md border border-border bg-card px-2 py-1 text-card-foreground',
          className,
        )}
        {...props}
      >
        {children}
        <div className='absolute inset-x-0 bottom-0 h-px bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-transparent via-primary to-transparent' />
      </div>
    </CartCounterContext.Provider>
  ),
);
CartCounter.displayName = 'CartCounter';

const CartCounterIncrement = React.forwardRef<
  React.ElementRef<typeof Button>,
  React.ComponentPropsWithoutRef<typeof Button>
>(
  (
    { variant = 'ghost', size = 'icon', className, disabled, ...props },
    ref,
  ) => {
    const { size: counterSize, maxStockSize } = useCartCounter();
    return (
      <Button
        ref={ref}
        variant={variant}
        size={size}
        className={cn(
          'group rounded-[calc(var(--radius)-0.25rem)] hover:bg-inherit',
          className,
        )}
        disabled={disabled ?? Number(counterSize) >= Number(maxStockSize)}
        {...props}
      >
        <span className='sr-only'>آیکون افزایش</span>
        <PlusIcon className='group-disabled:opacity-50' />
      </Button>
    );
  },
);
CartCounterIncrement.displayName = 'CartCounterIncrement';

const CartCounterDecrement = React.forwardRef<
  React.ElementRef<typeof Button>,
  React.ComponentPropsWithoutRef<typeof Button>
>(
  (
    { variant = 'ghost', size = 'icon', className, disabled, ...props },
    ref,
  ) => {
    const { size: counterSize, minStockSize } = useCartCounter();
    return (
      <Button
        ref={ref}
        variant={variant}
        size={size}
        className={cn(
          'group rounded-[calc(var(--radius)-0.25rem)] hover:bg-inherit',
          className,
        )}
        disabled={disabled ?? Number(counterSize) <= Number(minStockSize)}
        {...props}
      >
        <span className='sr-only'>آیکون کاهش</span>
        <MinusIcon className='group-disabled:opacity-50' />
      </Button>
    );
  },
);
CartCounterDecrement.displayName = 'CartCounterDecrement';

const CartCounterRemove = React.forwardRef<
  React.ElementRef<typeof Button>,
  React.ComponentPropsWithoutRef<typeof Button>
>(
  (
    { variant = 'ghost', size = 'icon', className, disabled, ...props },
    ref,
  ) => {
    const { size: counterSize, minStockSize } = useCartCounter();
    return (
      <Button
        ref={ref}
        variant={variant}
        size={size}
        className={cn(
          'rounded-[calc(var(--radius)-0.25rem)] hover:bg-inherit disabled:hidden',
          className,
        )}
        disabled={disabled ?? Number(counterSize) > Number(minStockSize)}
        {...props}
      >
        <TrashIcon className='text-danger' />
      </Button>
    );
  },
);
CartCounterRemove.displayName = 'CartCounterRemove';

type CartCounterInputProps = React.ComponentPropsWithoutRef<
  typeof DecimalInput
> &
  Partial<Record<'onValueChange', (value: number) => void>>;

const CartCounterInput = React.forwardRef<
  React.ElementRef<typeof DecimalInput>,
  CartCounterInputProps
>(
  (
    {
      decimals = 3,
      allowCombine = true,
      addonAfter,
      containerClassName,
      className,
      value,
      onValueChange,
      onChange,
      onKeyDown,
      onBlur,
      ...props
    },
    ref,
  ) => {
    const [formattedValue, setFormattedValue] = React.useState<
      string | number
    >();
    const {
      size: counterSize = 1,
      minStockSize,
      maxStockSize,
      packagingInfo,
    } = useCartCounter();

    const { measurementUnit, amount = 1 } = packagingInfo ?? {};
    const { name: measurementUnitName } = measurementUnit ?? {};

    const calculateFormattedValue = React.useCallback(
      (size: typeof counterSize) => {
        const roundedValue = preciseRound(size, amount, decimals);

        if (minStockSize === undefined || maxStockSize === undefined) {
          return roundedValue;
        }

        return enforceValueLimits(
          roundedValue,
          preciseRound(minStockSize, amount),
          preciseRound(maxStockSize, amount),
        );
      },
      [amount, decimals, minStockSize, maxStockSize],
    );

    const calculateCounterSize = React.useCallback(
      (value: typeof formattedValue) => {
        const numericValue = Number(value) || 1;
        return Math.ceil(numericValue / amount);
      },
      [amount],
    );

    const onInputConfirm = React.useCallback(() => {
      const updatedSize = calculateCounterSize(formattedValue);
      const updatedValue = calculateFormattedValue(updatedSize);

      setFormattedValue(updatedValue);
      onValueChange?.(updatedSize);
    }, [
      formattedValue,
      calculateCounterSize,
      calculateFormattedValue,
      onValueChange,
    ]);

    React.useEffect(() => {
      const updatedValue = calculateFormattedValue(counterSize);
      setFormattedValue(updatedValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counterSize]);

    return (
      <DecimalInput
        ref={ref}
        decimals={decimals}
        allowCombine={allowCombine}
        addonAfter={addonAfter ?? measurementUnitName}
        containerClassName={cn(
          'justify-center *:basis-full [&>span]:justify-start [&>span]:border-none [&>span]:bg-inherit [&>span]:p-0 [&>span]:text-sm [&>span]:font-medium [&>span]:text-inherit',
          containerClassName,
        )}
        className={cn(
          'h-auto rounded-none border-none px-1 text-left ring-0 focus-visible:ring-0',
          className,
        )}
        value={value ?? formattedValue}
        onChange={event => {
          setFormattedValue(event.target.value);
          onChange?.(event);
        }}
        onBlur={event => {
          onInputConfirm();
          onBlur?.(event);
        }}
        {...props}
      />
    );
  },
);
CartCounterInput.displayName = 'CartCounterInput';

export {
  CartCounter,
  CartCounterDecrement,
  CartCounterIncrement,
  CartCounterInput,
  CartCounterRemove,
};
export { useCartCounter };
