import { Fetch } from '@/lib';
import type { PageBuilderType, PageCategoryProduct } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    PAGE_BUILDER: {
      PAGE_CATEGORY_PRODUCTS: { BASE: BASE_PAGE_CATEGORY_PRODUCTS },
    },
  },
} = TAG;

type RequestResponse = Partial<
  Record<
    'pageSettings',
    Record<`Landing_Pos${3 | 5 | 7}_CategoryProduct`, PageCategoryProduct>
  >
>;

type RequestParameter = {
  type: PageBuilderType[];
};

const getPageCategoryProducts = async (params: RequestParameter) =>
  await Fetch<RequestResponse, RequestParameter>({
    url: 'pageBuilder/getPageCategoryProducts',
    params,
    next: {
      tags: [BASE_PAGE_CATEGORY_PRODUCTS],
    },
  });

export { getPageCategoryProducts };
