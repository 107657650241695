'use client';

import * as React from 'react';

import Image from 'next/image';

import { cva } from 'class-variance-authority';
import type { VariantProps } from 'class-variance-authority';

import Logo from '@/assets/images/logo.png';
import FingerprintDark from '@/assets/svgs/fingerprint-dark.svg';
import FingerprintLight from '@/assets/svgs/fingerprint-light.svg';
import KashilandIconSvg from '@/assets/svgs/logo.svg';
import { LocaleLink, Slot } from '@/components';
import { cn, PATHNAME } from '@/utils';

const {
  LOCAL: {
    HOME: { BASE_URL: HOME_BASE_URL },
  },
} = PATHNAME;

type KashilandLogoProps = Partial<
  React.ComponentPropsWithoutRef<typeof Image> &
    Pick<React.ComponentPropsWithoutRef<typeof LocaleLink>, 'href'>
>;

const KashilandIconLogo = React.forwardRef<
  React.ElementRef<typeof Image>,
  KashilandLogoProps
>(
  (
    {
      src = KashilandIconSvg,
      width = 396,
      height = 396,
      alt = 'لوگو کاشی‌لند',
      href = HOME_BASE_URL,
      ...props
    },
    ref,
  ) => (
    <LocaleLink href={href}>
      <Image
        ref={ref}
        src={src}
        width={width}
        height={height}
        alt={alt}
        {...props}
      />
    </LocaleLink>
  ),
);
KashilandIconLogo.displayName = 'KashilandIconLogo';

const KashilandLogo = React.forwardRef<
  React.ElementRef<typeof Image>,
  KashilandLogoProps
>(
  (
    {
      src = Logo,
      width = 150,
      height = 40,
      alt = 'کاشی لند',
      href = HOME_BASE_URL,
      ...props
    },
    ref,
  ) => (
    <LocaleLink href={href}>
      <Image
        ref={ref}
        src={src}
        width={width}
        height={height}
        alt={alt}
        {...props}
      />
    </LocaleLink>
  ),
);
KashilandLogo.displayName = 'KashilandLogo';

const fingerprintWallpaperVariants = cva('relative isolate overflow-hidden', {
  variants: {
    variant: {
      default: 'bg-primary text-primary-foreground',
      danger: 'bg-danger text-danger-foreground',
      warning: 'bg-warning text-warning-foreground',
      success: 'bg-success text-success-foreground',
      muted: 'bg-muted text-muted-foreground',
      border: 'bg-border text-foreground',
      foreground: 'bg-foreground text-background',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

type FingerprintWallpaperProps = React.ComponentPropsWithoutRef<'div'> &
  VariantProps<typeof fingerprintWallpaperVariants> &
  Partial<Record<'asChild', boolean>>;

const FingerprintWallpaper = React.forwardRef<
  React.ElementRef<'div'>,
  FingerprintWallpaperProps
>(({ asChild = false, variant, className, children, ...props }, ref) => {
  const Comp = asChild ? Slot : 'div';

  const isLightBackground = variant === 'muted' || variant === 'border';

  return (
    <Comp
      ref={ref}
      className={cn(fingerprintWallpaperVariants({ variant, className }))}
      {...props}
    >
      <Image
        src={isLightBackground ? FingerprintDark : FingerprintLight}
        className='absolute right-0 top-0 -z-10 -mt-36 max-w-[50%] rotate-180'
        alt='start wavey fingerprint vector'
      />

      <div className='py-8'>{children}</div>

      <Image
        src={isLightBackground ? FingerprintDark : FingerprintLight}
        className='absolute bottom-0 left-0 -z-10 -mb-36 max-w-[50%]'
        alt='end wavey fingerprint vector'
      />
    </Comp>
  );
});
FingerprintWallpaper.displayName = 'FingerprintWallpaper';

const UnionBackground = React.forwardRef<
  React.ElementRef<'svg'>,
  React.ComponentPropsWithoutRef<'svg'>
>(
  (
    {
      viewBox = '0 0 1680 404',
      fill = 'none',
      xmlns = 'http://www.w3.org/2000/svg',
      ...props
    },
    ref,
  ) => (
    <svg ref={ref} viewBox={viewBox} fill={fill} xmlns={xmlns} {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M338 1C312.671 1 288.38 11.0095 270.47 28.8258C252.561 46.6419 242.5 70.8053 242.5 96C242.5 121.195 252.561 145.358 270.47 163.174C288.38 180.99 312.671 191 338 191H1378V191.143C1404.72 192.23 1430.13 203.268 1449.11 222.157C1469.13 242.073 1480.38 269.084 1480.38 297.25C1480.38 325.416 1469.13 352.428 1449.11 372.344C1429.09 392.259 1401.94 403.447 1373.63 403.447H-3V402.447H1373.63C1401.68 402.447 1428.58 391.363 1448.41 371.635C1468.24 351.906 1479.38 325.149 1479.38 297.25C1479.38 269.351 1468.24 242.594 1448.41 222.866C1428.58 203.138 1401.68 192.054 1373.63 192.054H439.5V192H338C312.407 192 287.863 181.886 269.765 163.883C251.668 145.88 241.5 121.462 241.5 96C241.5 70.5384 251.668 46.1201 269.765 28.1168C287.863 10.1136 312.407 0 338 0H1692V1H338Z'
        fill='#CBD5E1'
      />
    </svg>
  ),
);
UnionBackground.displayName = 'UnionBackground';

export {
  FingerprintWallpaper,
  KashilandIconLogo,
  KashilandLogo,
  UnionBackground,
};
export { fingerprintWallpaperVariants };
