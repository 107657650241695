import { Fetch } from '@/lib';
import type { SiteMapCount } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    SEO: {
      SITE_MAP: { COUNT: SEO_SITE_MAP_COUNT },
    },
  },
} = TAG;

type RequestResponse = SiteMapCount;

const getSiteMapCount = async () =>
  await Fetch<RequestResponse>({
    url: 'seo/getSiteMapCount',
    next: {
      tags: [SEO_SITE_MAP_COUNT],
    },
  });

export { getSiteMapCount };
