import { Fetch } from '@/lib';
import type { RecipientAddressInfo } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    USER: {
      RECIPIENT_ADDRESS_INFOS: { BASE: BASE_RECIPIENT_ADDRESS_INFOS },
    },
  },
} = TAG;

type RequestResponse = {
  addressInfos: RecipientAddressInfo[];
};

const getRecipientAddressInfos = async () =>
  await Fetch<RequestResponse>({
    url: 'user/getRecipientAddressInfos',
    next: {
      tags: [BASE_RECIPIENT_ADDRESS_INFOS],
    },
  });

export { getRecipientAddressInfos };
