import { getAuthorizationHeaders } from '@/lib';
import type { ResponseErrors } from '@/types';
import { generateRequestUrl, generateResponseError } from '@/utils';

type RequestConfigMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

type RequestConfig<P, B> = StrictOmit<RequestInit, 'body'> &
  Record<'url', string> &
  Partial<
    Record<'params', P> &
      Record<'method', RequestConfigMethod> &
      Record<'body', B>
  >;

const Fetch = <T, P extends object = object, B = unknown>({
  url,
  params,
  method = 'GET',
  body,
  headers,
  cache = 'no-cache',
  ...config
}: RequestConfig<P, B>) =>
  new Promise<T>(async (resolve, reject) => {
    const input = generateRequestUrl<P>(url, params);
    const authorizationHeaders = await getAuthorizationHeaders();

    const response = await fetch(input, {
      method,
      body: method !== 'GET' ? JSON.stringify(body) : undefined,
      headers: {
        'Content-Type': 'application/json',
        ...authorizationHeaders,
        ...headers,
      },
      cache,
      ...config,
    });
    const data = await response.json();

    if (response.ok) {
      resolve(data as T);
    } else {
      const message = generateResponseError(data as ResponseErrors);
      reject(new Error(message));
    }
  });

export { Fetch };
