'use client';

export {
  AddressBook as AddressBookIcon,
  ArrowDown as ArrowDownIcon,
  ArrowLeft as ArrowLeftIcon,
  ArrowsDownUp as ArrowsDownUpIcon,
  ArrowUpLeft as ArrowUpLeftIcon,
  Basket as BasketIcon,
  Bell as BellIcon,
  BookOpenText as BookOpenTextIcon,
  BuildingOffice as BuildingOfficeIcon,
  Calculator as CalculatorIcon,
  CalendarDots as CalendarDotsIcon,
  CaretDoubleDown as CaretDoubleDownIcon,
  CaretDoubleLeft as CaretDoubleLeftIcon,
  CaretDoubleRight as CaretDoubleRightIcon,
  CaretDoubleUp as CaretDoubleUpIcon,
  CaretDown as CaretDownIcon,
  CaretLeft as CaretLeftIcon,
  CaretRight as CaretRightIcon,
  CaretUp as CaretUpIcon,
  ChatCircleDots as ChatCircleDotsIcon,
  Check as CheckIcon,
  Circle as CircleIcon,
  ClipboardText as ClipboardTextIcon,
  Clock as ClockIcon,
  Copy as CopyIcon,
  Dot as DotIcon,
  DotsNine as DotsNineIcon,
  DotsThree as DotsThreeIcon,
  DotsThreeVertical as DotsThreeVerticalIcon,
  DownloadSimple as DownloadSimpleIcon,
  EnvelopeOpen as EnvelopeOpenIcon,
  Eye as EyeIcon,
  EyeSlash as EyeSlashIcon,
  FacebookLogo as FacebookLogoIcon,
  FileText as FileTextIcon,
  Funnel as FunnelIcon,
  FunnelSimple as FunnelSimpleIcon,
  Gauge as GaugeIcon,
  Globe as GlobeIcon,
  GpsFix as GpsFixIcon,
  HandHeart as HandHeartIcon,
  Handshake as HandshakeIcon,
  Heart as HeartIcon,
  ImageBroken as ImageBrokenIcon,
  Image as ImageIcon,
  Info as InfoIcon,
  InstagramLogo as InstagramLogoIcon,
  Lightbulb as LightbulbIcon,
  LinkedinLogo as LinkedinLogoIcon,
  List as ListIcon,
  MagnifyingGlass as MagnifyingGlassIcon,
  Mailbox as MailboxIcon,
  MapPinArea as MapPinAreaIcon,
  MapPin as MapPinIcon,
  Minus as MinusIcon,
  NavigationArrow as NavigationArrowIcon,
  Newspaper as NewspaperIcon,
  PencilSimple as PencilSimpleIcon,
  PhoneCall as PhoneCallIcon,
  Phone as PhoneIcon,
  PinterestLogo as PinterestLogoIcon,
  PlayCircle as PlayCircleIcon,
  Play as PlayIcon,
  Plus as PlusIcon,
  Receipt as ReceiptIcon,
  Scales as ScalesIcon,
  SealCheck as SealCheckIcon,
  SealPercent as SealPercentIcon,
  ShareNetwork as ShareNetworkIcon,
  ShoppingCartSimple as ShoppingCartSimpleIcon,
  SignIn as SignInIcon,
  SignOut as SignOutIcon,
  SortAscending as SortAscendingIcon,
  Spinner as SpinnerIcon,
  SquaresFour as SquaresFourIcon,
  Star as StarIcon,
  TelegramLogo as TelegramLogoIcon,
  ThumbsDown as ThumbsDownIcon,
  ThumbsUp as ThumbsUpIcon,
  Trash as TrashIcon,
  TrashSimple as TrashSimpleIcon,
  User as UserIcon,
  Users as UsersIcon,
  UsersThree as UsersThreeIcon,
  WarningCircle as WarningCircleIcon,
  WhatsappLogo as WhatsappLogoIcon,
  X as XIcon,
  XLogo as XLogoIcon,
  YoutubeLogo as YoutubeLogoIcon,
} from '@phosphor-icons/react';
