'use client';

import * as React from 'react';

import { KashilandLogo } from '@/components';
import { cn } from '@/utils';

const Loading = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('fixed inset-0 z-50 bg-muted/90', className)}
    {...props}
  >
    <div className='pointer-events-none grid h-full place-content-center'>
      <KashilandLogo width={300} height={80} className='animate-pulse' />
    </div>
  </div>
));
Loading.displayName = 'Loading';

export { Loading };
