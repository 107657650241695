import { useContext } from 'react';

import { CheckoutActionContext, CheckoutValueContext } from './context';

const useCheckoutValue = () => {
  const context = useContext(CheckoutValueContext);

  if (!context) {
    throw new Error(
      'useCheckoutValue must be used within a <CheckoutContext />',
    );
  }

  return context;
};

const useCheckoutAction = () => {
  const context = useContext(CheckoutActionContext);

  if (!context) {
    throw new Error(
      'useCheckoutAction must be used within a <CheckoutContext />',
    );
  }

  return context;
};

export { useCheckoutAction, useCheckoutValue };
