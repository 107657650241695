'use client';

import * as React from 'react';

import Image from 'next/image';

import {
  CalendarDotsIcon,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  LocaleLink,
  Skeleton,
  TypographyBalancer,
  TypographyParagraph,
  TypographyTime,
} from '@/components';
import type { MagazinePost } from '@/types';
import { cn, dateTimeFormat, parseFromContent } from '@/utils';

type BlogPostContextProps = MagazinePost;

const BlogPostContext = React.createContext<BlogPostContextProps>(
  {} as BlogPostContextProps,
);

const useBlogPost = () => {
  const context = React.useContext(BlogPostContext);

  if (!context) {
    throw new Error('useBlogPost must be used within a <BlogPost />');
  }

  return context;
};

type BlogPostProps = React.ComponentPropsWithoutRef<typeof Card> &
  BlogPostContextProps;

const BlogPost = React.forwardRef<React.ElementRef<typeof Card>, BlogPostProps>(
  (
    { bannerLink, date, excerpt, id, link, title, className, ...props },
    ref,
  ) => (
    <BlogPostContext.Provider
      value={{ bannerLink, date, excerpt, id, link, title }}
    >
      <LocaleLink href={link} target='_blank' className='block h-full'>
        <Card ref={ref} className={cn('flex gap-4', className)} {...props} />
      </LocaleLink>
    </BlogPostContext.Provider>
  ),
);
BlogPost.displayName = 'BlogPost';

const BlogPostHeader = React.forwardRef<
  React.ElementRef<typeof CardHeader>,
  React.ComponentPropsWithoutRef<typeof CardHeader>
>(({ className, ...props }, ref) => {
  const { bannerLink, title } = useBlogPost();

  const { width, height } = {
    width: 320,
    height: 300,
  };

  return (
    <CardHeader
      ref={ref}
      className={cn('rounded overflow-hidden', className)}
      {...props}
    >
      <Image
        className='size-full'
        src={bannerLink}
        width={width}
        height={height}
        alt={title}
      />
    </CardHeader>
  );
});
BlogPostHeader.displayName = 'BlogPostHeader';

const BlogPostContent = React.forwardRef<
  React.ElementRef<typeof CardContent>,
  React.ComponentPropsWithoutRef<typeof CardContent>
>(({ className, ...props }, ref) => {
  const { title, link, excerpt, date } = useBlogPost();

  return (
    <CardContent
      ref={ref}
      className={cn('flex-col justify-center gap-2', className)}
      {...props}
    >
      <CardTitle className='line-clamp-1 text-base'>
        <LocaleLink href={link} target='_blank'>
          {title}
        </LocaleLink>
      </CardTitle>

      <TypographyBalancer>
        <TypographyParagraph
          className='line-clamp-3 text-xs leading-relaxed text-foreground/75'
          dangerouslySetInnerHTML={{ __html: parseFromContent(excerpt) }}
        />
      </TypographyBalancer>

      <div className='flex items-center gap-2 text-muted-foreground/80'>
        <CalendarDotsIcon />
        <TypographyTime className='text-xs'>
          {dateTimeFormat(new Date(date))}
        </TypographyTime>
      </div>
    </CardContent>
  );
});
BlogPostContent.displayName = 'BlogPostContent';

const BlogPostPlaceholder = React.forwardRef<
  React.ElementRef<typeof Card>,
  React.ComponentPropsWithoutRef<typeof Card>
>(({ className, ...props }, ref) => (
  <Card ref={ref} className={cn('flex gap-4', className)} {...props} />
));
BlogPostPlaceholder.displayName = 'BlogPostPlaceholder';

const BlogPostHeaderPlaceholder = React.forwardRef<
  React.ElementRef<typeof CardHeader>,
  React.ComponentPropsWithoutRef<typeof CardHeader>
>((props, ref) => (
  <CardHeader ref={ref} {...props}>
    <Skeleton className='size-full' />
  </CardHeader>
));
BlogPostHeaderPlaceholder.displayName = 'BlogPostHeaderPlaceholder';

const BlogPostContentPlaceholder = React.forwardRef<
  React.ElementRef<typeof CardContent>,
  React.ComponentPropsWithoutRef<typeof CardContent>
>(({ className, ...props }, ref) => (
  <CardContent
    ref={ref}
    className={cn('flex-col justify-center gap-5', className)}
    {...props}
  >
    <Skeleton className='h-5 w-2/3' />

    <div className='space-y-2'>
      <Skeleton className='h-3 w-full' />
      <Skeleton className='h-3 w-full' />
      <Skeleton className='h-3 w-2/3' />
    </div>

    <Skeleton className='h-3 w-24' />
  </CardContent>
));
BlogPostContentPlaceholder.displayName = 'BlogPostContentPlaceholder';

export {
  BlogPost,
  BlogPostContent,
  BlogPostContentPlaceholder,
  BlogPostHeader,
  BlogPostHeaderPlaceholder,
  BlogPostPlaceholder,
};
