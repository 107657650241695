import { useCallback, useEffect, useRef, useState } from 'react';

import { useIsMounted } from 'usehooks-ts';

import { deepFlatFilter } from '@/utils';

type FetchOption<T, U extends Error = Error, V = T> = {
  fetchKey: unknown[];
  enabled?: boolean;
  refetchInterval?: number;
  retry?: number;
  retryDelay?: number;
  staleTime?: number;
  fetchFn: () => Promise<T>;
  select?: (data: T) => V;
};

type FetchResult<T, U extends Error = Error, V = T> = {
  isLoading: boolean;
  isFetching: boolean;
  isRefetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  data?: V;
  error?: U;
  lastUpdatedAt?: number;
  refetch: () => Promise<void>;
};

const useFetch = <T, U extends Error = Error, V = T>({
  fetchKey,
  enabled = true,
  refetchInterval = 0,
  retry = 1,
  retryDelay = 1000,
  staleTime = 1000,
  fetchFn,
  select = data => data as unknown as V,
}: FetchOption<T, U, V>): FetchResult<T, U, V> => {
  const [isLoading, setLoading] = useState(enabled);
  const [isFetching, setFetching] = useState(enabled);
  const [isRefetching, setRefetching] = useState(false);
  const [data, setData] = useState<V>();
  const [error, setError] = useState<U>();
  const [lastUpdatedAt, setLastUpdatedAt] = useState<number>();
  const retryTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isMounted = useIsMounted();

  const executeFetch = useCallback(
    async (count: number = 0) => {
      try {
        if (!lastUpdatedAt || Date.now() - lastUpdatedAt > staleTime) {
          setFetching(true);
          setRefetching(data !== undefined);
          setLoading(!data);
          setError(undefined);
          const result = await fetchFn();
          setData(select(result));
          setLastUpdatedAt(Date.now());
        }
      } catch (error) {
        if (count < retry) {
          if (retryTimeoutRef.current) {
            clearTimeout(retryTimeoutRef.current);
          }
          retryTimeoutRef.current = setTimeout(
            () => executeFetch(count + 1),
            retryDelay * Math.pow(2, count),
          );
          return;
        }
        setError(error as U);
      } finally {
        setFetching(false);
        setRefetching(false);
        setLoading(false);
      }
    },
    [data, fetchFn, lastUpdatedAt, retry, retryDelay, select, staleTime],
  );

  useEffect(() => {
    if (isMounted() && enabled) {
      executeFetch();

      if (refetchInterval > 0) {
        const intervalId = setInterval(executeFetch, refetchInterval);
        return () => clearInterval(intervalId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, enabled, refetchInterval, ...deepFlatFilter(fetchKey)]);

  useEffect(() => {
    return () => {
      setLoading(false);
      setFetching(false);
      setRefetching(false);
      setData(undefined);
      setError(undefined);
      setLastUpdatedAt(undefined);
      if (retryTimeoutRef.current) {
        clearTimeout(retryTimeoutRef.current);
      }
    };
  }, []);

  return {
    isLoading,
    isFetching,
    isRefetching,
    isSuccess: !!data && !error,
    isError: !!error,
    data,
    error,
    lastUpdatedAt,
    refetch: executeFetch,
  };
};

export { useFetch };
