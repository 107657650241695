import Script from 'next/script';

import { Brand as BrandSchemaDts, WithContext } from 'schema-dts';

import type { Brand } from '@/types';
import { generateCanonical, generateFileUrl, PATHNAME } from '@/utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    HOME: { BASE_URL: HOME_BASE_URL },
    BRANDS: { BASE_URL: BRANDS_BASE_URL },
  },
} = PATHNAME;

type BrandSchemaProps = Brand;

const BrandSchema = ({
  id,
  pictures,
  slug,
  title,
  iconId = '',
  seo,
}: BrandSchemaProps) => {
  const schema: WithContext<BrandSchemaDts> = {
    '@context': 'https://schema.org',
    '@type': 'Brand',
    '@id': generateCanonical(`/schema/Brand/${id}`),

    name: title,
    alternateName: seo?.title,
    description: seo?.description,
    url: generateCanonical(
      `${BRANDS_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`,
    ),

    logo: generateFileUrl('Brand', iconId),
    image: pictures.map(({ id }) => generateFileUrl('Brand', id)),

    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': generateCanonical(
        `/schema/WebPage${BRANDS_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`,
      ),
      breadcrumb: {
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: 'کاشی‌لند',
            item: generateCanonical(HOME_BASE_URL),
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: 'برند‌ها',
            item: generateCanonical(BRANDS_BASE_URL),
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: title,
            item: generateCanonical(
              `${BRANDS_BASE_URL}${slug}${DEFAULT_SLUG_SEPARATOR}`,
            ),
          },
        ],
      },
    },
  };

  return (
    <Script
      id={id}
      type='application/ld+json'
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
};

export { BrandSchema };
