import { Fetch } from '@/lib';
import type { UserInfo } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    USER: {
      ACCOUNT_INFORMATION: { BASE: BASE_USER_ACCOUNT_INFORMATION },
    },
  },
} = TAG;

type RequestResponse = UserInfo;

const getUserAccountInformation = async () =>
  await Fetch<RequestResponse>({
    url: 'user/getUserAccountInformation',
    next: {
      tags: [BASE_USER_ACCOUNT_INFORMATION],
    },
  });

export { getUserAccountInformation };
