'use client';

import * as React from 'react';

import {
  Button,
  CaretLeftIcon,
  CaretRightIcon,
  DotsThreeIcon,
  TypographySmall,
} from '@/components';
import { usePagination, useQueryParam } from '@/hooks';
import { adjustPageNumber, cn, PATHNAME, randomId } from '@/utils';

const {
  QUERY_PARAM: {
    DEFAULT: { PAGE_NUMBER: DEFAULT_PAGE_NUMBER },
    PAGE_NUMBER: { BASE_URL: PAGE_NUMBER_BASE_URL },
  },
} = PATHNAME;

type PaginationProps = React.ComponentPropsWithoutRef<'nav'> &
  Partial<Record<'total', number>>;

const Pagination = React.forwardRef<React.ElementRef<'nav'>, PaginationProps>(
  (
    {
      total,
      role = 'navigation',
      'aria-label': ariaLabel = 'صفحه بندی',
      className,
      ...props
    },
    ref,
  ) => {
    const [queryParam, setQueryParam] = useQueryParam<
      Record<typeof PAGE_NUMBER_BASE_URL, number>
    >({
      initialValue: { [PAGE_NUMBER_BASE_URL]: DEFAULT_PAGE_NUMBER },
      delay: 0,
    });
    const current = queryParam[PAGE_NUMBER_BASE_URL];
    const { totalPage, pagination } = usePagination({
      total,
      current,
    });

    const disablePrevious = current === 1;
    const disableNext = current === totalPage;

    React.useEffect(() => {
      adjustPageNumber(totalPage, current, page => {
        setQueryParam({
          ...queryParam,
          [PAGE_NUMBER_BASE_URL]: page,
        });
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalPage, current]);

    return (
      <nav
        ref={ref}
        role={role}
        aria-label={ariaLabel}
        className={cn('mx-auto flex w-full justify-center', className)}
        {...props}
      >
        <PaginationContent className='lg:gap-2'>
          <PaginationItem>
            <PaginationPrevious
              disabled={disablePrevious}
              onClick={() => {
                setQueryParam({
                  ...queryParam,
                  [PAGE_NUMBER_BASE_URL]: current - 1,
                });
              }}
            >
              <TypographySmall className='max-lg:sr-only'>
                صفحه قبل
              </TypographySmall>
            </PaginationPrevious>
          </PaginationItem>

          {pagination.map(page => {
            const id = randomId();

            return (
              <PaginationItem key={id}>
                {page === 'DOTS' ? (
                  <PaginationEllipsis>
                    <TypographySmall className='sr-only'>
                      صفحه‌های بیشتر
                    </TypographySmall>
                  </PaginationEllipsis>
                ) : (
                  <PaginationButton
                    isActive={page === current}
                    onClick={() => {
                      setQueryParam({
                        ...queryParam,
                        [PAGE_NUMBER_BASE_URL]: page,
                      });
                    }}
                  >
                    {page}
                  </PaginationButton>
                )}
              </PaginationItem>
            );
          })}

          <PaginationItem>
            <PaginationNext
              disabled={disableNext}
              onClick={() => {
                setQueryParam({
                  ...queryParam,
                  [PAGE_NUMBER_BASE_URL]: current + 1,
                });
              }}
            >
              <TypographySmall className='max-lg:sr-only'>
                صفحه بعد
              </TypographySmall>
            </PaginationNext>
          </PaginationItem>
        </PaginationContent>
      </nav>
    );
  },
);
Pagination.displayName = 'Pagination';

const PaginationContent = React.forwardRef<
  React.ElementRef<'ul'>,
  React.ComponentPropsWithoutRef<'ul'>
>(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    className={cn('flex flex-row items-center gap-1', className)}
    {...props}
  />
));
PaginationContent.displayName = 'PaginationContent';

const PaginationItem = React.forwardRef<
  React.ElementRef<'li'>,
  React.ComponentPropsWithoutRef<'li'>
>((props, ref) => <li ref={ref} {...props} />);
PaginationItem.displayName = 'PaginationItem';

type PaginationButtonProps = React.ComponentPropsWithoutRef<typeof Button> &
  Partial<Record<'isActive', boolean>>;

const PaginationButton = React.forwardRef<
  React.ElementRef<typeof Button>,
  PaginationButtonProps
>(
  (
    {
      isActive,
      variant = 'outline',
      size = 'icon',
      'aria-current': ariaCurrent,
      className,
      ...props
    },
    ref,
  ) => (
    <Button
      ref={ref}
      variant={isActive ? 'default' : variant}
      size={size}
      aria-current={isActive ? 'page' : ariaCurrent}
      className={cn('rounded', !isActive && 'text-foreground', className)}
      {...props}
    />
  ),
);
PaginationButton.displayName = 'PaginationButton';

const PaginationPrevious = React.forwardRef<
  React.ElementRef<typeof PaginationButton>,
  React.ComponentPropsWithoutRef<typeof PaginationButton>
>(({ className, children, ...props }, ref) => (
  <PaginationButton
    ref={ref}
    className={cn('w-auto gap-1 px-2', className)}
    {...props}
  >
    <CaretRightIcon />
    {children}
  </PaginationButton>
));
PaginationPrevious.displayName = 'PaginationPrevious';

const PaginationNext = React.forwardRef<
  React.ElementRef<typeof PaginationButton>,
  React.ComponentPropsWithoutRef<typeof PaginationButton>
>(({ className, children, ...props }, ref) => (
  <PaginationButton
    ref={ref}
    className={cn('w-auto gap-1 px-2', className)}
    {...props}
  >
    {children}
    <CaretLeftIcon />
  </PaginationButton>
));
PaginationNext.displayName = 'PaginationNext';

const PaginationEllipsis = React.forwardRef<
  React.ElementRef<typeof PaginationButton>,
  React.ComponentPropsWithoutRef<typeof PaginationButton>
>(
  (
    { className, 'aria-hidden': ariaHidden = true, children, ...props },
    ref,
  ) => (
    <PaginationButton
      ref={ref}
      className={cn('cursor-auto', className)}
      aria-hidden={ariaHidden}
      {...props}
    >
      <DotsThreeIcon />
      {children}
    </PaginationButton>
  ),
);
PaginationEllipsis.displayName = 'PaginationEllipsis';

export {
  Pagination,
  PaginationButton,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
};
