import { useContext } from 'react';

import { CartActionContext, CartValueContext } from './context';

const useCartValue = () => {
  const context = useContext(CartValueContext);

  if (!context) {
    throw new Error('useCartValue must be used within a <CartContext />');
  }

  return context;
};

const useCartAction = () => {
  const context = useContext(CartActionContext);

  if (!context) {
    throw new Error('useCartAction must be used within a <CartContext />');
  }

  return context;
};

export { useCartAction, useCartValue };
