import { Fetch } from '@/lib';
import type { CategoryRelated } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    CATEGORY: {
      RELATED: { GLOBAL: GLOBAL_RELATED_CATEGORY },
    },
  },
} = TAG;

type RequestResponse = {
  categories: CategoryRelated[];
};

type RequestParameter = {
  slug: string;
};

const getRelatedCategories = async ({ slug }: RequestParameter) =>
  await Fetch<RequestResponse, RequestParameter>({
    url: `category/getRelatedCategories/${slug}`,
    next: {
      tags: [GLOBAL_RELATED_CATEGORY],
    },
  });

export { getRelatedCategories };
