import { useCallback, useEffect } from 'react';

import { useCountdown } from 'usehooks-ts';

import { fixedParseFloat, padString } from '@/utils';

type TimeCountdownOption = Parameters<typeof useCountdown>[number] &
  Partial<Record<'initialStart', boolean>>;

const useTimeCountdown = ({
  initialStart = true,
  ...options
}: TimeCountdownOption) => {
  const [totalSeconds, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown(options);

  useEffect(() => {
    if (initialStart) {
      restartCountdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStart]);

  const restartCountdown = useCallback(() => {
    resetCountdown();
    startCountdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isRunning: Boolean(initialStart) && totalSeconds > 0,
    totalSeconds: padString(fixedParseFloat(totalSeconds).toString()),
    seconds: padString(fixedParseFloat(totalSeconds % 60).toString()),
    minutes: padString(
      fixedParseFloat(Math.floor(totalSeconds / 60) % 60).toString(),
    ),
    hours: padString(
      fixedParseFloat(Math.floor(totalSeconds / (60 * 60)) % 24).toString(),
    ),
    days: padString(
      fixedParseFloat(Math.floor(totalSeconds / (60 * 60 * 24))).toString(),
    ),
    start: startCountdown,
    stop: stopCountdown,
    reset: resetCountdown,
    restart: restartCountdown,
  };
};

export { useTimeCountdown };
