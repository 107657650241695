'use client';

import * as React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  NumericInput,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SpinnerIcon,
  TelInput,
  Textarea,
  TypographyMuted,
  TypographyParagraph,
  TypographySuperscript,
} from '@/components';
import {
  createRecipientAddressInfo,
  getCities,
  updateRecipientAddressInfo,
} from '@/services';
import type { RecipientAddressInfo } from '@/types';
import { cn, findElementByKey, FormSchema, TAG } from '@/utils';

const {
  SERVICE: {
    CITY: { GLOBAL: GLOBAL_CITY },
  },
} = TAG;

const AddressInfoFormSchema = FormSchema.pick({
  firstName: true,
  lastName: true,
  provinceId: true,
  cityId: true,
  addressContent: true,
  addressTitle: true,
  addressPostalCode: true,
  phoneNumber: true,
}).extend({
  telNumber: FormSchema.shape.telNumber.or(z.literal('')).optional(),
});

type AddressInfoFormProps = StrictOmit<
  React.ComponentPropsWithoutRef<'form'>,
  'onSubmit'
> &
  Partial<
    RecipientAddressInfo &
      Record<'type', 'create' | 'update'> &
      Record<'actionTitle', string> &
      Record<'onAction', () => void>
  >;

const AddressInfoForm = React.forwardRef<
  React.ElementRef<'form'>,
  AddressInfoFormProps
>(
  (
    {
      id = '',
      firstName = '',
      lastName = '',
      city = { id: '', province: { id: '' } },
      address = {
        content: '',
        postalCode: '',
        no: '',
        unit: '',
      },
      title = '',
      phoneNumber = '',
      telNumber = '',
      type = 'create',
      actionTitle = 'ذخیره آدرس',
      className,
      onAction,
      ...props
    },
    ref,
  ) => {
    const [provinceId, setProvinceId] = React.useState(city.province.id);
    const { isPending, data: provinces = [] } = useQuery({
      queryKey: [GLOBAL_CITY],
      queryFn: getCities,
      select: ({ provinces }) => provinces,
    });
    const form = useForm<z.infer<typeof AddressInfoFormSchema>>({
      resolver: zodResolver(AddressInfoFormSchema),
      defaultValues: {
        firstName,
        lastName,
        provinceId,
        cityId: city.id,
        addressContent: address.content,
        addressTitle: title,
        addressPostalCode: address.postalCode,
        phoneNumber,
        telNumber,
      },
    });

    const onSubmit = async ({
      firstName,
      lastName,
      cityId,
      addressContent,
      addressTitle,
      addressPostalCode,
      phoneNumber,
      telNumber,
    }: z.infer<typeof AddressInfoFormSchema>) => {
      const body: Parameters<typeof updateRecipientAddressInfo>[1] = {
        title: addressTitle,
        firstName,
        lastName,
        cityId,
        address: {
          content: addressContent,
          postalCode: addressPostalCode,
        },
        telNumber,
        phoneNumber,
      };

      try {
        if (type === 'update') {
          await updateRecipientAddressInfo({ id }, body);
          toast.success('آدرس شما با موفقیت ویرایش شد.');
        } else {
          await createRecipientAddressInfo(body);
          toast.success('آدرس شما با موفقیت ایجاد شد.');
        }
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message);
        }
      } finally {
        onAction?.();
      }
    };

    const {
      formState: { isSubmitting },
    } = form;

    return (
      <Form {...form}>
        <form
          ref={ref}
          className={cn('grid grid-cols-2 gap-4 p-4 lg:grid-cols-4', className)}
          onSubmit={form.handleSubmit(onSubmit)}
          {...props}
        >
          <FormField
            control={form.control}
            name='firstName'
            render={({ field }) => (
              <FormItem className='col-span-full lg:col-span-2'>
                <FormLabel>
                  <span> نام گیرنده</span>
                  <TypographySuperscript className='text-danger/80'>
                    *
                  </TypographySuperscript>
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder='لطفا نام گیرنده را وارد کنید'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='lastName'
            render={({ field }) => (
              <FormItem className='col-span-full lg:col-span-2'>
                <FormLabel>
                  <span> نام خانوادگی گیرنده</span>
                  <TypographySuperscript className='text-danger/80'>
                    *
                  </TypographySuperscript>
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder='لطفا نام خانوادگی گیرنده را وارد کنید'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='provinceId'
            render={({ field: { value, onChange } }) => (
              <FormItem className='col-span-full lg:col-span-2'>
                <FormLabel>
                  <span> استان</span>
                  <TypographySuperscript className='text-danger/80'>
                    *
                  </TypographySuperscript>
                </FormLabel>
                <Select
                  value={value}
                  onValueChange={value => {
                    onChange(value);
                    setProvinceId(value);
                    form.setValue('cityId', '');
                  }}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder='لطفا استان خود را انتخاب کنید' />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {isPending ? (
                      <TypographyParagraph className='py-4 text-center'>
                        <TypographyMuted>لطفا کمی منتظر بمانید</TypographyMuted>
                      </TypographyParagraph>
                    ) : (
                      provinces
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(({ id, name }) => (
                          <SelectItem key={id} value={id}>
                            {name}
                          </SelectItem>
                        ))
                    )}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='cityId'
            render={({ field: { name, value, onChange } }) => (
              <FormItem className='col-span-full lg:col-span-2'>
                <FormLabel>
                  <span> شهر</span>
                  <TypographySuperscript className='text-danger/80'>
                    *
                  </TypographySuperscript>
                </FormLabel>
                <Select
                  name={name}
                  defaultValue={value}
                  onValueChange={onChange}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder='لطفا شهر خود را انتخاب کنید' />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {provinceId ? (
                      findElementByKey(provinces, 'id', provinceId)
                        ?.cities.sort((a, b) => a.name.localeCompare(b.name))
                        .map(({ id, name }) => (
                          <SelectItem key={id} value={id}>
                            {name}
                          </SelectItem>
                        ))
                    ) : (
                      <TypographyParagraph className='py-4 text-center'>
                        <TypographyMuted>
                          لطفا ابتدا استان خود را انتخاب کنید
                        </TypographyMuted>
                      </TypographyParagraph>
                    )}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='addressContent'
            render={({ field }) => (
              <FormItem className='col-span-full'>
                <FormLabel>
                  <span> آدرس</span>
                  <TypographySuperscript className='text-danger/80'>
                    *
                  </TypographySuperscript>
                </FormLabel>
                <FormControl>
                  <Textarea
                    rows={6}
                    placeholder='لطفا آدرس خود را وارد کنید'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='addressTitle'
            render={({ field }) => (
              <FormItem className='col-span-full lg:col-span-2'>
                <FormLabel>
                  <span> عنوان آدرس</span>
                  <TypographySuperscript className='text-danger/80'>
                    *
                  </TypographySuperscript>
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder='لطفا عنوان آدرس را وارد کنید (شرکت کاشی‌لند)'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='addressPostalCode'
            render={({ field }) => (
              <FormItem className='col-span-full lg:col-span-2'>
                <FormLabel>
                  <span> کدپستی</span>
                  <TypographySuperscript className='text-danger/80'>
                    *
                  </TypographySuperscript>
                </FormLabel>
                <FormControl>
                  <NumericInput
                    placeholder='لطفا کدپستی خود را وارد کنید'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* <FormField
            control={form.control}
            name='addressNumber'
            render={({ field }) => (
              <FormItem className='col-span-full lg:col-span-2'>
                <FormLabel>پلاک</FormLabel>
                <FormControl>
                  <NumericInput placeholder='لطفا پلاک خود را وارد کنید' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='addressUnit'
            render={({ field }) => (
              <FormItem className='col-span-full lg:col-span-2'>
                <FormLabel>واحد</FormLabel>
                <FormControl>
                  <NumericInput placeholder='لطفا واحد خود را وارد کنید' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          /> */}

          <FormField
            control={form.control}
            name='phoneNumber'
            render={({ field }) => (
              <FormItem className='col-span-full lg:col-span-2'>
                <FormLabel>
                  <span> تلفن همراه گیرنده</span>
                  <TypographySuperscript className='text-danger/80'>
                    *
                  </TypographySuperscript>
                </FormLabel>
                <FormControl>
                  <TelInput
                    placeholder='لطفا تلفن همراه گیرنده را وارد کنید'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='telNumber'
            render={({ field }) => (
              <FormItem className='col-span-full lg:col-span-2'>
                <FormLabel>تلفن ثابت گیرنده</FormLabel>
                <FormControl>
                  <TelInput
                    placeholder='لطفا تلفن ثابت گیرنده را وارد کنید'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className='col-span-full pt-4'>
            <Button type='submit' className='w-full' disabled={isSubmitting}>
              {isSubmitting ? <SpinnerIcon className='animate-spin' /> : null}
              <span>{actionTitle}</span>
            </Button>
          </div>
        </form>
      </Form>
    );
  },
);
AddressInfoForm.displayName = 'AddressInfoForm';

export { AddressInfoForm };
