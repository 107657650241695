'use client';

import * as React from 'react';

import Image from 'next/image';

import { useMediaQuery } from 'usehooks-ts';

import { LocaleLink, Skeleton } from '@/components';
import type { PageBanner as PageBannerType } from '@/types';
import { BREAKPOINT, cn, generateFileUrl, PATHNAME } from '@/utils';

const {
  DEFAULT: { SLUG_SEPARATOR: DEFAULT_SLUG_SEPARATOR },
  LOCAL: {
    CATEGORY: { BASE_URL: CATEGORY_BASE_URL },
  },
} = PATHNAME;
const {
  SMALLER_THAN: { LG: SMALLER_THAN_LG },
} = BREAKPOINT;

type PageBannerProps = Partial<
  StrictOmit<React.ComponentPropsWithoutRef<typeof Image>, 'src'>
> &
  PageBannerType;

const PageBanner = React.forwardRef<
  React.ElementRef<typeof Image>,
  PageBannerProps
>(
  (
    {
      type,
      bannerId,
      mobileBannerId,
      categorySlug,
      brandSlug,
      width: initialWidth,
      height: initialHeight,
      alt: initialAlt,
      className,
      ...props
    },
    ref,
  ) => {
    const matches = useMediaQuery(SMALLER_THAN_LG);

    const { width, height } = {
      width: matches ? 480 : 1080,
      height: matches ? 480 : 1080,
    };

    return (
      <LocaleLink
        href={{
          pathname: `${CATEGORY_BASE_URL}${categorySlug}${DEFAULT_SLUG_SEPARATOR}`,
        }}
        className='block h-full'
      >
        <Image
          ref={ref}
          src={generateFileUrl(
            'Config',
            matches ? mobileBannerId : bannerId,
            width,
            height,
          )}
          width={initialWidth ?? width}
          height={initialHeight ?? height}
          className={cn('size-full rounded-md', className)}
          alt={initialAlt ?? `Page Banner ${type}`}
          {...props}
        />
      </LocaleLink>
    );
  },
);
PageBanner.displayName = 'PageBanner';

const PageBannerPlaceholder = React.forwardRef<
  React.ElementRef<typeof Skeleton>,
  React.ComponentPropsWithoutRef<typeof Skeleton>
>(({ className, ...props }, ref) => (
  <Skeleton
    ref={ref}
    className={cn('absolute -z-10 size-full rounded-md', className)}
    {...props}
  />
));
PageBannerPlaceholder.displayName = 'PageBannerPlaceholder';

export { PageBanner, PageBannerPlaceholder };
