import { Fetch } from '@/lib';
import type { ProductSummary } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    PRODUCT: {
      RELATED_GLOBAL: { TO_CART: RELATED_GLOBAL_PRODUCT_TO_CART },
    },
  },
} = TAG;

type RequestResponse = {
  products: ProductSummary[];
};

type RequestParameter = {
  cartId: string;
};

const getRelatedProductsToCart = async (params: RequestParameter) =>
  await Fetch<RequestResponse, RequestParameter>({
    url: 'product/getRelatedProductsToCart',
    params,
    next: {
      tags: [RELATED_GLOBAL_PRODUCT_TO_CART],
    },
  });

export { getRelatedProductsToCart };
