'use client';

import * as React from 'react';

import { TypographyTime } from '@/components';
import { useTimeCountdown } from '@/hooks';
import type { SpecialOffer } from '@/types';
import { cn, randomId } from '@/utils';

type CountdownProps = React.ComponentPropsWithoutRef<'div'> & SpecialOffer;

const Countdown = React.forwardRef<React.ElementRef<'div'>, CountdownProps>(
  (
    { duration: { startTime, endTime }, description, className, ...props },
    ref,
  ) => {
    const { seconds, minutes, hours, days } = useTimeCountdown({
      countStart:
        (new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000,
    });

    return (
      <div ref={ref} className={cn('flex gap-2', className)} {...props}>
        {[
          { label: 'ثانیه', value: seconds },
          { label: 'دقیقه', value: minutes },
          { label: 'ساعت', value: hours },
          { label: 'روز', value: days },
        ].map(({ label, value }) => {
          const id = randomId();

          return (
            <div key={id} className='flex flex-col items-center gap-1'>
              <TypographyTime className='flex size-11 items-center justify-center rounded bg-background/15 p-4 text-base'>
                {value}
              </TypographyTime>

              <span className='text-sm'>{label}</span>
            </div>
          );
        })}
      </div>
    );
  },
);
Countdown.displayName = 'Countdown';

export { Countdown };
