import { Fetch } from '@/lib';
import type { IdentityPayload } from '@/types';

type RequestResponse = Pick<IdentityPayload, 'ticket'>;

type RequestParameter = {};

type RequestBody = Pick<IdentityPayload, 'phoneNumber'>;

const changePhoneNumber = async (body: RequestBody) =>
  await Fetch<RequestResponse, RequestParameter, RequestBody>({
    url: 'identity/changePhoneNumber',
    method: 'PATCH',
    body,
  });

export { changePhoneNumber };
