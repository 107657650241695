'use client';

import * as React from 'react';

import { Controller, FormProvider, useFormContext } from 'react-hook-form';
import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';

import { Label, Slot, TypographyMuted, TypographySmall } from '@/components';
import { cn } from '@/utils';

const Form = FormProvider;

type FormFieldContextProps<
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>,
> = {
  name: N;
};

const FormFieldContext = React.createContext<FormFieldContextProps>(
  {} as FormFieldContextProps,
);

type FormItemContextProps = {
  id: string;
};

const FormItemContext = React.createContext<FormItemContextProps>(
  {} as FormItemContextProps,
);

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  if (!fieldContext) {
    throw new Error('useFormField should be used within a <FormField />');
  }
  if (!itemContext) {
    throw new Error('useFormField should be used within a <FormItem />');
  }

  const name = fieldContext.name;
  const id = itemContext.id;
  const fieldState = getFieldState(name, formState);

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  };
};

const FormField = <
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>,
>(
  props: ControllerProps<T, N>,
) => (
  <FormFieldContext.Provider value={{ name: props.name }}>
    <Controller {...props} />
  </FormFieldContext.Provider>
);

const FormItem = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => {
  const id = React.useId();

  return (
    <FormItemContext.Provider value={{ id }}>
      <div ref={ref} className={cn('space-y-2', className)} {...props} />
    </FormItemContext.Provider>
  );
});
FormItem.displayName = 'FormItem';

const FormLabel = React.forwardRef<
  React.ElementRef<typeof Label>,
  React.ComponentPropsWithoutRef<typeof Label>
>(({ htmlFor, className, ...props }, ref) => {
  const { error, formItemId } = useFormField();

  return (
    <Label
      ref={ref}
      htmlFor={htmlFor ?? formItemId}
      className={cn(error && 'text-danger', className)}
      {...props}
    />
  );
});
FormLabel.displayName = 'FormLabel';

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(
  (
    {
      id,
      'aria-describedby': ariaDescribedby,
      'aria-invalid': ariaInvalid,
      ...props
    },
    ref,
  ) => {
    const { error, formItemId, formDescriptionId, formMessageId } =
      useFormField();

    return (
      <Slot
        ref={ref}
        id={id ?? formItemId}
        aria-describedby={
          ariaDescribedby ??
          `${formDescriptionId} ${error ? ` ${formMessageId}` : ''}`
        }
        aria-invalid={ariaInvalid ?? !!error}
        {...props}
      />
    );
  },
);
FormControl.displayName = 'FormControl';

const FormDescription = React.forwardRef<
  React.ElementRef<typeof TypographyMuted>,
  React.ComponentPropsWithoutRef<typeof TypographyMuted>
>(({ id, className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();

  return (
    <TypographyMuted
      ref={ref}
      id={id ?? formDescriptionId}
      className={cn('text-xs', className)}
      {...props}
    />
  );
});
FormDescription.displayName = 'FormDescription';

const FormMessage = React.forwardRef<
  React.ElementRef<typeof TypographySmall>,
  React.ComponentPropsWithoutRef<typeof TypographySmall>
>(({ id, className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error?.message) : children;

  if (!body) return null;

  return (
    <TypographySmall
      ref={ref}
      id={id ?? formMessageId}
      className={cn('text-danger', className)}
      {...props}
    >
      {body}
    </TypographySmall>
  );
});
FormMessage.displayName = 'FormMessage';

export {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
};
export { useFormField };
