import { Upload } from '@/lib';

type RequestBody = StrictOmit<Parameters<typeof Upload>[number], 'url'>;

const uploadUserFile = async (body: RequestBody) =>
  await Upload({
    url: 'user/uploadUserFile',
    ...body,
  });

export { uploadUserFile };
