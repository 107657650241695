import { useContext } from 'react';

import { ProductActionContext, ProductValueContext } from './context';

const useProductValue = () => {
  const context = useContext(ProductValueContext);

  if (!context) {
    throw new Error('useProductValue must be used within a <ProductContext />');
  }

  return context;
};

const useProductAction = () => {
  const context = useContext(ProductActionContext);

  if (!context) {
    throw new Error(
      'useProductAction must be used within a <ProductContext />',
    );
  }

  return context;
};

export { useProductAction, useProductValue };
