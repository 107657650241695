'use client';

import * as React from 'react';

import { Badge, badgeVariants, Separator } from '@/components';
import { cn, randomId } from '@/utils';

type StepperProps = React.ComponentPropsWithoutRef<'div'> &
  Record<'steps', string[]> &
  Partial<
    Record<'active', number> &
      Record<'onStepChange', (currentStep: number) => void>
  >;

const Stepper = React.forwardRef<React.ElementRef<'div'>, StepperProps>(
  ({ steps, active = 0, className, onStepChange, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('flex items-baseline justify-between gap-4', className)}
      {...props}
    >
      {steps.map((step, index) => {
        const id = randomId();

        ++index;
        const isCurrentStep = active === index;
        const isCompletedSteps = active > index;

        return (
          <React.Fragment key={id}>
            {index !== 1 ? (
              <Separator
                className={cn(
                  'h-px w-full bg-secondary/20',
                  (isCurrentStep || isCompletedSteps) && 'bg-primary',
                )}
              />
            ) : null}

            <div
              role='button'
              className='relative h-16'
              onClick={() => {
                onStepChange?.(index);
              }}
            >
              <Badge
                className={cn(
                  badgeVariants({
                    variant: isCompletedSteps ? 'default' : 'secondary',
                    size: 'full',
                    className: 'size-8 text-sm',
                  }),
                )}
              >
                {index}
              </Badge>

              <div className='absolute -right-9 bottom-0 w-28 text-center'>
                <span
                  className={cn(
                    'text-sm text-secondary font-medium',
                    isCompletedSteps && 'text-primary',
                  )}
                >
                  {step}
                </span>
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  ),
);
Stepper.displayName = 'Stepper';

export { Stepper };
