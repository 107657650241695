'use client';

import { useReducer } from 'react';

import { CheckoutActionContext, CheckoutValueContext } from './context';
import { useCheckoutAction, useCheckoutValue } from './hook';
import { initialState } from './initial';
import { reducer } from './reducer';

const CheckoutContext = ({ children }: Readonly<React.PropsWithChildren>) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CheckoutValueContext.Provider value={state}>
      <CheckoutActionContext.Provider value={dispatch}>
        {children}
      </CheckoutActionContext.Provider>
    </CheckoutValueContext.Provider>
  );
};

export { CheckoutContext, useCheckoutAction, useCheckoutValue };
