import { Fetch } from '@/lib';
import type { ProductSortType, ProductSummary } from '@/types';

type RequestResponse = {
  total: number;
  data: ProductSummary[];
};

type RequestParameter = {
  sortType: ProductSortType;
  searchKey?: string;
  categoriesIds?: string[];
  brandsIds?: string[];
  isAvailable?: boolean;
  pageSize: number;
  pageNumber: number;
};

const basicSearchProducts = async ({
  sortType,
  searchKey,
  categoriesIds,
  brandsIds,
  isAvailable,
  pageSize,
  pageNumber,
}: RequestParameter) => {
  const params: Record<string, unknown> = {
    sortType,
    pageSize,
    pageNumber,
  };

  if (searchKey) {
    params['searchKey'] = searchKey;
  }
  if (categoriesIds) {
    params['categoriesIds'] = categoriesIds;
  }
  if (brandsIds) {
    params['brandsIds'] = brandsIds;
  }
  if (isAvailable) {
    params['isAvailable'] = isAvailable;
  }

  return await Fetch<RequestResponse, typeof params>({
    url: 'product/basicSearchProducts',
    params,
  });
};

export { basicSearchProducts };
