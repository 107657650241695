import { Fetch } from '@/lib';
import type { IdentityPayload } from '@/types';

type RequestResponse = {};

type RequestParameter = {};

type RequestBody = Pick<IdentityPayload, 'phoneNumber' | 'email' | 'tokenType'>;

const forgetPassword = async (body: RequestBody) =>
  await Fetch<RequestResponse, RequestParameter, RequestBody>({
    url: 'identity/forgetPassword',
    method: 'POST',
    body,
  });

export { forgetPassword };
