import { z } from 'zod';

const FormSchema = z.object({
  firstName: z.string().min(1, {
    message: 'لطفا نام را وارد کنید',
  }),
  lastName: z.string().min(1, {
    message: 'لطفا نام خانوادگی را وارد کنید',
  }),
  displayName: z.string().min(1, {
    message: 'لطفا نام و نام خانوادگی را وارد کنید',
  }),
  email: z.string().email({
    message: 'لطفا ایمیل خود را وارد کنید',
  }),
  comment: z.string().min(1, { message: 'لطفا دیدگاه خود را وارد کنید' }),
  contactRequest: z
    .string()
    .min(1, { message: 'لطفا درخواست خود را وارد کنید' }),
  provinceId: z.string().min(1, {
    message: 'لطفا استان را انتخاب کنید',
  }),
  cityId: z.string().min(1, {
    message: 'لطفا شهر را انتخاب کنید',
  }),
  addressTitle: z.string().min(1, {
    message: 'لطفا عنوان آدرس را وارد کنید',
  }),
  addressContent: z.string().min(1, {
    message: 'لطفا آدرس را وارد کنید',
  }),
  addressNumber: z.string().min(1, {
    message: 'لطفا پلاک را وارد کنید',
  }),
  addressUnit: z.string().min(1, {
    message: 'لطفا واحد را وارد کنید',
  }),
  addressPostalCode: z.string().regex(/^\d{10}$/gm, {
    message: 'لطفا کدپستی را وارد کنید',
  }),
  telNumber: z.string().regex(/^((\+98)|0)?\d{10}$/, {
    message: 'لطفا تلفن ثابت را وارد کنید',
  }),
  phoneNumber: z.string().regex(/^((\+98)|0)?9\d{9}$/gm, {
    message: 'لطفا تلفن همراه را وارد کنید',
  }),
  otp: z.string().min(6, {
    message: 'لطفا کد تایید را وارد کنید',
  }),
  discountCode: z.string().optional(),
  description: z.string().optional(),
  addressInfoId: z.string().optional(),
  paymentGatewayId: z.string().optional(),
  rate: z.number().min(0).max(5).step(1),
  paperInvoice: z.boolean(),
});

export { FormSchema };
