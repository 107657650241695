import { Fetch } from '@/lib';
import { TAG } from '@/utils';

const {
  SERVICE: {
    IDENTITY: {
      AUTH: { BASE: BASE_IDENTITY_AUTH },
    },
  },
} = TAG;

type RequestResponse = {
  success: boolean;
};

const checkAuth = async () =>
  await Fetch<RequestResponse>({
    url: 'identity/checkAuth',
    next: {
      tags: [BASE_IDENTITY_AUTH],
    },
  });

export { checkAuth };
