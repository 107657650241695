import { Fetch } from '@/lib';
import type { ProductSummary, SpecialOffer } from '@/types';

type RequestResponse = {
  total: number;
  data: ProductSummary[];
  info?: SpecialOffer;
};

type RequestParameter = {
  searchKey: string;
  pageSize: number;
  pageNumber: number;
};

const searchSpecialOffers = async (params: RequestParameter) =>
  await Fetch<RequestResponse, RequestParameter>({
    url: 'specialOffer/searchSpecialOffers',
    params,
  });

export { searchSpecialOffers };
