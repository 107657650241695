import { Fetch } from '@/lib';
import type { ProductSummary } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    PRODUCT: {
      RELATED_GLOBAL: { BASE: BASE_RELATED_GLOBAL_PRODUCT },
    },
  },
} = TAG;

type RequestResponse = {
  products: ProductSummary[];
};

type RequestParameter = {
  productId: string;
};

const getRelatedProducts = async (params: RequestParameter) =>
  await Fetch<RequestResponse, RequestParameter>({
    url: 'product/getRelatedProducts',
    params,
    next: {
      tags: [BASE_RELATED_GLOBAL_PRODUCT],
    },
  });

export { getRelatedProducts };
