'use client';

import * as React from 'react';

import Image from 'next/image';

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  Skeleton,
} from '@/components';
import type { CategoryRelated } from '@/types';
import { cn, generateFileUrl, PATHNAME } from '@/utils';

import { LocaleLink } from '../common';

const {
  LOCAL: {
    CATEGORY: { BASE_URL: CATEGORY_BASE_URL },
  },
} = PATHNAME;

type CategoryCardContextProps = CategoryRelated;

const CategoryCardContext = React.createContext<CategoryCardContextProps>(
  {} as CategoryCardContextProps,
);

const useCategoryCard = () => {
  const context = React.useContext(CategoryCardContext);

  if (!context) {
    throw new Error('useCategoryCard must be used within a <CategoryCard />');
  }

  return context;
};

type CategoryCardProps = React.ComponentPropsWithoutRef<typeof Card> &
  CategoryCardContextProps;

const CategoryCard = React.forwardRef<
  React.ElementRef<typeof Card>,
  CategoryCardProps
>(
  (
    { category, lastProduct, productCount, dir = 'rtl', className, ...props },
    ref,
  ) => {
    const { slug } = category;

    return (
      <CategoryCardContext.Provider
        value={{ category, lastProduct, productCount }}
      >
        <LocaleLink
          href={`${CATEGORY_BASE_URL}${slug}`}
          className='block h-full'
        >
          <Card
            ref={ref}
            dir={dir}
            className={cn('size-full', className)}
            {...props}
          />
        </LocaleLink>
      </CategoryCardContext.Provider>
    );
  },
);
CategoryCard.displayName = 'CategoryCard';

const CategoryCardHeader = React.forwardRef<
  React.ElementRef<typeof CardHeader>,
  React.ComponentPropsWithoutRef<typeof CardHeader>
>(({ className, ...props }, ref) => {
  const { lastProduct } = useCategoryCard();

  if (lastProduct) {
    const { name, bannerId } = lastProduct;
    const { width, height } = { width: 200, height: 200 };

    return (
      <CardHeader
        ref={ref}
        className={cn('items-center justify-center', className)}
        {...props}
      >
        {lastProduct ? (
          <Image
            src={generateFileUrl('Product', bannerId, width, height)}
            width={width}
            height={height}
            className='block size-full'
            alt={name}
          />
        ) : null}
      </CardHeader>
    );
  }
});
CategoryCardHeader.displayName = 'CategoryCardHeader';

const CategoryCardContent = React.forwardRef<
  React.ElementRef<typeof CardContent>,
  React.ComponentPropsWithoutRef<typeof CardContent>
>(({ className, ...props }, ref) => {
  const {
    category: { title },
  } = useCategoryCard();

  return (
    <CardContent
      ref={ref}
      className={cn('flex-row items-center', className)}
      {...props}
    >
      <CardTitle>{title}</CardTitle>
    </CardContent>
  );
});
CategoryCardContent.displayName = 'CategoryCardContent';

const CategoryCardFooter = React.forwardRef<
  React.ElementRef<typeof CardFooter>,
  React.ComponentPropsWithoutRef<typeof CardFooter>
>(({ className, ...props }, ref) => {
  const { productCount } = useCategoryCard();

  return (
    <CardFooter ref={ref} className={cn('items-center', className)} {...props}>
      <CardDescription className='text-xs'>
        {productCount} محصول
      </CardDescription>
    </CardFooter>
  );
});
CategoryCardFooter.displayName = 'CategoryCardFooter';

const CategoryCardPlaceholder = React.forwardRef<
  React.ElementRef<typeof Card>,
  React.ComponentPropsWithoutRef<typeof Card>
>(({ dir, className, ...props }, ref) => (
  <Card ref={ref} dir={dir} className={cn('size-full', className)} {...props} />
));
CategoryCardPlaceholder.displayName = 'CategoryCardPlaceholder';

const CategoryCardHeaderPlaceholder = React.forwardRef<
  React.ElementRef<typeof CardHeader>,
  React.ComponentPropsWithoutRef<typeof CardHeader>
>(({ className, ...props }, ref) => (
  <CardHeader
    ref={ref}
    className={cn('items-center justify-center', className)}
    {...props}
  >
    <Skeleton className='size-full' />
  </CardHeader>
));
CategoryCardHeaderPlaceholder.displayName = 'CategoryCardHeaderPlaceholder';

const CategoryCardContentPlaceholder = React.forwardRef<
  React.ElementRef<typeof CardContent>,
  React.ComponentPropsWithoutRef<typeof CardContent>
>(({ className, ...props }, ref) => (
  <CardContent
    ref={ref}
    className={cn('flex-row items-center', className)}
    {...props}
  >
    <Skeleton className='h-4 w-full' />
  </CardContent>
));
CategoryCardContentPlaceholder.displayName = 'CategoryCardContentPlaceholder';

const CategoryCardFooterPlaceholder = React.forwardRef<
  React.ElementRef<typeof CardFooter>,
  React.ComponentPropsWithoutRef<typeof CardFooter>
>(({ className, ...props }, ref) => (
  <CardFooter ref={ref} className={cn('items-center', className)} {...props}>
    <Skeleton className='h-3 w-1/2' />
  </CardFooter>
));
CategoryCardFooterPlaceholder.displayName = 'CategoryCardFooterPlaceholder';

export {
  CategoryCard,
  CategoryCardContent,
  CategoryCardContentPlaceholder,
  CategoryCardFooter,
  CategoryCardFooterPlaceholder,
  CategoryCardHeader,
  CategoryCardHeaderPlaceholder,
  CategoryCardPlaceholder,
};
