'use client';

import * as React from 'react';

import { useBoolean } from 'usehooks-ts';

import { Badge, Button, EyeIcon, EyeSlashIcon } from '@/components';
import {
  cn,
  enforceValueLimits,
  formatCurrency,
  normalizeToString,
} from '@/utils';

const Input = React.forwardRef<
  React.ElementRef<'input'>,
  React.ComponentPropsWithoutRef<'input'>
>(({ type = 'text', inputMode = 'text', className, ...props }, ref) => (
  <input
    ref={ref}
    type={type}
    inputMode={inputMode}
    className={cn(
      'flex w-full rounded-md border border-input bg-background p-3 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-xs placeholder:font-medium placeholder:text-secondary/60 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
      className,
    )}
    {...props}
  />
));
Input.displayName = 'Input';

type AddonInputProps = React.ComponentPropsWithoutRef<typeof Input> &
  Partial<
    Record<'addonBefore' | 'addonAfter', React.ReactNode> &
      Record<'containerClassName', string>
  >;

const AddonInput = React.forwardRef<
  React.ElementRef<typeof Input>,
  AddonInputProps
>(
  (
    {
      addonBefore,
      addonAfter,
      containerClassName,
      className,
      dir = 'ltr',
      ...props
    },
    ref,
  ) => (
    <div className={cn('flex w-full justify-between', containerClassName)}>
      {addonBefore ? (
        <Badge
          variant='muted'
          className='rounded-l-none border border-l-0 border-border px-4'
        >
          {addonBefore}
        </Badge>
      ) : null}
      <Input
        ref={ref}
        dir={dir}
        className={cn(
          'text-right',
          addonBefore && 'rounded-r-none',
          addonAfter && 'rounded-l-none',
          (addonBefore || addonAfter) &&
            'relative focus-visible:ring-1 focus-visible:ring-offset-0',
          className,
        )}
        {...props}
      />
      {addonAfter ? (
        <Badge
          variant='muted'
          className='rounded-r-none border border-r-0 border-border px-4'
        >
          {addonAfter}
        </Badge>
      ) : null}
    </div>
  ),
);
AddonInput.displayName = 'AddonInput';

const TelInput = React.forwardRef<
  React.ElementRef<typeof AddonInput>,
  React.ComponentPropsWithoutRef<typeof AddonInput>
>(({ type = 'tel', inputMode = 'tel', ...props }, ref) => (
  <AddonInput ref={ref} type={type} inputMode={inputMode} {...props} />
));
TelInput.displayName = 'TelInput';

const EmailInput = React.forwardRef<
  React.ElementRef<typeof AddonInput>,
  React.ComponentPropsWithoutRef<typeof AddonInput>
>(({ inputMode = 'email', ...props }, ref) => (
  <AddonInput ref={ref} inputMode={inputMode} {...props} />
));
EmailInput.displayName = 'EmailInput';

const PasswordInput = React.forwardRef<
  React.ElementRef<typeof AddonInput>,
  React.ComponentPropsWithoutRef<typeof AddonInput>
>(({ type = 'password', ...props }, ref) => {
  const { value: show, toggle: toggleShow } = useBoolean();
  return (
    <div className='relative'>
      <AddonInput ref={ref} type={!show ? type : undefined} {...props} />
      <Button
        tabIndex={-1}
        variant='link'
        size='icon'
        className='absolute inset-y-0 left-0 text-muted-foreground'
        onClick={toggleShow}
      >
        {React.createElement(show ? EyeSlashIcon : EyeIcon, {
          className: 'size-4',
        })}
      </Button>
    </div>
  );
});
PasswordInput.displayName = 'PasswordInput';

const UrlInput = React.forwardRef<
  React.ElementRef<typeof AddonInput>,
  React.ComponentPropsWithoutRef<typeof AddonInput>
>(({ inputMode = 'url', defaultValue, value, onChange, ...props }, ref) => {
  const [decodedValue, setDecodedValue] = React.useState<string>();

  const generateDecodeURI = React.useCallback(
    (input: unknown) => decodeURI(normalizeToString(input)),
    [],
  );

  return (
    <AddonInput
      ref={ref}
      inputMode={inputMode}
      defaultValue={
        defaultValue !== undefined ? generateDecodeURI(defaultValue) : undefined
      }
      value={value !== undefined ? generateDecodeURI(value) : decodedValue}
      onChange={event => {
        const { target } = event;
        const { value: updatedValue } = target;
        const updatedDecodedValue = generateDecodeURI(updatedValue);

        setDecodedValue(updatedDecodedValue);
        onChange?.({
          ...event,
          target: {
            ...target,
            value: updatedDecodedValue,
          },
        });
      }}
      {...props}
    />
  );
});
UrlInput.displayName = 'UrlInput';

type NumericInputProps = React.ComponentPropsWithoutRef<typeof AddonInput> &
  Partial<
    Record<'decimals', number> &
      Record<'allowCombine' | 'allowNegative', boolean>
  >;

const NumericInput = React.forwardRef<
  React.ElementRef<typeof AddonInput>,
  NumericInputProps
>(
  (
    {
      decimals,
      allowCombine,
      allowNegative,
      inputMode = 'numeric',
      min,
      max,
      defaultValue,
      value,
      onChange,
      ...props
    },
    ref,
  ) => {
    const [formattedValue, setFormattedValue] = React.useState<string>();

    const generateFormatCurrency = React.useCallback(
      (input: unknown) => {
        const enforcedValue = enforceValueLimits(input, min, max);

        return formatCurrency(
          enforcedValue,
          decimals,
          allowCombine,
          allowNegative,
        );
      },
      [min, max, decimals, allowCombine, allowNegative],
    );

    return (
      <AddonInput
        ref={ref}
        inputMode={inputMode}
        min={min}
        max={max}
        defaultValue={
          defaultValue !== undefined
            ? generateFormatCurrency(defaultValue).formatted
            : undefined
        }
        value={
          value !== undefined
            ? generateFormatCurrency(value).formatted
            : formattedValue
        }
        onChange={event => {
          const { target } = event;
          const { value: updatedValue } = target;
          const { formatted, numeric } = generateFormatCurrency(updatedValue);

          setFormattedValue(formatted);
          onChange?.({
            ...event,
            target: {
              ...target,
              value: normalizeToString(numeric),
            },
          });
        }}
        {...props}
      />
    );
  },
);
NumericInput.displayName = 'NumericInput';

const DecimalInput = React.forwardRef<
  React.ElementRef<typeof NumericInput>,
  React.ComponentPropsWithoutRef<typeof NumericInput>
>(({ inputMode = 'decimal', ...props }, ref) => (
  <NumericInput ref={ref} inputMode={inputMode} {...props} />
));
DecimalInput.displayName = 'DecimalInput';

export {
  AddonInput,
  DecimalInput,
  EmailInput,
  Input,
  NumericInput,
  PasswordInput,
  TelInput,
  UrlInput,
};
