import { Fetch, setIdentityToken } from '@/lib';
import type { IdentityToken } from '@/types';

type RequestResponse = IdentityToken;

type RequestParameter = {};

type RequestBody = Pick<IdentityToken, 'refreshToken'>;

const refreshToken = async (body: RequestBody) => {
  const response = await Fetch<RequestResponse, RequestParameter, RequestBody>({
    url: 'identity/refreshToken',
    method: 'PATCH',
    body,
  });

  setIdentityToken(response);

  return response;
};

export { refreshToken };
