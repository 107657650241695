import { Fetch } from '@/lib';
import type { Product } from '@/types';
import { TAG } from '@/utils';

const {
  SERVICE: {
    PRODUCT: { BASE: BASE_PRODUCT },
  },
} = TAG;

type RequestResponse = Product;

type RequestParameter = {
  slug: string;
};

const getProductBySlug = async ({ slug }: RequestParameter) => {
  try {
    return await Fetch<RequestResponse, RequestParameter>({
      url: `product/getProductBySlug/${slug}`,
      next: { tags: [BASE_PRODUCT] },
    });
  } catch {
    return null;
  }
};

export { getProductBySlug };
