import type { CartInvoice, CartProduct } from '@/types';

type State = Record<'products', CartProduct[]> &
  Record<'invoice', Partial<CartInvoice>> &
  Partial<
    Record<'id', string> & Record<'enabledInitialize' | 'enabledSync', boolean>
  >;
const initialState: State = {
  products: [],
  invoice: {},
};

type Action =
  | {
      type: 'INITIALIZE_CART_PRODUCTS';
      payload: Pick<typeof initialState, 'id' | 'products'>;
    }
  | {
      type: 'SYNC_CART_PRODUCTS';
      payload: Pick<typeof initialState, 'id' | 'products'>;
    }
  | {
      type: 'ADD_CART_PRODUCT';
      payload: (typeof initialState)['products'][number];
    }
  | {
      type: 'UPDATE_CART_PRODUCT';
      payload: (typeof initialState)['products'][number];
    }
  | {
      type: 'DELETE_CART_PRODUCT';
      payload: (typeof initialState)['products'][number];
    }
  | {
      type: 'UPDATE_CART_INVOICE';
      payload: Pick<typeof initialState, 'invoice'>;
    }
  | {
      type: 'UPDATE_CART_STATUS';
      payload: Pick<typeof initialState, 'enabledInitialize' | 'enabledSync'>;
    }
  | undefined;
const initialAction: Action = undefined;

export { initialAction, initialState };
