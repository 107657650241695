import { Fetch } from '@/lib';
import type { IdentityPayload } from '@/types';

type RequestResponse = {};

type RequestParameter = {};

type RequestBody = Pick<IdentityPayload, 'password' | 'newPassword'>;

const changePassword = async (body: RequestBody) =>
  await Fetch<RequestResponse, RequestParameter, RequestBody>({
    url: 'identity/changePassword',
    method: 'PUT',
    body,
  });

export { changePassword };
